<template>
  <div class="content pt-0">
    <div class="container">
      <div class="row" style="margin-bottom: 5% !important;">
        <div class="col-md-12 px-0">
          <div class="header">
            <div class="row">
              <div class="col-md-6 col-6">
                <span class="headerText">
                  Web Toppings Management
                </span>
              </div>
              <!-- <div v-if="toppingEditClicked" class="col-md-6 col-6 text-right pt-3 pr-5">
                <button @click="backToToppingList" class="btn btn-default toppingAddBtn">Back</button>
              </div> -->
            </div>
          </div>

          <div class="toppingsTable" style="background-color: #FFFFFF; padding: 2% 31px 31px 31px;">
            <!-- create topping form -->
            <form class="form-inline mb-5">
              <div class="row">
                <div class="col-md-3">
                  <label for="category" class="d-block">Category</label>
                  <input type="text" v-model="toppingTitle" class="form-control" id="category" required>
                </div>
                <div class="col-md-2">
                  <label for="min" class="d-block">Minimum</label>
                  <input type="text" v-model="toppingMin" class="form-control w-100" id="min">
                </div>
                <div class="col-md-2">
                  <label for="max" class="d-block">Maximum</label>
                  <input type="text" v-model="toppingMax" class="form-control w-100" id="max">
                </div>
                <div class="col-md-2">
                  <label for="free" class="d-block">Free</label>
                  <input type="text" v-model="toppingFree" class="form-control w-100" id="free">
                </div>
                <div class="col-md-3 text-center">
                  <button v-if="!toppingEditClicked" type="button" @click="addTopping" class="btn btn-default toppingAddBtn mt-4">Add</button>
                  <button v-else type="button" @click="() => editToppingItem('', false, false)" class="btn btn-default toppingAddBtn mt-4">Save</button>
                </div>
              </div>
            </form>

            <!-- add item to topping form -->
            <form v-if="toppingEditClicked" @submit.prevent="addItemToTopping" class="form-inline mb-5">
              <div class="row">
                <div class="col-md-5">
                  <label for="itemTitle" class="d-block">Topping title</label>
                  <input type="text" v-model="itemTitle" class="form-control w-100" id="itemTitle" required>
                </div>
                <div class="col-md-2 pl-3">
                  <label for="itemPrice" class="d-block">Price</label>
                  <input type="text" v-model="itemPrice" class="form-control w-100" id="itemPrice" required>
                </div>
                <div class="col-md-2 pl-3">
                  <label for="itemFree" class="d-block">Free</label>
                  <input type="checkbox" v-model="itemFree" class="form-control w-50" id="itemFree">
                </div>
                <div class="col-md-3 text-center">
                  <button type="submit" class="btn btn-default toppingAddBtn mt-4">Add</button>
                </div>
              </div>
            </form>

            <table v-if="!toppingEditClicked" class="table table-bordered">
              <thead>
                <tr style="font-weight: bold; color: blue">
                  <th>Category</th>
                  <th>Minimum</th>
                  <th>Maximum</th>
                  <th>Free</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(topping, index) in toppingsDataList" :key="index">
                  <td>{{topping.title}}</td>
                  <td>{{topping.minQty}}</td>
                  <td>{{topping.maxQty}}</td>
                  <td>{{topping.freeQty}}</td>
                  <td><i class="fa fa-edit" @click="() => editTopping(topping.id)" style="cursor: pointer; font-size: 1.3rem"></i></td>
                  <td><i class="fa fa-trash" @click="() => deleteTopping(topping.id)" style="color: red; font-size: 1.3rem; cursor: pointer;"></i></td>
                </tr>
              </tbody>
            </table>

            <table v-if="toppingEditClicked" class="table table-bordered">
              <thead>
                <tr style="font-weight: bold; color: blue">
                  <th>Topping</th>
                  <th>Price</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in selectedTopping.items" :key="index">
                  <td>{{item.title}}</td>
                  <td>£{{item.price}}</td>
                  <td><i class="fa fa-edit" @click="() => setEditItem(item.id, item.title, item.price, item.free)" style="cursor: pointer; font-size: 1.3rem"></i></td>
                  <td><i class="fa fa-trash" @click="() => editToppingItem(item.id, false, true)" style="color: red; font-size: 1.3rem; cursor: pointer;"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <!-- overlay -->
      <div class="overlay" v-if="showItemEditModal" @click="showItemEditModal = false"></div>

      <!-- modal -->
      <div class="modal-own" v-if="showItemEditModal">
        <div class="modal-title">
          <h2 class="d-inline" style="color: rgb(254, 117, 15); margin: unset">Edit Item</h2>
          <i class="fa fa-times float-right mr-3" @click="showItemEditModal=false" style="cursor: pointer; color: #fe750f; font-size: 2rem;"></i>

        </div>
        <div class="modal-body">
          <form @submit.prevent="() => editToppingItem(editItemId, true, false)" class="mx-auto" style="width: 70%;">
            <div class="form-group" style="margin: 2rem 0">
              <label for="editItemTitle">Topping title</label>
              <input type="text" class="form-control" id="editItemTitle" v-model="editItemTitle" required>
            </div>
            <div class="form-group" style="margin: 2rem 0">
              <label for="editItemPrice">Price</label>
              <input type="text" class="form-control" id="editItemPrice" v-model="editItemPrice" required>
            </div>
            <div class="form-group" style="margin: 2rem 0">
              <label for="editItemFree">Free</label>
              <input type="checkbox" class="form-control" style="width: 12%" id="editItemFree" v-model="editItemFree">
            </div>
            <button type="button" @click="showItemEditModal=false" class="btn cancelBtn">Cancel</button>
            <button type="submit" class="btn toppingAddBtn float-right" style="border: 1px solid #000">Save</button>
          </form>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="overlay">
      <img src="/img/loading.gif" alt="GIF" width="30" height="30" class="mx-auto d-block" style="margin-top: 20%">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      restaurantId: parseInt(localStorage.getItem('restaurantId')),
      jwtToken: '',
      requestHeader: '',
      toppingsDataList: [],
      toppingTitle: '',
      toppingMin: '',
      toppingMax: '',
      toppingFree: '',
      itemTitle: '',
      itemPrice: '',
      itemFree: false,
      isLoading: false,
      toppingEditClicked: false,
      selectedTopping: {},
      showItemEditModal: false,
      editItemId: '',
      editItemTitle: '',
      editItemPrice: '',
      editItemFree: false,
    }
  },
  created () {
    this.jwtToken = this.$cookies.get('token') || null
    this.requestHeader = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "*",
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
    this.webToppingsList ()
    this.$root.$on('backToToppingList', () => {
      this.backToToppingList()
    })
  },
  methods: {
    addTopping () {
      if (!this.toppingTitle || !this.toppingMin || !this.toppingMax || !this.toppingFree) {
        this.$toastr.w("Fill up all fields", "Warning");
        return
      }
      this.isLoading = true

      let url = process.env.VUE_APP_API_URL+'/api/webTopping/create'

      let dataToSubmit = {
        "restaurantId": this.restaurantId,
        "title": this.toppingTitle,
        "minQty": this.toppingMin,
        "maxQty": this.toppingMax,
        "freeQty": this.toppingFree,
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping created successfully", "Success");

        this.toppingTitle = ''
        this.toppingMin = ''
        this.toppingMax = ''
        this.toppingFree = ''
        this.webToppingsList ()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    webToppingsList () {
      let url = process.env.VUE_APP_API_URL+`/api/webTopping?restaurantId=${this.restaurantId}`

      axios
      .get(url, { headers: this.requestHeader })
      .then((data)=> {
        this.toppingsDataList = data.data
        if (this.toppingEditClicked) {
          for (var i = 0; i < this.toppingsDataList.length; i++) {
            if (this.toppingsDataList[i].id == this.selectedTopping.id) {
              this.selectedTopping = this.toppingsDataList[i]
            }
          }
        }
      })
    },
    backToToppingList () {
      this.toppingEditClicked = false
      this.toppingTitle = ''
      this.toppingMin = ''
      this.toppingMax = ''
      this.toppingFree = ''
      this.$root.$emit('enableBackBtn', false)
    },
    editTopping (val) {
      for (var i = 0; i < this.toppingsDataList.length; i++) {
        if (this.toppingsDataList[i].id == val) {
          this.selectedTopping = this.toppingsDataList[i]
          this.toppingTitle = this.toppingsDataList[i].title
          this.toppingMin = this.toppingsDataList[i].minQty
          this.toppingMax = this.toppingsDataList[i].maxQty
          this.toppingFree = this.toppingsDataList[i].freeQty
        }
      }
      this.toppingEditClicked = true
      this.$root.$emit('enableBackBtn', true)
    },
    deleteTopping (val) {
      if (confirm('Are you sure to delete this topping?')) {
        let url = process.env.VUE_APP_API_URL+`/api/webTopping/delete?id=${val}`

        axios
        .post(url, { headers: this.requestHeader })
        .then((data)=> {
          if (data.status == 200) {
            this.$toastr.s("Deleted Successfully", "Success");

            this.webToppingsList()
          }
        })
      }
    },
    addItemToTopping () {
      if (!this.itemPrice || !this.itemTitle) {
        this.$toastr.w("Fill up all fields", "Warning");
        return
      }
      this.isLoading = true

      let url = process.env.VUE_APP_API_URL+'/api/webTopping/addTopping'

      let dataToSubmit = {
        "price": this.itemPrice,
        "webToppingId": this.selectedTopping.id,
        "title": this.itemTitle,
        "free": this.itemFree ? 'Yes' : 'No'
      }

      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Item Added to Topping", "Success");

        this.itemTitle = ''
        this.itemPrice = ''
        this.webToppingsList()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    setEditItem (id, title, price, free) {
      this.editItemId = id
      this.editItemTitle = title
      this.editItemPrice = price
      this.editItemFree = free === 'Yes' ? true : false
      this.showItemEditModal = true
    },
    editToppingItem (id, edit=false, del=false) {
      let url = process.env.VUE_APP_API_URL+'/api/webTopping/update'
      this.selectedTopping.title = this.toppingTitle
      this.selectedTopping.minQty = this.toppingMin
      this.selectedTopping.maxQty = this.toppingMax
      this.selectedTopping.freeQty = this.toppingFree
      if (del) {
        if (confirm('Are you sure to delete this item?')) {
          this.selectedTopping.items = this.selectedTopping.items.filter((item)=>{ return item.id !== id })
        } else {return}
      }
      if (edit) {
        for (var i = 0; i < this.selectedTopping.items.length; i++) {
          if (this.selectedTopping.items[i].id == id) {
            this.selectedTopping.items[i].title = this.editItemTitle
            this.selectedTopping.items[i].price = this.editItemPrice
            this.selectedTopping.items[i].free = this.editItemFree ? 'Yes' : 'No'
          }
        }
      }

      axios
      .post(url, this.selectedTopping, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping updated successfully", "Success");

        this.webToppingsList ()
        this.showItemEditModal = false
      })
      .catch((e)=>{this.isLoading = false})
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 69px;
  background: #FFEDE3;
  border: 1px solid #FFD8BC;
  box-sizing: border-box;
  border-radius: 0px;
}

.headerText {
  position: absolute;
  width: 200px;
  height: 19px;
  left: 28px;
  top: 20px;
  color: #FE750F;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.headerBtn {
  padding-top: 12px;
  padding-right: 44px;
}

.headerBtnStyle {
  background: #FFF5FF !important;
  color: #FE7611;
  margin-top: 7%;
}
.toppingAddBtn{
  padding: 6px 30px;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
  color: #fff;
  border: unset;
}
.cancelBtn{
  padding: 6px 20px;
  background: #fff;
  color: #000;
  border: 1px solid;
}
.table > thead > tr > th {
  color: #E317E3;
  background: #FFF5FF;
}
.table > thead > tr > th:last-child, .table > tbody > tr > th:last-child, .table > tfoot > tr > th:last-child, .table > thead > tr > td:last-child, .table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
  width: auto;
}
.overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-title {
  padding: 10px;
  background-color: #FFEDE3;
  border-radius: 10px 10px 0 0;
  top: 10%;
  left: 35%;
}
.modal-vue .modal-own {
  position: fixed;
  width: 500px;
  z-index: 9999;
  margin: 0 auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 10px;
  top: 15%;
  left: 35%;
}

.modal-vue .close{
  position: absolute;
  top: 10px;
  right: 10px;
}
.submitBtn{
  background-color: red;
  color: #fff;
  border: unset;
}
</style>

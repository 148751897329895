<template>
  <div class="content pt-0">
    <div class="container">
      <div class="row" style="margin-bottom: 5% !important;">
        <div class="col-md-4 px-0 categoryCol">
          <div class="px-3">
            <h4 class="mt-0 d-inline-block">Category</h4>
            <button v-if="selectedCategory" type="button" @click="addNewTopping" class="btn btn-default addNewBtn mt-2">Add New</button>

            <input type="text" v-model="categoryName" class="form-control" style="margin-bottom: 5rem">
            <i v-if="selectedCategory" @click="deleteTopping" class="fa fa-trash deleteBtn"></i>
            <button v-if="selectedCategory" type="button" @click="editTopping" class="btn btn-default toppingAddBtn mt-4 mb-3">Update</button>
            <button v-else type="button" @click="addTopping" class="btn btn-default toppingAddBtn mt-4 mb-3">Add</button>
          </div>
          <div>
            <ul class="list-group list-group-flush" style="background-color: #fff">
              <li v-for="(item, index) in toppingsDataList" :key="index" @click="() => chooseCategory(item)" class="list-group-item category-list" style="cursor: pointer;" :id="'categoryChoosenId'+item.id">
                <span>
                  <span> {{item.title}} </span>
                  <i class="fa fa-chevron-right" :class="'category'+item.id" style="float: right; color: black; display: block;"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="selectedCategory" class="col-md-4 px-0 categoryCol">
          <div class="px-3">
            <h4 class="mt-0 d-inline-block">Selection</h4>
            <button v-if="selectedSelection" type="button" @click="addNewSelection" class="btn btn-default addNewBtn mt-2">Add New</button>
            <select class="form-control" v-model="selectionName">
              <option v-for="(selection, i) in selectionDataList" :key="i" :value="selection.title">{{ selection.title }}</option>
            </select>
            <input type="text" v-model="selectionName" class="form-control mt-2" style="margin-bottom: 2rem">
            <i v-if="selectedSelection" @click="deleteSelection" class="fa fa-trash deleteBtn"></i>
            <button v-if="selectedSelection" type="button" @click="editSelection" class="btn btn-default toppingAddBtn mt-4 mb-3">Update</button>
            <button v-else type="button" @click="addSelection" class="btn btn-default toppingAddBtn mt-4 mb-3">Add</button>
          </div>
          <div>
            <ul class="list-group list-group-flush" style="background-color: #fff">
              <li v-for="(item, index) in selectedCategory.items" :key="index" @click="() => chooseSelection(item)" class="list-group-item selection-list" style="cursor: pointer;" :id="'selectionChoosenId'+item.id">
                <span>
                  <span> {{item.title}} </span>
                  <i class="fa fa-chevron-right" :class="'selection'+item.id" style="float: right; color: black; display: block;"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="selectedSelection" class="col-md-4 px-0 priceCol">
          <div class="px-3">
            <h4 class="mt-0 d-inline-block">Price</h4>
            <button v-if="selectedPrice" type="button" @click="addNewPrice" class="btn btn-default addNewBtn mt-2">Add New</button>

            <input type="text" v-model="selectionPrice" class="form-control" style="margin-bottom: 5rem">
            <button v-if="selectedPrice" type="button" @click="editPrice" class="btn btn-default toppingAddBtn mt-4 mb-3">Update</button>
            <button v-else type="button" @click="addPrice" class="btn btn-default toppingAddBtn mt-4 mb-3">Add</button>
          </div>
          <div v-if="selectedSelection.priceChart && selectedSelection.priceChart.length > 0" style="margin-top: 3rem">
            <ul v-for="(price, index) in selectedSelection.priceChart" :key="index" class="list-group list-group-flush price-list">
              <li class="list-group-item price-item" @click="() => choosePrice(price, index)" :id="'priceChoosenId'+index">
                <span> {{price}} </span>
              </li>
              <li class="price-item">
                <i @click="() => deletePrice(price)" class="fa fa-trash deleteBtn"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="overlay">
      <img src="/img/loading.gif" alt="GIF" width="30" height="30" class="mx-auto d-block" style="margin-top: 20%">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      restaurantId: parseInt(localStorage.getItem('restaurantId')),
      jwtToken: '',
      requestHeader: '',
      categoryName: '',
      toppingsDataList: [],
      categoryName: '',
      selectedCategory: '',

      selectionDataList: '',
      selectedSelection: '',
      selectionName: '',

      selectionPrice: '',
      selectedPrice: '',

      isLoading: false
    }
  },
  created () {
    this.jwtToken = this.$cookies.get('token') || null
    this.requestHeader = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "*",
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
    this.customToppingsList()
    this.selectionList()
  },
  methods: {
    addTopping () {
      if (!this.categoryName) {
        this.$toastr.w("Category name required", "Warning");
        return
      }
      this.isLoading = true

      const url = process.env.VUE_APP_API_URL+'/api/customTopping/create'

      const dataToSubmit = {
        "restaurantId": this.restaurantId,
        "title": this.categoryName
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping created successfully", "Success");

        this.categoryName = ''
        this.customToppingsList ()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    editTopping () {
      this.isLoading = true

      const url = process.env.VUE_APP_API_URL+'/api/customTopping/update'

      const dataToSubmit = {
        "id": this.selectedCategory.id,
        "restaurantId": this.restaurantId,
        "title": this.categoryName,
        "items": this.selectedCategory.items
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping updated successfully", "Success");
        this.customToppingsList()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    addNewTopping () {
      this.selectedCategory = ''
      this.categoryName = ''
      // remove other list bg color
      let catItemList = document.getElementsByClassName('category-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
      this.addNewSelection()
      this.addNewPrice()
    },
    deleteTopping () {
      if (confirm('Are you sure to delete this topping?')) {
        let url = process.env.VUE_APP_API_URL+`/api/customTopping/delete?id=${this.selectedCategory.id}`

        axios
        .post(url, {}, { headers: this.requestHeader })
        .then((data)=> {
          if (data.status == 200) {
            this.$toastr.s("Deleted Successfully", "Success");
            this.customToppingsList()
            this.addNewTopping()
            this.addNewSelection()
            this.addNewPrice()
          }
        })
      }
    },
    chooseCategory (category) {
      if (this.selectedCategory) {
        // reset arrow of previous element
        var prevElement = document.getElementsByClassName("category"+this.selectedCategory.id)[0];
        prevElement.classList.remove("fa-chevron-left");
        prevElement.classList.add("fa-chevron-right");
      }
      // set arrow of current element
      var curElement = document.getElementsByClassName("category"+category.id)[0];
      curElement.classList.remove("fa-chevron-right");
      curElement.classList.add("fa-chevron-left");

      this.selectedCategory = category
      this.categoryName = category.title
      // remove other list bg color
      let catItemList = document.getElementsByClassName('category-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
      //set selected item bg color
      let x = document.getElementById('categoryChoosenId'+category.id)
      x.style.background = "#FDEAD5"

      // reset selection
      this.addNewSelection()
    },
    chooseSelection (selection) {
      if (this.selectedSelection) {
        // reset arrow of previous element
        var prevElement = document.getElementsByClassName("selection"+this.selectedSelection.id)[0];
        prevElement.classList.remove("fa-chevron-left");
        prevElement.classList.add("fa-chevron-right");
      }
      // set arrow of current element
      var curElement = document.getElementsByClassName("selection"+selection.id)[0];
      curElement.classList.remove("fa-chevron-right");
      curElement.classList.add("fa-chevron-left");

      this.selectedSelection = selection
      this.selectionName = selection.title
      // remove other list bg color
      let catItemList = document.getElementsByClassName('selection-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
      //set selected item bg color
      let x = document.getElementById('selectionChoosenId'+selection.id)
      x.style.background = "#FDEAD5"
    },
    customToppingsList (makeSelected=false) {
      let url = process.env.VUE_APP_API_URL+`/api/customTopping?restaurantId=${this.restaurantId}`

      axios
      .get(url, { headers: this.requestHeader })
      .then((data)=> {
        this.toppingsDataList = data.data
        // after creating/updating selection set selectedCategory
        if (makeSelected) {
          for (var i = 0; i < this.toppingsDataList.length; i++) {
            if (this.toppingsDataList[i].id == this.selectedCategory.id) {
              this.selectedCategory = this.toppingsDataList[i]
            }
          }
        }
        // if (this.toppingEditClicked) {
        //   for (var i = 0; i < this.toppingsDataList.length; i++) {
        //     if (this.toppingsDataList[i].id == this.selectedTopping.id) {
        //       this.selectedTopping = this.toppingsDataList[i]
        //     }
        //   }
        // }
      })
    },
    selectionList (makeSelected=false) {
      let url = process.env.VUE_APP_API_URL+`/api/customTopping/allSelection?restaurantId=${this.restaurantId}`

      axios
      .get(url, { headers: this.requestHeader })
      .then((data)=> {
        this.selectionDataList = data.data
        // after creating/updating selection set selectedCategory
        if (makeSelected) {
          for (var i = 0; i < this.selectionDataList.length; i++) {
            if (this.selectionDataList[i].id == this.selectedSelection.id) {
              this.selectedSelection = this.selectionDataList[i]
            }
          }
        }
      })
    },
    addSelection () {
      if (!this.selectionName) {
        this.$toastr.w("Selection name required", "Warning");
        return
      }
      this.isLoading = true

      const url = process.env.VUE_APP_API_URL+'/api/customTopping/addSelection'

      const dataToSubmit = {
        "customToppingId": this.selectedCategory.id,
        "title": this.selectionName
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping created successfully", "Success");

        this.selectionName = ''
        this.customToppingsList(true)
        this.selectionList()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    editSelection () {
      if (!this.selectionName) {
        this.$toastr.w("Selection name required", "Warning");
        return
      }
      this.isLoading = true

      const url = process.env.VUE_APP_API_URL+'/api/customTopping/updateSelection'

      const dataToSubmit = {
        "id": this.selectedSelection.id,
        "customToppingId": this.selectedCategory.id,
        "title": this.selectionName
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping updated successfully", "Success");
        this.customToppingsList(true)
        this.selectionList()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    deleteSelection () {
      if (confirm('Are you sure to delete this topping?')) {
        let url = process.env.VUE_APP_API_URL+`/api/customTopping/deleteSelection?id=${this.selectedCategory.id}`
        const dataToSubmit = {
          "id": this.selectedSelection.id,
          "customToppingId": this.selectedCategory.id
        }
        axios
        .post(url, dataToSubmit, { headers: this.requestHeader })
        .then((data)=> {
          if (data.status == 200) {
            this.$toastr.s("Deleted Successfully", "Success");
            this.customToppingsList(true)
            this.selectionList()
            this.addNewSelection()
            this.addNewPrice()
          }
        })
      }
    },
    addNewSelection () {
      this.selectedSelection = ''
      this.selectionName = ''
      // remove other list bg color
      let catItemList = document.getElementsByClassName('selection-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
    },
    addPrice () {
      if (!this.selectionPrice) {
        this.$toastr.w("Selection price required", "Warning");
        return
      }
      this.isLoading = true

      const url = process.env.VUE_APP_API_URL+'/api/customTopping/priceSelection'

      const dataToSubmit = {
        "amount": this.selectionPrice,
        "categoryId": this.selectedCategory.id,
        "selectionId": this.selectedSelection.id,
        "function": "Add",
        "prevAmount": ""
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Price added successfully", "Success");

        this.selectionPrice = ''
        this.customToppingsList(true)
        this.selectionList(true)
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    editPrice () {
      if (!this.selectionPrice) {
        this.$toastr.w("Selection price required", "Warning");
        return
      }
      this.isLoading = true

      const url = process.env.VUE_APP_API_URL+'/api/customTopping/priceSelection'

      const dataToSubmit = {
        "amount": this.selectionPrice,
        "categoryId": this.selectedCategory.id,
        "selectionId": this.selectedSelection.id,
        "function": "Update",
        "prevAmount": this.selectedPrice
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Price updated successfully", "Success");
        this.customToppingsList(true)
        this.selectionList(true)
        this.addNewPrice()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    deletePrice (price) {
      if (confirm('Are you sure to delete this price?')) {
        this.isLoading = true

        const url = process.env.VUE_APP_API_URL+'/api/customTopping/priceSelection'
        const dataToSubmit = {
          "amount": price,
          "categoryId": this.selectedCategory.id,
          "selectionId": this.selectedSelection.id,
          "function": "Delete",
          "prevAmount": ""
        }
        axios
        .post(url, dataToSubmit, { headers: this.requestHeader })
        .then((data)=> {
          this.$toastr.s("Price deleted successfully", "Success");
          this.customToppingsList(true)
          this.selectionList(true)
          this.addNewPrice()
          this.isLoading = false
        })
        .catch((e)=>{this.isLoading = false})
      }
    },
    choosePrice (price, index) {
      this.selectedPrice = price
      this.selectionPrice = price
      // remove other list bg color
      let catItemList = document.getElementsByClassName('price-item')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
      //set selected item bg color
      let x = document.getElementById('priceChoosenId'+index)
      x.style.background = "#FDEAD5"
    },
    addNewPrice () {
      this.selectedPrice = ''
      this.selectionPrice = ''
      // remove other list bg color
      let catItemList = document.getElementsByClassName('price-item')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
    }
  }
}
</script>

<style scoped>
.categoryCol{border: 1px solid rgba(0, 0, 0, 0.125);}
.categoryCol div:first-child, .priceCol div:first-child{
  background-color: #FDEAD5;
  display: flow-root;
}
.categoryCol div:nth-child(2){
  margin-top: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.price-list{background-color: #fff; display: block}
.price-list li{border:  1px solid;}
.price-list li:first-child{cursor: pointer; width: 70%; display: inline-block; margin-left: 1rem;}
.price-list li:nth-child(2){cursor: pointer; width: 15%; display: inline-block; float: right; margin-right: 1rem}
.price-item{margin-bottom: 1px;}
.toppingAddBtn{
  padding: 6px 40px;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
  color: #fff;
  border: unset;
  float: right;
  border-radius: 15px;
}
.addNewBtn{
  padding: 2px 10px;
  background: #fff;
  color: #000;
  box-shadow: 0px 6px 10px rgba(95, 95, 95, 0.18);
  border: unset;
  border-radius: 15px;
  float: right;
}
.deleteBtn{
  font-size: 2rem;
  margin-top: 1.5rem;
  color: red;
  cursor: pointer;
}
.price-list .deleteBtn{
  font-size: 2rem !important;
  margin-top: 8px !important;
  height: 40px;
  margin-left: 13px;
}
.cancelBtn{
  padding: 6px 20px;
  background: #fff;
  color: #000;
  border: 1px solid;
}

.overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

</style>

<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0">
          <top-navbar :title="restaurantName" search="false"></top-navbar>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-2">
          <sidebar-link to="/admin/restaurant/order/history" class="dashboard-link">
            <button class="btn orderHistoryBtn">Back to Order</button>
          </sidebar-link>
          <sidebar-link to="/admin/restaurant/report" class="dashboard-link my-3">
            <button class="btn orderHistoryBtn">Back to Report</button>
          </sidebar-link>
          <div class="row mb-3 mx-0" style="background-color: #fff; text-align: center; border-radius: 2px">
            <div class="col-md-6 col-sm-12 px-0">
              <label>From</label>
              <datepicker v-model="startDate" format="d/MM/yyyy" placeholder="Select Date"></datepicker>
            </div>
            <div class="col-md-6 col-sm-12 px-0">
              <label>To</label>
              <datepicker v-model="endDate" format="d/MM/yyyy" placeholder="Select Date"></datepicker>
            </div>
          </div>
          <button @click="getBookingHistory" class="btn applyBtn">Apply Filter</button>
        </div>
        <div class="col-md-10 col-sm-10" style="background-color: white">
          <h3>All Booking</h3>
          <b-table
            class="orderTable"
            :items="allBooking"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            striped
            bordered
            show-empty
          ></b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
          <div class="row orderBtm">
            <div class="col md-6">
              Total booking: {{ allBooking.length }}
            </div>
          </div>
          </div>
      </div>
    </div>

    <div v-if="showFilterLoading" class="overlay">
      <img src="/img/loading.gif" alt="GIF" width="30" height="30" class="mx-auto d-block" style="margin-top: 20%">
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

import TopNavbar from '../../../layout/TopNavbar'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import {BPagination, BTable} from 'bootstrap-vue'

export default {
  components: {
    TopNavbar,
    Datepicker,
    BPagination,
    BTable
  },
  data () {
    return {
      restaurantName: localStorage.getItem('restaurantName'),
      jwtToken: this.$cookies.get('token') || null,
      startDate: new Date(),
      endDate: new Date(),
      allBooking: [],
      showFilterLoading: false,
      perPage: 10,
      currentPage: 1,
      // fields: ['Name', 'ID', 'Type', 'Date', 'Time', 'Payment', 'Total', 'Status', 'View']
    }
  },
  mounted () {
    this.getBookingHistory()
  },
  computed: {
    rows() {
      return this.allBooking.length
    }
  },
  methods: {
    getBookingHistory () {
      this.showFilterLoading = true
      const sDate = this.startDate != '' ? moment(this.startDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      const eDate = this.endDate != '' ? moment(this.endDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')

      let url = process.env.VUE_APP_API_URL+'/api/booking?restaurantId='
        + localStorage.getItem('restaurantId')
        + '&startDate=' + sDate
        + '&endDate=' + eDate
        + '&currentTime=' + moment().format('h:m A')
      // send request
      axios
        .get(url,
          {
            headers: { Authorization: `Bearer ${this.jwtToken}` }
          })
        .then((data) => {
          this.allBooking = []
          data.data.forEach(item => {
            this.allBooking.push(
              {
                // created: item.createdAt,
                name: item.firstName +' '+item.lastName,
                phone: item.phone,
                guest: item.guest,
                time: item.date +' '+ item.time,
                status: item.status,
                comment: item.msg
              }
            )
          });
          this.showFilterLoading = false
        })
        .catch((error)=>{
          this.showFilterLoading = false
        })
    }
  }
}
</script>
<style scoped>
.overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}
.dashboard-link{list-style-type: none;}
/* .content{padding: 10px 0;} */
.orderHistoryBtn{
  background-color: #fff;
  color: #000;
  width: 100%;
  opacity: 1 !important;
}
.applyBtn{
  background-color: green;
  color: #fff;
  width: 100%;
  opacity: 1 !important;
}
.orderBtm{
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
  padding: 10px;
  color: #fff;
}
/* .orderTable{background-color: #FD7902;} */
.orderTable table{width: 11% !important; background-color: #FD7902 !important;}
</style>

<template>
    <div class="sidebar">
        <div class="sidebar-wrapper">
            <div class="logo">
                <a href="#" class="simple-text logo__container">
                    <div class="logo-img">
                        <img src="/img/logo.jpeg" width="100%" alt="">
                    </div>
                </a>
            </div>

            <slot name="content"></slot>
            <ul class="nav nav-main__links">
                <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
                <slot>
                    <sidebar-link v-for="(link,index) in sidebarLinks"
                    :key="link.name + index"
                    :to="link.path"
                    @click="closeNavbar"
                    :link="link">
                    <i :class="link.icon"></i>
                    <p>{{link.name}}</p>
                </sidebar-link>
            </slot>
        </ul>
        <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
            <slot name="bottom-links"></slot>
        </ul>
    </div>
</div>
</template>
<script>
import SidebarLink from './SidebarLink.vue'

export default {
    components: {
        SidebarLink
    },
    props: {
        title: {
            type: String,
            default: 'Vue LBD'
        },
        activeColor: {
            type: String,
            default: 'success',
            validator: (value) => {
                let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
                return acceptedValues.indexOf(value) !== -1
            }
        },
        sidebarLinks: {
            type: Array,
            default: () => []
        },
        autoClose: {
            type: Boolean,
            default: true
        }
    },
    provide () {
        return {
            autoClose: this.autoClose
        }
    },
    computed: {

    }
}

</script>
<style>
    .logo-img > img {
        max-width: 41px !important;
    }
</style>
<style scoped>
.sidebar .sidebar-wrapper {
    display: flex;
    flex-direction: column;
}
.sidebar:after{
    background: #E5E5E5;
}
.sidebar .nav-main__links {
    flex: 1;
    margin-top: 10px;
    border-radius: 0 5px 0 0;
}
.sidebar .sidebar-wrapper .logo{
    background-color: #555555;
    border-radius: 0 0 5px 0;
}
.sidebar .sidebar-wrapper .logo .logo__container {
    padding-left: 10px;
}
.sidebar .sidebar-wrapper .nav-mobile-menu, .nav-main__links{
    background-color: #555555;
}
.dropdown-menu{
    position: relative
}
</style>

<style media="screen">
    .sidebar .sidebar-wrapper, body > .navbar-collapse .sidebar-wrapper {
        padding-bottom: 0px;
    }
</style>

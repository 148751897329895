<template>

  <div class="content">
    <div class="container" style="padding-left: 10px; overflow-x: hidden">
      <div class="row">

        <div class="col-md-12 px-0">
          <top-navbar :title="restaurantName"></top-navbar>
          <div class="" style="background-color: #FFFFFF;padding-top: 2%">
            <h2 class="text-center mt-0" style="border-bottom: 2px solid;">Image</h2>
            <!-- Welcome Background Image -->
            <div style="background: rgb(209 201 218)">
              <h3 class="pl-3">Welcome Background</h3>
              <div class="row">
                <div class="col-md-4 text-center my-2">
                  <img :src="welcomeBgImage" width="200" alt="Image" class="header-image">
                  <i class="fa fa-times position-absolute header-delete"
                     @click="() => deleteImage(webData.welcomeBgImagePublicId, 'WelcomeBgImage')" aria-hidden="true"></i>
                </div>
                <div class="col-md-12">
                  <form @submit.prevent="uploadWelcomeBgImage">
                    <div class="row">
                      <div class="col-md-4 text-center p-4">
                        <img src="/img/upload.png" alt=""> Upload New Image
                        <input type="file" @change="welcomeBgImageSelected" accept="image/*" class="uploadBtn"/>
                      </div>
                      <div class="col-md-4 text-left p-4 contentBtnAlign">
                        <button class="btn submitBtn">Upload</button>
                        <img v-if="showWelcomeBgImageLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <h2 class="text-center mt-5" style="border-bottom: 2px solid;">Content</h2>
            <h3 class="content-heading">Slider Text</h3>
            <div class="row pl-5 w-100">
              <div class="col-md-12">
                <vue-editor v-model="sliderText" />
              </div>
            </div>
            <h3 class="content-heading">Colors</h3>
            <div class="row pl-5 w-100">
              <div class="col-md-3">
                <label for="">Welcome Bg Shadow</label>
                <input type="text" class="form-control" v-model="welcomeBgShadowColor" placeholder="#FFFFFF">
              </div>
              <div class="col-md-3">
                <label for="">Review Font Color</label>
                <input type="text" class="form-control" v-model="reviewFontColor" placeholder="#FFFFFF">
              </div>
              <div class="col-md-12 pt-5 contentBtnAlign">
                <button class="btn submitBtn" @click="updateWebConfig">Upload</button>
                <img v-if="showWebConfigLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
              </div>
            </div>

            <h3 class="content-heading">Review</h3>
            <div class="row pl-5 w-100">
              <div v-for="(review, i) in reviews" :key="i" class="col-md-4">
                <h4>{{ review.name }}</h4>
                <span v-for="(star, j) in Number(review.ratting)" :key="j">
                  <i class="fa fa-star" style="color: yellow" aria-hidden="true"></i>
                </span>
                <p>{{ review.comments }}</p>
                <i @click="() => deleteReview(review.id)" class="fa fa-times position-absolute header-delete" aria-hidden="true"></i>
              </div>
            </div>
            <div class="row pl-5">
              <div class="col-md-6 col-12 mt-3">
                <label for="">Reviewer Name</label>
                <input type="text" class="form-control" v-model="reviewer" placeholder="Reviewer name">
              </div>
              <div class="col-md-6 col-12 mt-3">
                <label for="">Star</label>
                <select v-model="reviewStar" class="form-control mb-3">
                  <option value="5">5</option>
                  <option value="4">4</option>
                  <option value="3">3</option>
                  <option value="2">2</option>
                  <option value="1">1</option>
                </select>
              </div>
              <div class="col-md-12 col-11 mt-3">
                <label for="">Message</label>
                <input type="text" class="form-control" v-model="reviewMsg" placeholder="Message">
              </div>
            </div>

            <div class="col-md-12 p-5 contentBtnAlign">
              <button class="btn submitBtn" @click="updateReview">Upload</button>
              <img v-if="showReviewLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TopNavbar from '../../layout/TopNavbar'
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
  components: {
    TopNavbar
  },
  data () {
    return {
      welcomeBgImage: '',
      showWelcomeBgImageLoading: false,
      welcomeBgShadowColor: '',
      reviewFontColor: '',
      reviews: '',
      reviewer: '',
      reviewStar: '',
      reviewMsg: '',
      sliderText: '',
      jwtToken: '',
      webData: '',
      restaurantName: localStorage.getItem('restaurantName'),
      showWebConfigLoading: false,
      showReviewLoading: false
    }
  },
  created() {
    this.jwtToken = this.$cookies.get('token') || null
    this.webConfig()
    this.getAllReviews()
  },
  methods: {
    welcomeBgImageSelected(e){
      this.welcomeBgImage = e.target.files[0]
    },
    uploadWelcomeBgImage() {
      this.showWelcomeBgImageLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.welcomeBgImage || '')
      dataToSubmit.append('imageType', 'WelcomeBgImage')
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWelcomeBgImageLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    deleteImage(publicId, headerType){
      if (confirm('Please confirm to delete.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/webConfig/v2/deleteImage'
        // request data
        let dataToSubmit = {
          "restaurantId": parseInt(localStorage.getItem('restaurantId')),
          "publicId": publicId,
          "imageType": headerType
        }
        // send request
        axios
          .post(url, dataToSubmit, {
            headers: this.requestHeader
          })
          .then((data) => {
            if (data.status == 200) {
              this.$toastr.s("Successfully Deleted.", "Success");
              setTimeout(()=> {this.$router.go()}, 1000)
            }
          })
      }
    },
    webConfig(){
      // request header
      this.requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig?restaurantId='+localStorage.getItem('restaurantId')
      // send request
      axios
        .get(url,
          {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            }
          })
        .then((result) => {
          if (result.status === 403) {
              let logoutResponse = this.logout()
              if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
              }
          }
          this.webData = result.data
          this.welcomeBgShadowColor = result.data.welcomeBgShadowColor
          this.reviewFontColor = result.data.reviewFontColor
          this.welcomeBgImage = result.data.welcomeBgImage
          this.sliderText = result.data.sliderText
        })
    },
    updateWebConfig(){
      this.showWebConfigLoading = true

      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "welcomeTxt": this.webData.welcomeTxt,
        "aboutUs": this.webData.aboutUs,
        "privacyPolicy": this.webData.privacyPolicy,
        "termsCondition": this.webData.termsCondition,
        "lat": this.webData.lat,
        "long": this.webData.long,
        "mapIframe": this.webData.mapIframe,
        "stripeSecretKey": this.webData.stripeSecretKey,
        "stripePublishKey": this.webData.stripePublishKey,
        "headerColor": this.webData.headerColor,
        "navigationColor": this.webData.navigationColor,
        "navbarHoverColor": this.webData.navbarHoverColor,
        "mobileNavBgColor": this.webData.mobileNavBgColor,
        "alertBgColor": this.webData.alertBgColor,
        "alertBoxBtnBgColor": this.webData.alertBoxBtnBgColor,
        "alertBoxBtnFontColor": this.webData.alertBoxBtnFontColor,
        "welcomeTextColor": this.webData.welcomeTextColor,
        "welcomeBackgroundColor": this.webData.welcomeBackgroundColor,
        "welcomeBgShadowColor": this.welcomeBgShadowColor,
        "sliderText": this.sliderText,
        "headerTwoBackgroundColor": this.webData.headerTwoBackgroundColor,
        "aboutTextColor": this.webData.aboutTextColor,
        "aboutBackgroundColor": this.webData.aboutBackgroundColor,
        "reviewTextColor": this.webData.reviewTextColor,
        "reviewFontColor": this.reviewFontColor,
        "reviewBackgroundColor": this.webData.reviewBackgroundColor,
        "footerColor": this.webData.footerColor,
        "footerTextColor": this.webData.footerTextColor,
        "rightsReservedTextColor": this.webData.rightsReservedTextColor,
        "poweredByColor": this.webData.footerPoweredByColor,
        "categoryBgForDesktop": this.webData.categoryBgForDesktop,
        "categoryBgForMobile": this.webData.categoryBgForMobile,
        "categoryHoverBgColor": this.webData.categoryHoverBgColor,
        "categoryHoverTextColor": this.webData.categoryHoverTextColor,
        "cartItemBgColor": this.webData.cartItemBgColor,
        "buttonColor": this.webData.buttonColor,
        "buttonFontColor": this.webData.buttonFontColor,
        "contactPageBgColor": this.webData.contactPageBgColor,
        "openingTime": this.webData.openingTime,
        "closingTime": this.webData.closingTime,
        "allergyInformation": this.webData.allergyInformation,
        "lunchOpeningHours": this.webData.lunchOpeningHours,
        "socialMedia": this.webData.socialMedia,
        "webAddress": this.webData.webAddress
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/update'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWebConfigLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    getAllReviews () {
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/review?restaurantId='+localStorage.getItem('restaurantId')
      // send request
      axios
        .get(url,
          {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            }
          })
        .then((result) => {
          if (result.status === 403) {
              let logoutResponse = this.logout()
              if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
              }
          }
          this.reviews = result.data
        })
    },
    updateReview () {
      this.showReviewLoading = true

      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "name": this.reviewer,
        "ratting": this.reviewStar,
        "comments": this.reviewMsg,
      }
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/review/create'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showReviewLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    deleteReview(id){
      if (confirm('Please confirm to delete.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/review/delete?id='+id

        // send request
        axios
          .get(url, {
            headers: this.requestHeader
          })
          .then((data) => {
            if (data.status == 200) {
              this.$toastr.s("Successfully Deleted.", "Success");
              setTimeout(()=> {this.$router.go()}, 1000)
            }
          })
      }
    },
  }
}
</script>

<style scoped>
.uploadBtn {
  cursor: pointer;
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}
.content-heading{padding-left: 2rem; color: #38ACE2}
.content-heading h3{color: #38ACE2}
.header-delete{width: 40px; height:40px; top: 0; cursor:pointer; font-size: 1.5rem;right: 20px; color: red;}

</style>

import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import Dashboard from 'src/pages/Dashboard.vue'
import UserProfile from 'src/pages/UserProfile.vue'
import Subscription from 'src/pages/Subscription'
import Login from 'src/pages/Login'

// Admin pages : create owner
import CreateOwner from 'src/components/OwnerManagement/CreateOwner.vue'
import OwnerDashboard from 'src/components/OwnerManagement/Dashboard.vue'
import OwnerList from 'src/components/OwnerManagement/OwnerList.vue'
import MenuManagement from 'src/components/MenuManagement/MenuManagement.vue'
import ContentManagement from 'src/components/ContentManagement/index.vue'
import ContentManagementV2 from 'src/components/ContentManagement/indexV2.vue'
import BestSells from 'src/components/ContentManagement/BestSells.vue'
import DealSet from 'src/components/MenuManagement/DealSet'
import HookItem from 'src/components/MenuManagement/HookItem'
import BookingList from 'src/components/OwnerManagement/BookingList'
import CustomerList from 'src/components/OwnerManagement/CustomerList'

//Report
import Report from '@/pages/Report/Index'

import RestaurantReport from '@/pages/Report/Restaurant/Index'
import RestaurantOrderHistory from '@/pages/Report/Restaurant/OrderHistory'
import RestaurantBookingHistory from '@/pages/Report/Restaurant/BookingHistory'

import MigrateMenu from '@/pages/MigrateMenu'
import Enquiries from '@/pages/Enquiries/index.vue'
import EnquiryDetails from '@/pages/Enquiries/details.vue'

//Booking
// import Booking from "@/pages/Booking/Index";
// import CreateBooking from "@/pages/Booking/Create"

const routes = [
  {
    path: '/',
    component: Login,
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: 'create-owner',
        name: 'CreateOwner',
        component: CreateOwner
      },
      {
        path: 'owner-dashboard',
        name: 'OwnerDashboard',
        component: OwnerDashboard
      },
      {
        path: 'owner-list',
        name: 'OwnerList',
        component: OwnerList
      },
      {
        path: 'menu-management',
        name: 'MenuManagement',
        component: MenuManagement
      },
      {
        path: 'content-management',
        name: 'ContentManagement',
        component: ContentManagement
      },
      {
        path: 'content-management-v2',
        name: 'ContentManagementV2',
        component: ContentManagementV2
      },
      {
        path: 'best-sells',
        name: 'BestSells',
        component: BestSells
      },
      {
        path: 'booking-list',
        name: 'BookingList',
        component: BookingList
      },
      {
        path: 'customer-list',
        name: 'CustomerList',
        component: CustomerList
      },
      {
        path: 'deal-set',
        name: 'DealSet',
        component: DealSet
      },
      {
        path: 'hook-items',
        name: 'HookItem',
        component: HookItem
      },
      {
        path: 'user',
        name: 'User',
        component: UserProfile
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: Subscription
      },
      {
        path: 'report',
        name: 'Report',
        component: Report
      },
      {
        path: 'restaurant/report',
        name: 'RestaurantReport',
        component: RestaurantReport
      },
      {
        path: 'restaurant/order/history',
        name: 'RestaurantOrderHistory',
        component: RestaurantOrderHistory
      },
      {
        path: 'restaurant/booking/history',
        name: 'RestaurantBookingHistory',
        component: RestaurantBookingHistory
      },
      {
        path: 'migrate-menu',
        name: 'MigrateMenu',
        component: MigrateMenu
      },
      {
        path: 'enquiries',
        name: 'Enquiries',
        component: Enquiries
      },
      {
        path: 'enquiries/details',
        name: 'EnquiryDetails',
        component: EnquiryDetails
      },
      // {
      //   path: 'report/restaurant/:id',
      //   name: 'RestaurantReport',
      //   component: RestaurantReport
      // },
      // {
      //   path: 'booking',
      //   name: 'Booking',
      //   component: Booking
      // },
      // {
      //   path: 'booking/create',
      //   name: 'CreateBooking',
      //   component: CreateBooking
      // }
    ]
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes

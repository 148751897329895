<template>
  <div class="content">
    <div class="container px-0">
      <div class="row w-100 mx-0">
        <div class="col-md-12 col-12 px-0">
          <top-navbar title="OWNER" search="false"></top-navbar>
        </div>
        <div class="col-md-3" style="margin-top: 3rem">
          <sidebar-link v-if="userRole == 'Admin'" to="/admin/migrate-menu" class="menuMigrateBtn">
            <p class="mb-0 text-white">Menu Migration</p>
          </sidebar-link><br><br>
          <button v-if="userRole == 'Admin'" @click="showConfirmModal=true" class="menuMigrateBtn" style="padding: .8rem">
            Go {{ maintenanceMode == 'Yes' ? 'Online' : 'Offline' }}
          </button><br><br>
          <sidebar-link v-if="userRole == 'Admin'" to="/admin/enquiries" class="menuMigrateBtn">
            <p class="mb-0 text-white">Enquiries {{ pendingEnquiry }}</p>
          </sidebar-link>
        </div>
        <div class="col-md-6 text-center" style="margin-top: 2rem">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 px-0 text-right restaurantList">
              <sidebar-link to="/admin/owner-list">
                <img src="/img/list-icon.png" alt="Logo">
                <h3 class="text-white">Restaurant List</h3>
              </sidebar-link>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 px-0 text-left createRestaurant">
              <sidebar-link to="/admin/create-owner?edit=false">
                <img src="/img/create-restaurant.png" alt="Logo">
                <h3 class="text-white">Create Restaurant</h3>
              </sidebar-link>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 px-0 text-right report">
              <sidebar-link to="/admin/report">
                <img src="/img/report-icon.png" alt="Logo">
                <h3 class="text-white">Report</h3>
              </sidebar-link>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 px-0 text-left subscription">
              <div>
                <img src="/img/subscription-icon.png" alt="Logo">
                <h3 class="text-white">Subscription</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-12">
          <div class="logoutBtn">
            <h4 @click="logoutUser">Logout</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <!-- modal -->
      <div class="modal-own " v-if="showConfirmModal">
        <div class="modal-body text-center" :class="maintenanceMode == 'Yes' ? '' : 'px-5'" :style="{padding: maintenanceMode == 'Yes' ? '4rem !important' : ''}">
          <div v-if="maintenanceMode == 'No'">
            <h2 style="color: red">WARNING!</h2>
            <h3 style="font-weight: bold" class="mb-5">This action will take all websites offline.
              Are you sure you want to proceed?
            </h3>
          </div>
          <div v-else>
            <h3 style="font-weight: bold" class="mb-5">
              Are you sure you want to make all websites live?
            </h3>
          </div>
          <button type="button" @click="showConfirmModal = false" class="menuMigrateCancelBtn">Cancel</button>
          <button type="button" @click="submitMaintenanceMode" class="menuMigrateContinueBtn">Yes</button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import TopNavbar from '../layout/TopNavbar'
  import logout from './../mixins/logoutMixins.js'
  import axios from 'axios'

  export default {
    components: {
      TopNavbar
    },
    data () {
      return {
        ownerMgmt: false,
        isMenuManagement: false,
        userRole: this.$cookies.get('adminData').role,
        jwtToken: this.$cookies.get('token') || null,
        maintenanceMode: '',
        showConfirmModal: false,
        pendingEnquiry: 0
      }
    },
    mixins: [logout],
    mounted () {
      this.isUnderMaintenance()
      this.businessRequestList()
    },
    methods: {
      logoutUser() {
        let logoutResponse = this.logout()

        if (logoutResponse === 1) {
            this.$router.push({ path: '/login' })
        }
      },
      isUnderMaintenance(){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/webConfig?restaurantId=41'
        // send request
        axios
          .get(url,
            {
              headers: {
                Authorization: `Bearer ${this.jwtToken}`,
              }
            })
          .then((result) => {
            this.maintenanceMode = result.data.maintenanceMode
          })
      },
      submitMaintenanceMode () {
        const url = process.env.VUE_APP_API_URL+'/api/restaurant/updateMaintenanceMode?mode='+(this.maintenanceMode == 'Yes' ? 'No' : 'Yes')

        // send request
        axios
          .get(url,
            {
              headers: { Authorization: `Bearer ${this.jwtToken}` }
            })
          .then((data) => {
            this.$toastr.s(this.maintenanceMode == 'No' ? "Websites under maintenance.":"Websites are live.", "Success")
            this.showConfirmModal = false
            this.$router.go()
          })
          .catch((error)=>{
            // this.showFilterLoading = false
          })
      },
      businessRequestList () {
        let url = process.env.VUE_APP_API_URL+'/api/restaurantRequest/findByStatus?status=Pending'

        axios
        .get(url,
        {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          }
        })
        .then((data) => {
            this.pendingEnquiry = data.data.length

            if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                }
            }
        })
      }
    },
    watch: {
      $route (to, form) {
        if (to.name == 'MenuManagement' && to.path == '/admin/menu-management') {
          this.isMenuManagement = true
        }
        else {
          this.isMenuManagement = false
        }
      }
    }
  }

</script>
<style scoped>
  .createRestaurant li, .restaurantList li, .report li, .subscription div{
    width: 315px;
    height: 200px;
    background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
    border-radius: 10px;
    text-align: center;
    padding-top: 2rem;
    margin: 1rem;
    display: inline-block;
    position: relative;
  }
  .logoutBtn{
    width: 51%;
    background: linear-gradient(179.48deg, #7A7A7A 36.9%, #1C1C1C 93.77%);
    border-radius: 10px;
    text-align: center;
    display: block;
    margin: 0 auto;
    padding: .5rem 0;
    cursor: pointer;
  }
  .logoutBtn h4{
    margin: unset !important;
    color: #fff;
  }
  .modal-own{
    width: 53% !important;
    left: 24% !important;
    margin-top: 6rem !important;
    box-shadow: 1px 1px 15px 6px #888888;
  }
  .menuMigrateBtn{
    width: 56%;
    text-align: center;
    padding: 5px 15px;
    background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
    color: #fff;
    border: unset;
    border-radius: 10px;
    list-style-type: none;
    display: inline-block;
  }
  .menuMigrateCancelBtn{
    padding: 6px 30px;
    color: #000;
    background: #fff;
    border: unset;
    box-shadow: 1px 1px 4px 0px #888888;
  }
  .menuMigrateContinueBtn{
    margin-left: 5rem;
    padding: 6px 2.6rem;
    color: #fff;
    background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
    border: unset;
    box-shadow: 1px 1px 4px 0px #888888;
  }
</style>

<template>
  <div class="content pt-0">
    <div class="container">
      <div class="row" style="margin-bottom: 5% !important;">
        <div class="col-md-12 px-0">

          <div class="toppingsTable" style="background-color: #FFFFFF; padding: 2% 31px 31px 31px;">
            <div class="row">
              <!-- create topping form -->
              <div class="col-md-10">
                <form class="form-inline mb-5">
                  <div class="row">
                    <div class="col-md-3">
                      <label for="category" class="d-block">Category</label>
                      <select class="form-control w-100" v-model="selectedCategory">
                        <option v-for="(cat, i) in categoryDataList" :key="i" :value="cat">{{ cat.title }}</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <label for="min" class="d-block">Minimum</label>
                      <input type="text" v-model="toppingMin" class="form-control w-100" id="min">
                    </div>
                    <div class="col-md-2">
                      <label for="max" class="d-block">Maximum</label>
                      <input type="text" v-model="toppingMax" class="form-control w-100" id="max">
                    </div>
                    <div class="col-md-2">
                      <label for="free" class="d-block">Free</label>
                      <input type="text" v-model="toppingFree" class="form-control w-100" id="free">
                    </div>
                    <div class="col-md-1 pl-3">
                      <label class="d-block">Root</label>
                      <input type="checkbox" v-model="toppingRoot" class="form-control w-50">
                    </div>
                    <div class="col-md-2 text-center">
                      <button v-if="!toppingEditClicked" type="button" @click="addTopping" class="btn btn-default toppingAddBtn mt-4">Add</button>
                      <button v-else type="button" @click="() => editTopping()" class="btn btn-default toppingAddBtn mt-4">Save</button>
                    </div>
                  </div>
                </form>
              </div>
              <div v-if="toppingEditClicked" class="col-md-2">
                <button @click="initPriceMap" class="btn priceMapBtn w-50 mt-4">Price Mapping</button>
              </div>
            </div>

            <!-- created category list -->
            <table v-if="!toppingEditClicked" class="table table-bordered">
              <thead>
                <tr style="font-weight: bold; color: blue">
                  <th>Category</th>
                  <th>Minimum</th>
                  <th>Maximum</th>
                  <th>Free</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(topping, index) in toppingsDataList" :key="index">
                  <td>{{topping.title}}</td>
                  <td>{{topping.minQty}}</td>
                  <td>{{topping.maxQty}}</td>
                  <td>{{topping.freeQty}}</td>
                  <td><i class="fa fa-edit" @click="() => setEditToppingItem(topping)" style="cursor: pointer; font-size: 1.3rem"></i></td>
                  <td><i class="fa fa-trash" @click="() => deleteTopping(topping.id)" style="color: red; font-size: 1.3rem; cursor: pointer;"></i></td>
                </tr>
              </tbody>
            </table>

            <!-- price hook section -->
            <div v-if="toppingEditClicked && !priceMap.show" class="row" style="margin-bottom: 5% !important;">
              <div class="col-md-12">
                <p style="color:red">*Add root item price first</p>
              </div>
              <div class="col-md-4 px-0 categoryCol">
                <div>
                  <ul class="list-group list-group-flush" style="background-color: #fff">
                    <li
                      v-for="(item, index) in toppingsDataList"
                      :key="index"
                      @click="() => chooseCategory(item)"
                      class="list-group-item category-list"
                      style="cursor: pointer;"
                      :id="'categoryChoosenId'+item.id"
                    >
                      <span>
                        <span> {{item.title}} </span>
                        <i class="fa fa-chevron-right" :class="'category'+item.id" style="float: right; color: black; display: block;"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 px-0 categoryCol">
                <div>
                  <ul class="list-group list-group-flush" style="background-color: #fff">
                    <li
                      v-for="(item, index) in selectedEditCategory.items"
                      :key="index"
                      @click="() => chooseSelection(item)"
                      class="list-group-item selection-list"
                      style="cursor: pointer;"
                      :id="'selectionChoosenId'+item.id"
                    >
                      <span>
                        <span> {{item.title}} </span>
                        <i class="fa fa-chevron-right" :class="'selection'+item.id" style="float: right; color: black; display: block;"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 px-0 priceCol">
                <div>
                  <ul v-for="(price, index) in selectedEditSelection.priceChart" :key="index" class="list-group list-group-flush price-list">
                    <li class="list-group-item price-item" :id="'priceChoosenId'+index">
                      <span> {{price}} </span>
                    </li>
                    <li class="price-item">
                      <input @click="() => hookPrice(price)" class="deleteBtn" :checked="selectedEditSelection.total == price" type="radio" name="hookPrice">
                      <!-- <i  class="fa fa-trash deleteBtn"></i> -->
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- price mapping section -->
            <div v-if="priceMap.show" class="row" style="margin-bottom: 5% !important;">
              <div class="col-md-4 px-0 categoryCol">
                <div>
                  <ul class="list-group list-group-flush" style="background-color: #fff">
                    <li
                      v-for="(item, index) in priceMap.rootElement.items"
                      :key="index"
                      @click="()=>choosePriceMapRootItem(item.id)"
                      class="list-group-item pricemap-root-item-list"
                      :id="'priceMapRootItem'+item.id"
                      style="cursor: pointer;"
                    >
                      <span>
                        <span> {{item.title}} </span>
                        <i class="fa fa-chevron-right" :class="'priceMapRootItem'+item.id" style="float: right; color: black; display: block;"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 px-0 categoryCol">
                <div>
                  <ul v-for="(item, index) in toppingsDataList" :key="index" class="list-group list-group-flush" style="background-color: #fff">
                    <li v-if="item.root == 'No'" @click="() => choosePriceMapCategory(item)" class="list-group-item price-category-list" style="cursor: pointer;" :id="'priceCatChoosenId'+item.id">
                      <span>
                        <span> {{item.title}} </span>
                        <i class="fa fa-chevron-right" :class="'priceCategory'+item.id" style="float: right; color: black; display: block;"></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 px-0 categoryCol">
                <div>
                  <ul class="list-group list-group-flush" style="background-color: #fff">
                    <li v-for="(item, index) in priceMap.selectedCategory.items" :key="index" class="list-group-item price-map-selection-list" style="cursor: pointer;" :id="'priceMapSelectionChoosenId'+item.id">
                      <span>
                        <p @click="() => choosePriceMapSelection(item)" class="mb-0">
                          <span> {{item.title}} </span>
                          <i class="fa fa-chevron-down" :class="'priceSelection'+item.id" style="float: right; color: black; display: block;"></i>
                        </p>

                        <div class="mt-0" style="border: unset; padding: 11px; display: none;" :id="'priceMapPrices'+item.id">
                          <ul v-for="(price, i) in item.priceChart" :key="i" class="list-group list-group-flush">
                            <li class="list-group-item" style="border: unset" :id="'priceMapPriceChoosenId'+i">
                              <span style="float: left">£ {{price}} </span>
                                <input @click="() => priceMapHookPrice(item.id, price)" type="radio" :checked="isChecked(item.id, price) == true" :name="'itemprice'+item.id" style="float: right; width: 20px; height: 32px">
                            </li>
                          </ul>
                        </div>

                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>


    <div v-if="isLoading" class="overlay">
      <img src="/img/loading.gif" alt="GIF" width="30" height="30" class="mx-auto d-block" style="margin-top: 20%">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['toppingId'],
  data () {
    return {
      restaurantId: parseInt(localStorage.getItem('restaurantId')),
      jwtToken: '',
      requestHeader: '',
      categoryDataList: [],
      selectedCategory: '',
      toppingMin: '',
      toppingMax: '',
      toppingFree: '',
      toppingRoot: false,
      toppingsDataList: [],
      toppingEditClicked: false,
      selectedTopping: {},
      selectedEditCategory: '',
      selectedEditSelection: '',
      priceMap: {
        show: false,
        rootElement: '',
        selectedCategory: '',
        prices: '',
        rootItemId: ''
      },
      isLoading: false
    }
  },
  created () {
    this.jwtToken = this.$cookies.get('token') || null
    this.requestHeader = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "*",
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
    this.categoryList()
    this.toppingPlanList()
    // disable back button though from menuManagement it's enabled. It'll be enabled from edit
    this.$root.$emit('enableBackBtn', false)

    this.$root.$on('backToCustomToppingList', () => {
      this.backToCustomToppingList()
    })
  },
  methods: {
    categoryList () {
      let url = process.env.VUE_APP_API_URL+`/api/customTopping?restaurantId=${this.restaurantId}`

      axios
      .get(url, { headers: this.requestHeader })
      .then((data)=> {
        this.categoryDataList = data.data
        // if (this.toppingEditClicked) {
        //   for (var i = 0; i < this.toppingsDataList.length; i++) {
        //     if (this.toppingsDataList[i].id == this.selectedTopping.id) {
        //       this.selectedTopping = this.toppingsDataList[i]
        //     }
        //   }
        // }
      })
    },
    addTopping () {
      if (!this.selectedCategory || !this.toppingMin || !this.toppingMax || !this.toppingFree) {
        this.$toastr.w("Fill up all fields", "Warning");
        return
      }
      this.isLoading = true

      let url = process.env.VUE_APP_API_URL+'/api/customToppingPlan/create'

      let dataToSubmit = {
        "customToppingId": this.toppingId,
        "restaurantId": this.restaurantId,
        "customToppingCategoryId": this.selectedCategory.id,
        "msg": "",
        "root": this.toppingRoot ? 'Yes' : 'No',
        "title": this.selectedCategory.title,
        "minQty": this.toppingMin,
        "maxQty": this.toppingMax,
        "freeQty": this.toppingFree,
        "priceMap": [],
        "items": this.selectedCategory.items
      }

      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping created successfully", "Success");
        this.toppingPlanList()

        this.selectedCategory = ''
        this.toppingMin = ''
        this.toppingMax = ''
        this.toppingFree = ''
        this.toppingRoot = ''
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    setEditToppingItem (category) {
      this.toppingEditClicked = true
      for (var i = 0; i < this.toppingsDataList.length; i++) {
        if (this.toppingsDataList[i].id == category.id) {
          // this.selectedTopping = this.toppingsDataList[i]
          this.toppingTitle = this.toppingsDataList[i].title
          this.toppingMin = this.toppingsDataList[i].minQty
          this.toppingMax = this.toppingsDataList[i].maxQty
          this.toppingFree = this.toppingsDataList[i].freeQty
          this.toppingRoot = this.toppingsDataList[i].root == 'Yes' ? true : false
          this.selectedEditCategory = this.toppingsDataList[i]
          this.selectedCategory = this.toppingsDataList[i]

          setTimeout(() => {
            this.chooseCategory(this.selectedEditCategory)
          }, 500);
        }
      }
      for (var i = 0; i < this.categoryDataList.length; i++) {
        if (this.categoryDataList[i].id == category.customToppingCategoryId) {
          this.selectedCategory = this.categoryDataList[i]
        }
      }
      // enable back button when edit category is clicked
      this.$root.$emit('enableBackBtn', true)
    },
    editTopping () {
      if (!this.selectedCategory || !this.toppingMin || !this.toppingMax || this.toppingFree === '') {
        this.$toastr.w("Fill up all fields", "Warning");
        return
      }
      this.isLoading = true

      let url = process.env.VUE_APP_API_URL+'/api/customToppingPlan/update'

      let dataToSubmit = {
        "id": this.selectedEditCategory.id,
        "customToppingId": this.toppingId,
        "restaurantId": this.restaurantId,
        "customToppingCategoryId": this.selectedCategory.id,
        "msg": "",
        "root": this.toppingRoot ? 'Yes' : 'No',
        "title": this.selectedCategory.title,
        "minQty": this.toppingMin,
        "maxQty": this.toppingMax,
        "freeQty": this.toppingFree,
        "priceMap": [],
        "items": this.selectedCategory.items
      }

      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping updated successfully", "Success");
        this.toppingPlanList()

        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    toppingPlanList () {
      let url = process.env.VUE_APP_API_URL+`/api/customToppingPlan/findByCustomToppingId?restaurantId=${this.restaurantId}&customToppingId=${this.toppingId}`
      axios
      .get(url, { headers: this.requestHeader })
      .then((data)=> {
        this.toppingsDataList = data.data
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    chooseCategory (category) {
      if (this.selectedEditCategory) {
        // reset arrow of previous element
        var prevElement = document.getElementsByClassName("category"+this.selectedEditCategory.id)[0];
        prevElement.classList.remove("fa-chevron-left");
        prevElement.classList.add("fa-chevron-right");
      }
      // set arrow of current element
      var curElement = document.getElementsByClassName("category"+category.id)[0];

      curElement.classList.remove("fa-chevron-right");
      curElement.classList.add("fa-chevron-left");

      // remove other list bg color
      let catItemList = document.getElementsByClassName('category-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
      //set selected item bg color
      let x = document.getElementById('categoryChoosenId'+category.id)
      x.style.background = "#FDEAD5"

      this.selectedEditCategory = category
      this.selectedEditSelection = ''
      // remove selection list bg color
      let selectionList = document.getElementsByClassName('selection-list')
      for (var i = 0; i < selectionList.length; i++) {
        selectionList[i].style.backgroundColor = '#ffffff'
      }
    },
    chooseSelection (selection) {
      if (this.selectedEditSelection) {
        // reset arrow of previous element
        var prevElement = document.getElementsByClassName("selection"+this.selectedEditSelection.id)[0];
        prevElement.classList.remove("fa-chevron-left");
        prevElement.classList.add("fa-chevron-right");
      }
      // set arrow of current element
      var curElement = document.getElementsByClassName("selection"+selection.id)[0];
      curElement.classList.remove("fa-chevron-right");
      curElement.classList.add("fa-chevron-left");

      this.selectedSelection = selection
      this.selectionName = selection.title
      // remove other list bg color
      let catItemList = document.getElementsByClassName('selection-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
      //set selected item bg color
      let x = document.getElementById('selectionChoosenId'+selection.id)
      x.style.background = "#FDEAD5"

      this.selectedEditSelection = selection
    },
    hookPrice (price) {
      let url = process.env.VUE_APP_API_URL+'/api/customToppingPlan/priceMapping'
      let dataToSubmit = {
        "restaurantId": this.restaurantId,
        "customToppingId": this.toppingId,
        "categoryId": this.selectedEditCategory.id,
        "itemId": this.selectedEditSelection.id,
        "amount": price,
        "hasPriceMapping": "No"
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Price added successfully", "Success");
      })
    },
    deleteTopping (val) {
      if (confirm('Are you sure to delete this topping?')) {
        let url = process.env.VUE_APP_API_URL+`/api/customToppingPlan/delete?id=${val}`

        axios
        .get(url, { headers: this.requestHeader })
        .then((data)=> {
          if (data.status == 200) {
            this.$toastr.s("Deleted Successfully", "Success");

            this.toppingPlanList()
          }
        })
      }
    },
    initPriceMap () {
      for (let i = 0; i < this.toppingsDataList.length; i++) {
        if (this.toppingsDataList[i].root == 'Yes') {
          this.priceMap.rootElement = this.toppingsDataList[i]
          this.priceMap.prices = this.toppingsDataList[i].priceMap.length > 0 ? this.toppingsDataList[i].priceMap[0].categories : ''
        }
      }
      this.priceMap.show = true
      setTimeout(() => {
        this.choosePriceMapRootItem(this.priceMap.rootElement.items[0].id)
      }, 300);
    },
    choosePriceMapRootItem (id) {
      if (this.priceMap.rootItemId) {
        // reset arrow of previous element
        var prevElement = document.getElementsByClassName("priceMapRootItem"+this.priceMap.rootItemId)[0]
        prevElement.classList.remove("fa-chevron-left");
        prevElement.classList.add("fa-chevron-right");
      }

      // set arrow of current element
      var curElement = document.getElementsByClassName("priceMapRootItem"+id)[0]
      curElement.classList.remove("fa-chevron-right");
      curElement.classList.add("fa-chevron-left");

      // remove other list bg color
      let catItemList = document.getElementsByClassName('pricemap-root-item-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }

      //set selected item bg color
      let x = document.getElementById("priceMapRootItem"+id)
      x.style.background = "#FDEAD5"

      this.priceMap.rootItemId = id
    },
    choosePriceMapCategory (category) {
      if (this.priceMap.selectedCategory) {
        // reset arrow of previous element
        var prevElement = document.getElementsByClassName("priceCategory"+this.priceMap.selectedCategory.id)[0];
        prevElement.classList.remove("fa-chevron-left");
        prevElement.classList.add("fa-chevron-right");
      }

      // set arrow of current element
      var curElement = document.getElementsByClassName("priceCategory"+category.id)[0];
      curElement.classList.remove("fa-chevron-right");
      curElement.classList.add("fa-chevron-left");

      // remove other list bg color
      let catItemList = document.getElementsByClassName('price-category-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }

      //set selected item bg color
      let x = document.getElementById('priceCatChoosenId'+category.id)
      x.style.background = "#FDEAD5"

      this.priceMap.selectedCategory = category
      // this.selectedEditSelection = ''

    },
    choosePriceMapSelection (selection) {
      let curElement = document.getElementsByClassName('priceSelection'+selection.id)[0]
      let curPrices = document.getElementById("priceMapPrices"+selection.id)
      if(curElement.classList.contains("fa-chevron-down")) {
        curElement.classList.remove("fa-chevron-down")
        curElement.classList.add("fa-chevron-up")
        curPrices.style.display = 'block'
      } else {
        curElement.classList.remove("fa-chevron-up")
        curElement.classList.add("fa-chevron-down")
        curPrices.style.display = 'none'
      }

      // remove other list bg color
      // let catItemList = document.getElementsByClassName('price-map-selection-list')
      // for (var i = 0; i < catItemList.length; i++) {
      //   catItemList[i].style.backgroundColor = '#ffffff'
      // }

      //set selected item bg color
      // let x = document.getElementById('priceMapSelectionChoosenId'+selection.id)[0]
      // x.style.background = "#FDEAD5"

    },
    priceMapHookPrice (itemId, price) {
      let url = process.env.VUE_APP_API_URL+'/api/customToppingPlan/priceMapping'
      let dataToSubmit = {
        "restaurantId": this.restaurantId,
        "customToppingId": this.toppingId,
        "categoryId": this.priceMap.selectedCategory.id,
        "itemId": itemId,
        "rootItemId": this.priceMap.rootItemId,
        "amount": price,
        "hasPriceMapping": "Yes"
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Price mapping successful", "Success");
      })
    },
    isChecked (id, price) {
      for (let i = 0; i < this.priceMap.prices.length; i++) {
        if (this.priceMap.prices[i].categoryId == this.priceMap.selectedCategory.id) {
          for (let j = 0; j < this.priceMap.prices[i].price.length; j++) {
            if (this.priceMap.prices[i].price[j].itemId == id && this.priceMap.prices[i].price[j].amount == price) {
              return true
            }
          }
        }
      }
      return false
    },
    backToCustomToppingList () {
      this.toppingEditClicked = false
      this.priceMap.show = false
      this.selectedCategory = ''
      this.toppingMin = ''
      this.toppingMax = ''
      this.toppingFree = ''
      this.toppingRoot = false
      this.$root.$emit('enableBackBtn', false)
    }
  }
}
</script>

<style scoped>
.headerBtnStyle {
  background: #FFF5FF !important;
  color: #FE7611;
  margin-top: 7%;
}
.toppingAddBtn{
  padding: 6px 30px;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
  color: #fff;
  border: unset;
}
.priceMapBtn{
  padding: 5px;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
  color: #fff;
  border: unset;
  border-radius: 10px;
  font-size: .9rem;
  line-height: 1rem;
}
.cancelBtn{
  padding: 6px 20px;
  background: #fff;
  color: #000;
  border: 1px solid;
}
.table > thead > tr > th {
  color: #E317E3;
  background: #FFF5FF;
}
.table > thead > tr > th:last-child, .table > tbody > tr > th:last-child, .table > tfoot > tr > th:last-child, .table > thead > tr > td:last-child, .table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
  width: auto;
}
.categoryCol{border: 1px solid rgba(0, 0, 0, 0.125);}
.categoryCol div:first-child, .priceCol div:first-child{
  background-color: #FDEAD5;
  display: flow-root;
}
.categoryCol div:nth-child(2){
  margin-top: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.price-list{background-color: #fff; display: block}
.price-list li{border:  1px solid;}
.price-list li:first-child{cursor: pointer; width: 70%; display: inline-block; margin-left: 1rem;}
.price-list li:nth-child(2){cursor: pointer; width: 15%; display: inline-block; float: right; margin-right: 1rem}
.price-item{margin-bottom: 1px;}
.price-list .deleteBtn{
  width: 20px;
  margin-top: 9px !important;
  height: 28px;
  margin-left: 15px;
}
.deleteBtn:after{background-color: #000;}
.deleteBtn:checked:after{background-color: #000;}
.overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}
.list-group-flush > .list-group-item:last-child{border-bottom-width: 1px;}
</style>

<template>
  <div class="wrapper">
    <div class="main-panel">
      <dashboard-content @click="toggleSidebar"></dashboard-content>
    </div>
  </div>
</template>
<script>
  import DashboardContent from './Content.vue'
  export default {
    components: {
      DashboardContent
    },
    data () {
      return {
        ownerMgmt: false,
        isMenuManagement: false
      }
    },
    methods: {
      ownerManagement () {
        this.ownerMgmt = !this.ownerMgmt
      },
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    watch: {
      $route (to, form) {
        if (to.name == 'MenuManagement' && to.path == '/admin/menu-management') {
          this.isMenuManagement = true
        }
        else {
          this.isMenuManagement = false
        }
      }
    }
  }

</script>
<style>
.notClickable > a {
  pointer-events: none;
  cursor: default;
}
</style>
<style scoped>
.main-panel{background-color: #fff; width: 100%;}
</style>

<template>
  <div class="content">
    <div class="container px-0">
      <div class="row mx-0">
        <div class="col-md-12 px-0">
          <top-navbar
            :title="restaurantName"
            :page="pageName"
            search="false"
            :isGoBackEnabled="(isCustomMealDealSetItem || isFixedMealDeal || showHookCustomToppings) ? true : false"
            :goBackTitle="goBackTitle"
            @goBack="goBack" />
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-md-2 col-sm-12 px-0 menuSidebar">
          <ul>
            <li @click="showMenuDiv">Menu
              <i :class="(!isToppingsClicked && !isModifierClicked && !isPrintOptionClicked && !isWebToppingsClicked && !isCustomToppingsClicked) ? 'fa-chevron-left active' : 'fa-chevron-right'" class="fa float-right pt-1"></i>
            </li>
            <li @click="showToppingsDiv">Toppings
              <i :class="isToppingsClicked ? 'fa-chevron-left active' : 'fa-chevron-right'" class="fa float-right pt-1"></i>
            </li>
            <li @click="showWebToppingsDiv">Web Toppings
              <i :class="isWebToppingsClicked ? 'fa-chevron-left active' : 'fa-chevron-right'" class="fa float-right pt-1"></i>
            </li>
            <li @click="showCustomToppingsDiv">Custom Toppings
              <i :class="isCustomToppingsClicked ? 'fa-chevron-left active' : 'fa-chevron-right'" class="fa float-right pt-1"></i>
            </li>
            <li @click="showModifierDiv">Modifier
              <i :class="isModifierClicked ? 'fa-chevron-left active' : 'fa-chevron-right'" class="fa float-right pt-1"></i>
            </li>
            <li @click="showPrintOptionDiv">Print Option Title
              <i :class="isPrintOptionClicked ? 'fa-chevron-left active' : 'fa-chevron-right'" class="fa float-right pt-1"></i>
            </li>
          </ul>
        </div>
        <div class="col-md-10 col-sm-12">
          <div class="row" style="margin-bottom: 10% !important;"
            v-if="!isToppingsClicked && !isModifierClicked && !isPrintOptionClicked && !isFixedMealDeal && !isCustomMealDealSetItem && !isCustomMealDealSet && !isWebToppingsClicked&& !isCustomToppingsClicked && !showHookWebToppings && !showHookCustomToppings">
            <div class="col-md-4">
              <div class="card-header" style="background: #FDEAD5; color: #000; padding: 10px;">
                <span style="font-size: 1.3rem; font-weight: bold">Category</span>
                <span @click="categoryClick" v-bind:class="['dropdown', {'show': categoryOption}]" style="float: right; cursor: pointer; padding-top: 5px">
                  <i class="fa fa-ellipsis-v"></i>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#" @click="addNewCategory">Add Category</a>
                    <a class="dropdown-item" href="#">Delete All Category</a>
                  </div>
                </span>
              </div>
              <ul class="list-group list-group-flush" style="height: 50%;">
                  <li v-for="(item, index) in categoryDataList" :key="index" @click="() => chooseCategory(item.id)" class="list-group-item" style="cursor: pointer;" :id="'categoryChoosenId'+item.id">
                    <span>
                      <span> {{item.label}} </span>
                      <i class="fa fa-chevron-down" style="float: right; color: black; display: block;" :id="'categoryHide'+index" @click="() => categoryListShow(index)"></i>
                      <i class="fa fa-chevron-up" style="float: right; color: black; display: none;" :id="'categoryShow'+index" @click="() => categoryListShow(index)"></i>

                      <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'categoryOptions'+index">
                        <span style="padding-right: 5px;"> <button type="button" class="btn-sm editBtn"
                        @click="() => editCategory(item.id, item.label, item.description, 0, 0, 0, item.mealAssignFor, item.printOptionId, item.mealAssignFor, item.status, item.daysOff)"><i class="fa fa-edit"></i> Edit</button> </span>
                        <span> <button type="button" class="btn-sm deleteBtn" @click="() => deleteCategory(item.id)"><i class="fa fa-trash" ></i> Delete</button> </span>
                      </div>
                    </span>
                  </li>
              </ul>
            </div>
            <div class="col-md-4">
              <div class="card-header" style="background-color: #FDEAD5; color: #000; padding: 10px;">
                <span style="font-size: 1.3rem; font-weight: bold">Items</span>
                <span @click="itemClick" v-bind:class="['dropdown', {'show': itemOption}]" style="float: right; cursor: pointer; padding-top: 5px">
                  <i class="fa fa-ellipsis-v"></i>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <!-- <a class="dropdown-item" @click="addMealDeal()">Add Meal Deal</a> -->
                    <a class="dropdown-item" @click="addNewItem">Add Item</a>
                    <a class="dropdown-item" @click="addNewFixedMealDeal">Add Fixed Meal Deal</a>
                    <a class="dropdown-item" @click="addNewCustomMealDeal">Add Custom Meal Deal</a>
                    <!-- <a class="dropdown-item" href="#">View Section List</a> -->
                    <a class="dropdown-item" @click="addNewSelection">Add Selection With Price</a>
                    <a class="dropdown-item" @click="() => hookWebToppings('Item')">Hook Toppings</a>
                    <a class="dropdown-item" @click="addDynamicCustomDeal">Add dynamic custom deal</a>
                  </div>
                </span>
              </div>
              <ul class="list-group list-group-flush">
                <li v-for="(item, index) in selectedItemsAccordingToCategory" :key="index" :id="'itemChoosenId'+item.id" class="list-group-item item-list"  style="cursor: pointer;padding: unset">
                  <div class="row mx-0">
                    <div class="col-md-10 col-10 px-4" style="padding: 10px" @click="() => ChooseItem(item.id, item.dataType, item.mealDealType, item.printOptionId)">
                      <span> {{item.label}} </span>
                      <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'itemOptions'+index">
                        <span style="padding-right: 5px;"> <button type="button" class="btn-sm editBtn"
                          @click="() => editItems(item.id, item.label, item.description, item.basePrice, item.priceDifference, item.inPrice, item.outPrice, item.categoryId, 0, item.mealDealType, item.dataType, item.terminalOption, item.printOptionId, item.mealAssignFor, item.status, item.daysOff, item.specialItem, item.shortId, item.webToppingItems)">
                          <i class="fa fa-edit"></i> Edit</button>
                        </span>
                        <span> <button type="button" class="btn-sm deleteBtn" @click="() => deleteItem(item.id, item.dataType)"><i class="fa fa-trash"></i> Delete</button> </span>
                      </div>
                    </div>
                    <div class="col-md-2 col-2  pt-2 pr-2">
                      <i class="fa fa-chevron-down w-100 h-100" style="float: right; color: black; display: block;" :id="'itemHide'+index" @click="() => itemListShow(index)"></i>
                      <i class="fa fa-chevron-up w-100 h-100" style="float: right; color: black; display: none;" :id="'itemShow'+index" @click="() => itemListShow(index)"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <div class="card-header" style="background-color: #FDEAD5; color: #000; padding: 10px;">
                <span style="font-size: 1.3rem; font-weight: bold">Selections</span>
                <span @click="selectionClick" v-bind:class="['dropdown', {'show': selectionOption}]" style="float: right; cursor: pointer; padding-top: 5px">
                  <i class="fa fa-ellipsis-v"></i>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" @click="() => hookWebToppings('Selection')">Hook Toppings</a>
                  </div>
                </span>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item selection-list" style="cursor: pointer;" v-for="(item, index) in selectedSelectionAccordingToItem" :key="index" :id="'selectionChoosenId'+item.id">
                  <div @click="() => ChooseSelection(item)">
                    {{item.label}}
                    <i class="fa fa-chevron-down" style="float: right; color: black; display: block;" :id="'selectionHide'+index"  @click="() => selectionListShow(index)"></i>
                    <i class="fa fa-chevron-up" style="float: right; color: black; display: none;" :id="'selectionShow'+index"  @click="() => selectionListShow(index)"></i>

                    <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'selectionOption'+index">
                      <span style="padding-right: 5px;"> <button type="button" class="btn-sm editBtn"
                      @click="() => editSelections(item.id, item.label, item.description, item.basePrice, item.priceDifference, item.inPrice, item.outPrice, item.categoryId, item.itemId, item.terminalOption, item.printOptionId, item.mealAssignFor, item.status, item.daysOff, item.specialItem, item.shortId)">
                      <i class="fa fa-edit"></i> Edit</button> </span>
                      <span> <button type="button" class="btn-sm deleteBtn" @click="() => deleteSelection(item.id)"><i class="fa fa-trash"></i> Delete</button> </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>


          <MenuToppings ref="MenuToppings" v-if="isToppingsClicked" @toppingsEvent="addNewToppings" @editToppingsEvent="editToppings" />

          <WebToppings v-if="isWebToppingsClicked" />

          <HookWebToppings
            v-if="showHookWebToppings"
            v-bind:item="selectedItemForHook"
            v-bind:selection="selectedSelectionForHook"
            v-bind:webHookFor="webHookFor"
            @backFromWebToppings="backFromWebToppings"
          />

          <CustomToppings v-if="isCustomToppingsClicked" />

          <HookCustomToppings v-if="showHookCustomToppings" v-bind:toppingId="selectedItemForHook.id" />

          <MenuModifier v-if="isModifierClicked" />

          <MenuPrintOption v-if="isPrintOptionClicked" />

          <FixedMealDeal v-if="isFixedMealDeal"
          ref="FixedMealDeal"
          v-bind:categoryId="selectedCategoryId"
          v-bind:itemId="selectedItemId"
          v-bind:itemLabel="itemLabel"
          v-bind:itemDataList="itemDataList"
          @goBack="goBack"
          />

          <CustomMealDealSetItem v-if="isCustomMealDealSetItem"
          v-bind:itemDataList="itemDataList"
          v-bind:itemId="selectedItemId"
          v-bind:categoryId="selectedCategoryId"
          @checkEditClickForSetItem="checkEditClickForSetItem"
          @goBack="goBack"
          />

          <CustomMealDealSet v-if="isCustomMealDealSet"
          v-bind:categoryId="selectedCategoryId"
          v-bind:itemId="selectedItemId"
          v-bind:itemLabel="itemLabel"
          v-bind:itemDataList="itemDataList"
          v-bind:idOfSetItem="idOfSetItem"
          v-bind:labelOfSetItem="labelOfSetItem"
          @showSetList="showSetList"
          />
        </div>
      </div>

      <MenuManagementModal
        ref="MenuManagementModal"
        v-bind:modalTitle="modalTitle"
        v-bind:isCategoryModal="isCategoryModal"
        v-bind:isItemModal="isItemModal"
        v-bind:isSelectionModal="isSelectionModal"
        v-bind:isToppingsModal="isToppingsModal"
        v-bind:categoryDataList="categoryDataList"
        v-bind:itemDataList="itemDataList"
        v-bind:printOptionDataList="printOptionDataList"
        v-bind:selectedItemPrintOptionId="selectedItemPrintOptionId"
        @toppingsSubmitted="toppingsSubmitted"
        @categoryAndItem="categoryList"
      />

    </div>
  </div>
</template>

<script>
import MenuManagementModal from './MenuManagementModal.vue'
import MenuToppings from './MenuToppings.vue'
import WebToppings from './WebToppings.vue'
import HookWebToppings from './HookWebToppings.vue'
import CustomToppings from './CustomToppings.vue'
import HookCustomToppings from './HookCustomToppings.vue'
import MenuModifier from './MenuModifier.vue'
import MenuPrintOption from './MenuPrintOption.vue'
import FixedMealDeal from './MealDeal/FixedMealDeal.vue'
import CustomMealDealSetItem from './MealDeal/CustomMealDealSet.vue'
import CustomMealDealSet from './MealDeal/CustomMealDeal.vue'
import logout from './../../mixins/logoutMixins.js'
import TopNavbar from '../../layout/TopNavbar.vue'

import axios from 'axios'

export default {
  components: {
    MenuManagementModal,
    MenuToppings,
    WebToppings,
    HookWebToppings,
    CustomToppings,
    HookCustomToppings,
    MenuModifier,
    MenuPrintOption,
    FixedMealDeal,
    CustomMealDealSetItem,
    CustomMealDealSet,
    TopNavbar
  },
  data () {
    return {
      categoryOption: false,
      itemOption: false,
      selectionOption: false,
      categoryCollapse: false,
      itemCollapse: false,
      selectionCollapse: false,
      showModal: false,
      modalTitle: '',
      isCategoryModal: false,
      isItemModal: false,
      isSelectionModal: false,
      isToppingsModal: false,
      isToppingsClicked: false,
      isWebToppingsClicked: false,
      isCustomToppingsClicked: false,
      showHookCustomToppings: false,
      showHookWebToppings: false,
      isModifierClicked: false,
      isPrintOptionClicked: false,
      categoryDataList: [],
      itemDataList: [],
      selectionDataList: [],
      restaurantId:  parseInt(localStorage.getItem('restaurantId')),
      selectedItemsAccordingToCategory: [],
      selectedSelectionAccordingToItem: [],
      isFixedMealDeal: false,
      isCustomMealDealSetItem: false,
      isCustomMealDealSet: false,
      addFixedMealDeal: false,
      addCustomMealDeal: false,
      addGeneralMealDeal: false,
      selectedItemId: 0,
      selectedItemForHook: '',
      selectedSelectionForHook: '',
      webHookFor: '',
      selectedItemPrintOptionId: 0,
      selectedCategoryId: 0,
      selectedCategoryName: '',
      selectedItemName: '',
      selectedSelectionName: '',
      itemLabel: '',
      printOptionDataList: [],
      idOfSetItem: 0,
      labelOfSetItem: '',
      jwtToken: this.$cookies.get('token') || null,
      restaurantName: localStorage.getItem('restaurantName'),
      pageName: '',
      goBackTitle: 'mealdeal'
    }
  },
  created () {
    this.categoryList()
    this.printOptionList()

    localStorage.removeItem('selectedCategoryId')
    localStorage.removeItem('selectedItemId')
    localStorage.removeItem('itemLabel')
    localStorage.removeItem('editableToppingsData')
  },
  mixins: [logout],
  methods: {
    chooseCategory (val) {
      let categoryBackground = 'categoryChoosenId'+val

      let x = document.getElementById(categoryBackground)
      x.style.background = "#FDEAD5"
      this.selectedItemsAccordingToCategory = []
      this.selectedSelectionAccordingToItem = []

      localStorage.setItem('selectedCategoryId', val)
      this.selectedCategoryId = val

      for (let j = 0; j < this.itemDataList.length; j++) {
        if (this.itemDataList[j].categoryId == val) {
          let itemObj = {
            id: this.itemDataList[j].id,
            label: this.itemDataList[j].label,
            basePrice: this.itemDataList[j].basePrice,
            description: this.itemDataList[j].description,
            priceDifference: this.itemDataList[j].priceDifference,
            inPrice: this.itemDataList[j].inPrice,
            outPrice: this.itemDataList[j].outPrice,
            categoryId: this.itemDataList[j].categoryId,
            dataType: this.itemDataList[j].dataType,
            mealDealType: this.itemDataList[j].mealDealType,
            mealAssignFor: this.itemDataList[j].mealAssignFor,
            printOptionId: this.itemDataList[j].printOptionId,
            status: this.itemDataList[j].status,
            terminalOption: this.itemDataList[j].terminalOption,
            specialItem: this.itemDataList[j].specialItem === "Yes" ? true : false,
            shortId: this.itemDataList[j].shortId,
            webToppingId: this.itemDataList[j].webToppingId,
            webToppingItems: this.itemDataList[j].webToppingItems,
            daysOff: this.itemDataList[j].daysOff
          }
          this.selectedItemsAccordingToCategory.push(itemObj)
        }
      }
      for (let i = 0; i < this.categoryDataList.length; i++) {
        if (this.categoryDataList[i].id != val) {
          let categoryBackgroundRestOf = 'categoryChoosenId'+this.categoryDataList[i].id
          document.getElementById(categoryBackgroundRestOf).style.background = "#FFF"
        } else {
          this.selectedCategoryName = this.categoryDataList[i].label
        }
      }
    },
    ChooseItem (val, dataType, mealDealType, printOptionId) {
      this.selectedItemPrintOptionId = printOptionId
      // remove other list bg color
      let catItemList = document.getElementsByClassName('item-list')
      for (var i = 0; i < catItemList.length; i++) {
        catItemList[i].style.backgroundColor = '#ffffff'
      }
      //set selected item bg color
      let categoryBackground = 'itemChoosenId'+val
      let x = document.getElementById(categoryBackground)
      x.style.background = "#FDEAD5"

      localStorage.setItem('selectedItemId', val)
      this.selectedItemId = val

      this.selectedSelectionAccordingToItem = []

      if (dataType == 'Item') {
        for (let j = 0; j < this.selectionDataList.length; j++) {
          if (this.selectionDataList[j].itemId == val) {
            let itemObj = {
              id: this.selectionDataList[j].id,
              itemId: this.selectionDataList[j].itemId,
              label: this.selectionDataList[j].label,
              basePrice: this.selectionDataList[j].basePrice,
              description: this.selectionDataList[j].description,
              priceDifference: this.selectionDataList[j].priceDifference,
              inPrice: this.selectionDataList[j].inPrice,
              outPrice: this.selectionDataList[j].outPrice,
              categoryId: this.selectionDataList[j].categoryId,
              dataType: this.selectionDataList[j].dataType,
              mealAssignFor: this.selectionDataList[j].mealAssignFor,
              printOptionId: this.selectionDataList[j].printOptionId,
              status: this.selectionDataList[j].status,
              terminalOption: this.selectionDataList[j].terminalOption,
              specialItem: this.selectionDataList[j].specialItem === "Yes" ? true : false,
              shortId: this.selectionDataList[j].shortId,
              webToppingId: this.selectionDataList[j].webToppingId,
              webToppingItems: this.selectionDataList[j].webToppingItems,
              daysOff: this.selectionDataList[j].daysOff
            }
            this.selectedSelectionAccordingToItem.push(itemObj)
          }
        }
      }
      else if (dataType == 'CustomTopping') {
        this.showHookCustomToppings = true
        this.isFixedMealDeal = false
        this.isCustomMealDealSetItem = false
        this.isCustomMealDealSet = false
        this.isToppingsClicked = false
        this.isModifierClicked = false
        this.isPrintOptionClicked = false
      }
      else if (dataType == 'MealDeal' && mealDealType == 'Fixed') {
        this.isFixedMealDeal = true
        this.isCustomMealDealSetItem = false
        this.isCustomMealDealSet = false
        this.isToppingsClicked = false
        this.isModifierClicked = false
        this.isPrintOptionClicked = false

        // this.$refs.FixedMealDeal.assignedFixedItemSet()
      }
      else if (dataType == 'MealDeal' && mealDealType == 'Custom') {
        this.isFixedMealDeal = false
        this.isToppingsClicked = false
        this.isModifierClicked = false
        this.isPrintOptionClicked = false
        this.isCustomMealDealSetItem = true
        this.isCustomMealDealSet = false
      }

      for (let i = 0; i < this.selectedItemsAccordingToCategory.length; i++) {
        if (this.selectedItemsAccordingToCategory[i].id == val) {
          this.selectedItemForHook = this.selectedItemsAccordingToCategory[i]
          localStorage.setItem('itemLabel', this.selectedItemsAccordingToCategory[i].label)
          this.itemLabel = this.selectedItemsAccordingToCategory[i].label
          this.selectedItemName = this.selectedItemsAccordingToCategory[i].label
        }
      }
    },
    ChooseSelection (selection) {
      this.selectedSelectionForHook = selection
      // remove other list bg color
      let selectionList = document.getElementsByClassName('selection-list')
      for (var i = 0; i < selectionList.length; i++) {
        selectionList[i].style.backgroundColor = '#ffffff'
      }
      //set selected selection bg color
      let selectionBackground = 'selectionChoosenId'+selection.id
      let x = document.getElementById(selectionBackground)
      x.style.background = "#FDEAD5"

      this.selectedSelectionName = selection.label
    },
    categoryList () {
        let url = process.env.VUE_APP_API_URL+`/api/menu?restaurantId=${this.restaurantId}`

        // request header
        let requestHeader = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.jwtToken}`
        }

        axios
        .get(url,
        {
            headers: requestHeader
        })
        .then((data)=> {
            if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                }
            }

            this.itemDataList = []
            this.selectionDataList = []

            this.categoryDataList = data.data

            this.itemList(data.data)
            this.selectionList(data.data)
        })
    },
    printOptionList () {
        let url = process.env.VUE_APP_API_URL+`/api/printOption?restaurantId=${this.restaurantId}`

        // request header
        let requestHeader = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.jwtToken}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": "*",
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }

        axios
        .get(url,
        {
            headers: requestHeader
        })
        .then((data)=> {
            if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                }
            }
            this.printOptionDataList = data.data
        })
    },
    itemList (val) {
        for (let i = 0; i < val.length; i++) {
            if (typeof val[i].children !== 'undefined' && val[i].children.length > 0) {
                let children = val[i].children
                for (let j = 0; j < children.length; j++) {
                    let child = {
                      id: children[j].id,
                      categoryId: val[i].id,
                      label: children[j].label,
                      description: children[j].description,
                      priceDifference: children[j].priceDifference,
                      inPrice:children[j].inPrice,
                      outPrice: children[j].outPrice,
                      basePrice: children[j].basePrice,
                      dataType: children[j].dataType,
                      mealDealType: children[j].mealDealType,
                      mealAssignFor: children[j].mealAssignFor,
                      printOptionId: children[j].printOptionId,
                      status: children[j].status,
                      terminalOption: children[j].terminalOption,
                      specialItem: children[j].specialItem,
                      shortId: children[j].shortId,
                      webToppingId: children[j].webToppingId,
                      webToppingItems: children[j].webToppingItems,
                      daysOff: children[j].daysOff
                    }
                    this.itemDataList.push (child)
                }
            }
        }
        this.selectedItemsAccordingToCategory = []

        if (localStorage.getItem('selectedCategoryId')) {
            setTimeout(()=> {
                for (let j = 0; j < this.itemDataList.length; j++) {
                    if (this.itemDataList[j].categoryId == localStorage.getItem('selectedCategoryId')) {
                        let itemObj = {
                            id: this.itemDataList[j].id,
                            label: this.itemDataList[j].label,
                            basePrice: this.itemDataList[j].basePrice,
                            description: this.itemDataList[j].description,
                            categoryId: this.itemDataList[j].categoryId,
                            dataType: this.itemDataList[j].dataType,
                            mealDealType: this.itemDataList[j].mealDealType,
                            mealAssignFor: this.itemDataList[j].mealAssignFor,
                            printOptionId: this.itemDataList[j].printOptionId,
                            status: this.itemDataList[j].status,
                            terminalOption: this.itemDataList[j].terminalOption,
                            priceDifference: this.itemDataList[j].priceDifference,
                            inPrice: this.itemDataList[j].inPrice,
                            outPrice: this.itemDataList[j].outPrice,
                            specialItem: this.itemDataList[j].specialItem === "Yes" ? true : false,
                            shortId: this.itemDataList[j].shortId,
                            webToppingId: this.itemDataList[j].webToppingId,
                            webToppingItems: this.itemDataList[j].webToppingItems,
                            daysOff: this.itemDataList[j].daysOff
                        }
                        this.selectedItemsAccordingToCategory.push(itemObj)
                    }
                }
            }, 1000)
        }
    },
    selectionList (val) {
        for (let i = 0; i < val.length; i++) {
            if (typeof val[i].children !== 'undefined' && val[i].children.length > 0) {
                let children = val[i].children
                for (let j = 0; j < children.length; j++) {
                    if (typeof children[j].children !== 'undefined') {
                        let child = children[j].children
                        for (let x = 0; x < child.length; x++) {
                            let selections = {
                                id: child[x].id,
                                categoryId: val[i].id,
                                itemId: children[j].id,
                                label: child[x].label,
                                description: child[x].description,
                                basePrice: child[x].basePrice,
                                mealAssignFor: child[x].mealAssignFor,
                                printOptionId: child[x].printOptionId,
                                status: child[x].status,
                                terminalOption: child[x].terminalOption,
                                priceDifference: child[x].priceDifference,
                                inPrice:child[x].inPrice,
                                outPrice: child[x].outPrice,
                                specialItem: child[x].specialItem,
                                shortId: child[x].shortId,
                                webToppingId: child[x].webToppingId,
                                webToppingItems: child[x].webToppingItems,
                                daysOff: child[x].daysOff
                            }
                            this.selectionDataList.push (selections)
                        }
                    }
                }
            }
        }
        this.selectedSelectionAccordingToItem = []

        setTimeout(()=> {
          if (localStorage.getItem('selectedCategoryId') && localStorage.getItem('selectedItemId')) {

            for (let j = 0; j < this.selectionDataList.length; j++) {
              if (this.selectionDataList[j].itemId == this.selectedItemId) {
                let itemObj = {
                  id: this.selectionDataList[j].id,
                  id: this.selectionDataList[j].itemId,
                  label: this.selectionDataList[j].label,
                  basePrice: this.selectionDataList[j].basePrice,
                  description: this.selectionDataList[j].description,
                  categoryId: this.selectionDataList[j].categoryId,
                  dataType: this.selectionDataList[j].dataType,
                  mealAssignFor: this.selectionDataList[j].mealAssignFor,
                  printOptionId: this.selectionDataList[j].printOptionId,
                  status: this.selectionDataList[j].status,
                  terminalOption: this.selectionDataList[j].terminalOption,
                  priceDifference: this.selectionDataList[j].priceDifference,
                  inPrice: this.selectionDataList[j].inPrice,
                  outPrice: this.selectionDataList[j].outPrice,
                  specialItem: this.itemDataList[j].specialItem === "Yes" ? true : false,
                  shortId: this.selectionDataList[j].shortId,
                  daysOff: this.selectionDataList[j].daysOff
                }

                this.selectedSelectionAccordingToItem.push(itemObj)
              }
            }
          }
        }, 1000)
    },
    categoryClick () {
        this.categoryOption = !this.categoryOption
    },
    itemClick () {
        this.itemOption = !this.itemOption
    },
    selectionClick () {
        this.selectionOption = !this.selectionOption
    },
    categoryListShow(val) {
        let idShow = 'categoryShow'+val
        let idHide = 'categoryHide'+val
        let idOption = 'categoryOptions'+val

        if (this.categoryCollapse) {
            let x = document.getElementById(idHide)
            let y = document.getElementById(idShow)
            let z = document.getElementById(idOption)
            x.style.display = "block"
            y.style.display = "none"
            z.style.display = "none"
            this.categoryCollapse = !this.categoryCollapse
        }
        else {
            let x = document.getElementById(idHide)
            let y = document.getElementById(idShow)
            let z = document.getElementById(idOption)
            x.style.display = "none"
            y.style.display = "block"
            z.style.display = "block"
            this.categoryCollapse = !this.categoryCollapse
        }
    },
    itemListShow (val) {
        let idShow = 'itemShow'+val
        let idHide = 'itemHide'+val
        let idOption = 'itemOptions'+val

        if (this.itemCollapse) {
            let x = document.getElementById(idHide)
            let y = document.getElementById(idShow)
            let z = document.getElementById(idOption)
            x.style.display = "block"
            y.style.display = "none"
            z.style.display = "none"
            this.itemCollapse = !this.itemCollapse
        }
        else {
            let x = document.getElementById(idHide)
            let y = document.getElementById(idShow)
            let z = document.getElementById(idOption)
            x.style.display = "none"
            y.style.display = "block"
            z.style.display = "block"
            this.itemCollapse = !this.itemCollapse
        }
    },
    selectionListShow (val) {
        let idShow = 'selectionShow'+val
        let idHide = 'selectionHide'+val
        let idOption = 'selectionOption'+val

        if (this.selectionCollapse) {
            let x = document.getElementById(idHide)
            let y = document.getElementById(idShow)
            let z = document.getElementById(idOption)
            x.style.display = "block"
            y.style.display = "none"
            z.style.display = "none"
            this.selectionCollapse = !this.selectionCollapse
        }
        else {
            let x = document.getElementById(idHide)
            let y = document.getElementById(idShow)
            let z = document.getElementById(idOption)
            x.style.display = "none"
            y.style.display = "block"
            z.style.display = "block"
            this.selectionCollapse = !this.selectionCollapse
        }
    },
    addNewCategory () {
        this.modalTitle = 'Add New Category'
        this.isCategoryModal = true
        this.isItemModal = false
        this.isSelectionModal = false
        this.isToppingsModal = false

        this.addGeneralMealDeal = false
        this.addFixedMealDeal = false
        this.addCustomMealDeal = false
        // this.bus.$emit('categoryModal')

        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, false, true)
    },
    editCategory (val, title, description, basePrice, categoryId, itemId, terminal, print, assign, status, daysOff) {
        this.modalTitle = 'Edit Category'
        this.isCategoryModal = true
        this.isItemModal = false
        this.isSelectionModal = false
        this.isToppingsModal = false

        this.addGeneralMealDeal = false
        this.addFixedMealDeal = false
        this.addCustomMealDeal = false
        // this.bus.$emit('categoryModal')
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
        // this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, 0, 0, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
        this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, 0, 0, 0, 0, 0, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status, daysOff)
    },
    addNewItem () {
      if (localStorage.getItem('selectedCategoryId')) {
        this.modalTitle = 'Add New Item'
        this.isCategoryModal = false
        this.isItemModal = true
        this.isSelectionModal = false
        this.isToppingsModal = false
        this.addGeneralMealDeal = true
        this.addFixedMealDeal = false
        this.addCustomMealDeal = false
        // this.bus.$emit('categoryModal')
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, false, true)
      }
      else {
        this.$toastr.w("Please select a category first!", "Warning");
      }

    },
    addNewFixedMealDeal () {
        this.modalTitle = 'Add New Item'
        this.isCategoryModal = false
        this.isItemModal = true
        this.isSelectionModal = false
        this.isToppingsModal = false
        this.addGeneralMealDeal = false
        this.addFixedMealDeal = true
        this.addCustomMealDeal = false
        // this.bus.$emit('categoryModal')
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
    },
    addNewCustomMealDeal () {
        this.modalTitle = 'Add New Item'
        this.isCategoryModal = false
        this.isItemModal = true
        this.isSelectionModal = false
        this.isToppingsModal = false
        this.addGeneralMealDeal = false
        this.addFixedMealDeal = false
        this.addCustomMealDeal = true
        // this.bus.$emit('categoryModal')
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, false, true)
    },
    editItems (val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, mealDealType, dataType, terminal, print, assign, status, daysOff, specialItem, shortId, webToppingItems) {
        this.modalTitle = 'Edit Item'
        this.isCategoryModal = false
        this.isItemModal = true
        this.isSelectionModal = false
        this.isToppingsModal = false
        // this.bus.$emit('categoryModal')
        if (mealDealType == 'Custom') {
          this.addCustomMealDeal = true
          this.addGeneralMealDeal = false
        }else if (mealDealType == 'Fixed') {
          this.addFixedMealDeal = true
          this.addGeneralMealDeal = false
        }else{
          this.addGeneralMealDeal = true
        }
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, dataType==='CustomTopping'?true:false)
        // this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
        this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status, daysOff, specialItem, shortId, webToppingItems)
    },
    addNewSelection () {
        if (localStorage.getItem('selectedCategoryId') && localStorage.getItem('selectedItemId')) {
            this.modalTitle = 'Add New Selection'
            this.isCategoryModal = false
            this.isItemModal = false
            this.isSelectionModal = true
            this.isToppingsModal = false

            this.addGeneralMealDeal = false
            this.addFixedMealDeal = false
            this.addCustomMealDeal = false
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, false, true)
        }
        else {
            this.$toastr.w("Please select a category and an item first!", "Warning");
        }

    },
    editSelections (val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, terminal, print, assign, status, daysOff, specialItem, shortId) {
        this.modalTitle = 'Edit Selection'
        this.isCategoryModal = false
        this.isItemModal = false
        this.isSelectionModal = true
        this.isToppingsModal = false

        this.addGeneralMealDeal = false
        this.addFixedMealDeal = false
        this.addCustomMealDeal = false
        // this.bus.$emit('categoryModal')
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
        // this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, categoryId, itemId, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
        this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status, daysOff, specialItem, shortId)
    },
    addNewToppings () {
        this.modalTitle = 'Add New Toppings'
        this.isCategoryModal = false
        this.isItemModal = false
        this.isSelectionModal = false
        this.isToppingsModal = true

        this.addGeneralMealDeal = false
        this.addFixedMealDeal = false
        this.addCustomMealDeal = false
        // this.bus.$emit('categoryModal')
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
    },
    editToppings () {
        this.modalTitle = 'Edit Toppings'
        this.isCategoryModal = false
        this.isItemModal = false
        this.isSelectionModal = false
        this.isToppingsModal = true

        this.$refs.MenuManagementModal.editToppingsInfo()
        this.addGeneralMealDeal = false
        this.addFixedMealDeal = false
        this.addCustomMealDeal = false
        // this.bus.$emit('categoryModal')
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
    },
    toppingsSubmitted () {
        this.$refs.MenuToppings.toppingsList()
    },
    checkEditClickForSetItem (data) {
        this.idOfSetItem = parseInt(data[0])
        this.labelOfSetItem = data[1]

        setTimeout(()=> {
            this.isToppingsClicked = false
            this.isModifierClicked = false
            this.isPrintOptionClicked = false
            this.isFixedMealDeal = false
            this.isCustomMealDealSetItem = false
            this.isCustomMealDealSet = true
        }, 500)

    },
    showSetList () {
      this.isFixedMealDeal = false
      this.isToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
      this.isCustomMealDealSetItem = true
      this.isCustomMealDealSet = false
    },
    showMenuDiv () {
      this.isToppingsClicked = false
      this.isWebToppingsClicked = false
      this.isCustomToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
      this.isFixedMealDeal = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
      this.showHookWebToppings = false
      this.showHookCustomToppings = false
      this.pageName = ''

      this.selectedItemsAccordingToCategory = []
      this.selectedSelectionAccordingToItem = []
    },
    showToppingsDiv () {
      this.isToppingsClicked = true
      this.isWebToppingsClicked = false
      this.isCustomToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
      this.isFixedMealDeal = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
      this.showHookWebToppings = false
      this.showHookCustomToppings = false
      this.pageName = 'Toppings'
    },
    showWebToppingsDiv () {
      this.isCustomToppingsClicked = false
      this.isToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
      this.isFixedMealDeal = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
      this.showHookWebToppings = false
      this.showHookCustomToppings = false
      this.isWebToppingsClicked = true
      this.pageName = 'Web Toppings'
    },
    showCustomToppingsDiv () {
      this.isWebToppingsClicked = false
      this.isCustomToppingsClicked = true
      this.showHookCustomToppings = false
      this.isToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
      this.isFixedMealDeal = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
      this.showHookWebToppings = false
      this.pageName = 'Web Toppings'
    },
    showModifierDiv () {
      this.isToppingsClicked = false
      this.isWebToppingsClicked = false
      this.isCustomToppingsClicked = false
      this.isModifierClicked = true
      this.isPrintOptionClicked = false
      this.isFixedMealDeal = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
      this.showHookWebToppings = false
      this.showHookCustomToppings = false
      this.pageName = 'Modifier'
    },
    showPrintOptionDiv () {
      this.isPrintOptionClicked = true
      this.isToppingsClicked = false
      this.isWebToppingsClicked = false
      this.isCustomToppingsClicked = false
      this.isModifierClicked = false
      this.isFixedMealDeal = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
      this.showHookWebToppings = false
      this.showHookCustomToppings = false
      this.pageName = 'Print option title'
    },
    addMealDeal() {
      this.$router.push({ name: 'DealSet' })
    },
    deleteCategory (val) {
        let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

        // request header
        let requestHeader = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.jwtToken}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": "*",
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }

        let dataToSubmit = {
            "id": val,
            "restaurantId": this.restaurantId,
            "printOptionTitle": "Starter",
            "toppingsCategoryId": 0,
            "terminalOption": "",
            "modifierId": 0,
            "displayOrder": 0,
            "description": "",
            "baseQty": 1,
            "printOptionId": 1,
            "createdAt": "3 Sep 2020 11:05:30",
            "mealAssignFor": "Kitchen",
            "children": [],
            "setId": 0,
            "setItemId": 0,
            "setTitle": "",
            "iconURL": "",
            "toppingsId": 0,
            "basePrice": "",
            "updatedAt": "30 Sep 2020 11:05:30",
            "selectionId": 0,
            "dataType": "Category",
            "label": "",
            "resourceRef": "",
            "imgURL": "",
            "itemId": 0,
            "mealDealId": "",
            "toppingsCategory": "",
            "maxQty": 0,
            "mealDealType": "",
            "refCode": 0,
            "categoryId": val,
            "status": "Active",
            "minQty": 0,
            "total": ""
        }

        if (confirm('Are you sure to delete this category?')) {
            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                    this.$toastr.s("Deleted Successfully", "Success");
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.categoryList()
            })
        }
    },
    deleteItem (val, dataType) {
      let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

      // request header
      let requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }

      let dataToSubmit = {
        "id": val,
        "restaurantId": this.restaurantId,
        "printOptionTitle": "Starter",
        "toppingsCategoryId": 0,
        "terminalOption": "",
        "modifierId": 0,
        "displayOrder": 0,
        "description": "",
        "baseQty": 1,
        "printOptionId": 1,
        "createdAt": "3 Sep 2020 11:05:30",
        "mealAssignFor": "Kitchen",
        "children": [],
        "setId": 0,
        "setItemId": 0,
        "setTitle": "",
        "iconURL": "",
        "toppingsId": 0,
        "basePrice": "",
        "updatedAt": "30 Sep 2020 11:05:30",
        "selectionId": 0,
        "dataType": dataType,
        "label": "",
        "resourceRef": "",
        "imgURL": "",
        "itemId": 0,
        "mealDealId": "",
        "toppingsCategory": "",
        "maxQty": 0,
        "mealDealType": "",
        "refCode": 0,
        "categoryId": this.selectedCategoryId,
        "status": "Active",
        "minQty": 0,
        "total": ""
      }

      if (confirm('Are you sure to delete this item?')) {
        axios
        .post(url, dataToSubmit, {headers: requestHeader})
        .then((data)=> {
          if (data.status == 200) {
            this.$toastr.s("Deleted Successfully", "Success");
          }

          if (data.status === 403) {
            let logoutResponse = this.logout()
            if (logoutResponse === 1) {
              this.$router.push({ path: '/login' })
            }
          }

          this.selectedItemsAccordingToCategory = []
          this.categoryList()
        })
      }
    },
    deleteSelection (val) {
      let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

      // request header
      let requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }

      let dataToSubmit = {
        "id": val,
        "restaurantId": this.restaurantId,
        "printOptionTitle": "Starter",
        "toppingsCategoryId": 0,
        "terminalOption": "",
        "modifierId": 0,
        "displayOrder": 0,
        "description": "",
        "baseQty": 1,
        "printOptionId": 1,
        "createdAt": "3 Sep 2020 11:05:30",
        "mealAssignFor": "Kitchen",
        "children": [],
        "setId": 0,
        "setItemId": this.selectedItemId,
        "setTitle": "",
        "iconURL": "",
        "toppingsId": 0,
        "basePrice": "",
        "updatedAt": "30 Sep 2020 11:05:30",
        "selectionId": 0,
        "dataType": "Selection",
        "label": "",
        "resourceRef": "",
        "imgURL": "",
        "itemId": this.selectedItemId,
        "mealDealId": "",
        "toppingsCategory": "",
        "maxQty": 0,
        "mealDealType": "",
        "refCode": 0,
        "categoryId": this.selectedCategoryId,
        "status": "Active",
        "minQty": 0,
        "total": ""
      }

      if (confirm('Are you sure to delete this selection?')) {
        axios
        .post(url, dataToSubmit,
        {
            headers: requestHeader
        })
        .then((data)=> {
          if (data.status == 200) {
            this.$toastr.s("Deleted Successfully", "Success");
          }

          if (data.status === 403) {
            let logoutResponse = this.logout()
            if (logoutResponse === 1) {
              this.$router.push({ path: '/login' })
            }
          }

          this.selectedItemsAccordingToCategory = []
          this.selectedSelectionAccordingToItem = []

          this.categoryList()
        })
      }
    },
    goBack () {
      this.isFixedMealDeal = false
      this.isToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
    },
    hookWebToppings (type) {
      this.webHookFor = type
      if (type == 'Item' && !this.selectedItemForHook) {
        this.$toastr.w("Please select an item", "Warning");
        return
      } else if(type == 'Selection' && !this.selectedSelectionForHook) {
        this.$toastr.w("Please select a selection", "Warning");
        return
      }
      this.showHookWebToppings = true
      this.isWebToppingsClicked = false
      this.isCustomToppingsClicked = false
      this.showHookCustomToppings = false
      this.isToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
      this.isFixedMealDeal = false
      this.isCustomMealDealSetItem = false
      this.isCustomMealDealSet = false
      if (type == 'Item') {
        this.pageName = this.selectedCategoryName + ' | ' + this.selectedItemName + ' | ' + ' Hook'
      } else {
        this.pageName = this.selectedCategoryName + ' | ' + this.selectedItemName + ' | ' + this.selectedSelectionName + ' | ' + ' Hook'
      }
    },
    backFromWebToppings (webHookFor, item, selection) {
      this.showMenuDiv()

      setTimeout(() => {
        this.chooseCategory(item.categoryId)
      }, 500);
      setTimeout(() => {
        this.ChooseItem(item.id, item.dataType, item.mealDealType, item.printOptionId)
      }, 700);
      if (webHookFor == 'Selection') {
        setTimeout(() => {
          this.ChooseSelection (selection)
        }, 900);
      }
    },
    addDynamicCustomDeal () {
      if (localStorage.getItem('selectedCategoryId')) {
        this.modalTitle = 'Add New Item'
        this.isCategoryModal = false
        this.isItemModal = true
        this.isSelectionModal = false
        this.isToppingsModal = false
        this.addGeneralMealDeal = true
        this.addFixedMealDeal = false
        this.addCustomMealDeal = false
        this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, true)
      }
      else {
        this.$toastr.w("Please select a category first!", "Warning");
      }
    }
  },
  watch: {
    isWebToppingsClicked (val) {
      if (val) {
        this.goBackTitle = 'webTopping'
      } else {
        this.goBackTitle = 'mealDeal'
      }
    },
    showHookCustomToppings (val) {
      if (val) {
        this.goBackTitle = 'customTopping'
      } else {
        this.goBackTitle = 'mealDeal'
      }
    }
  }
}
</script>

<style scoped>
.menuSidebar{background: #FDEAD5; height: 100vh;}
.menuSidebar ul{padding-left: 0;}
.menuSidebar ul li{
  list-style-type: none;
  padding: .7rem 1rem;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.menuSidebar ul li:has(i.active) {background-color: #fff !important;}
.list-group-flush > .list-group-item{border-width: unset; border:1px solid rgba(0, 0, 0, 0.125);}
/* .content{padding: 10px 0} */
.menuMgmtBtn {
    background: #FFF5FF;
    border: 1px solid #E317E3;
    color: #E317E3;
}

.editBtn {
    background-color: #E317E3;
    border: 1px solid  #E317E3;
    color: white;
}
.deleteBtn {
    background-color: #FF646F;
    border: 1px solid #FF646F;
    color: white;
}
</style>

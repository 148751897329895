<template>
  <div class="content">
    <div class="container">
      <!--chart-->
      <div class="row">
        <div class="col-md-12 px-0">
          <top-navbar :title="restaurantName" search="false"></top-navbar>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-2">
          <sidebar-link to="/admin/restaurant/booking/history" class="dashboard-link">
            <button class="btn orderHistoryBtn">Booking History</button>
          </sidebar-link>
          <sidebar-link to="/admin/restaurant/order/history" class="dashboard-link my-3">
            <button class="btn orderHistoryBtn">Order History</button>
          </sidebar-link>
          <div class="row mb-3 mx-0" style="background-color: #fff; text-align: center; border-radius: 2px">
            <div class="col-md-6 col-sm-12 px-0">
              <label>From</label>
              <datepicker v-model="startDate" format="d/MM/yyyy" placeholder="Select Date"></datepicker>
            </div>
            <div class="col-md-6 col-sm-12 px-0">
              <label>To</label>
              <datepicker v-model="endDate" format="d/MM/yyyy" placeholder="Select Date"></datepicker>
            </div>
          </div>
          <button @click="getStatData" class="btn applyBtn">Apply Filter</button>
          <img v-if="showFilterLoading" src="/img/loading.gif" alt="GIF" width="30" height="30" class="mx-auto d-block">

        </div>
        <div class="col-md-10" style="background-color: white">
          <div class="row">
            <div class="col-md-12">
              <div class="card mt-2">
                <ColumnChart :series="orderCountByMonthsThisYear" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-2">
                <PieChart :series="orderTypeCounts" />
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="card mt-2">
                <PieChart :series="orderTypeCounts" />
              </div>
            </div> -->
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

import TopNavbar from '../../../layout/TopNavbar'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import ColumnChart from '../../../components/Charts/ColumnChart'
import PieChart from '../../../components/Charts/PieChart'

export default {
  components: {
    TopNavbar,
    Datepicker,
    ColumnChart,
    PieChart
  },
  data () {
    return {
      restaurantName: localStorage.getItem('restaurantName'),
      jwtToken: this.$cookies.get('token') || null,
      startDate: new Date(),
      endDate: new Date(),
      orderCountByMonthsThisYear: [],
      orderTypeCounts: [],
      showFilterLoading: false
    }
  },
  mounted () {
    this.getStatData()
  },
  methods: {
    getStatData () {
      this.showFilterLoading = true
      const sDate = this.startDate != '' ? moment(this.startDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      const eDate = this.endDate != '' ? moment(this.endDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')

      let url = process.env.VUE_APP_API_URL + '/api/order/statsBetweenDates?restaurantId='
        + localStorage.getItem('restaurantId')
        + '&startDate=' + sDate
        + '&endDate=' + eDate
        + '&currentTime=' + moment().format('h:m A')

      // send request
      axios
        .get(url,
          {
            headers: { Authorization: `Bearer ${this.jwtToken}` }
          })
        .then((data) => {
          const d = data.data
          this.orderCountByMonthsThisYear = [{
            data: d.orderCountByMonthsThisYear
          }]
          this.orderTypeCounts = []
          this.orderTypeCounts.push(d.tableOrderCount)
          this.orderTypeCounts.push(d.waitingOrderCount)
          this.orderTypeCounts.push(d.collectionOrderCount)
          this.orderTypeCounts.push(d.deliveryOrderCount)
          this.orderTypeCounts.push(d.expressOrderCount)

          this.showFilterLoading = false
        })
        .catch((error)=>{
          this.showFilterLoading = false
        })
    }
  }
}
</script>
<style scoped>
.dashboard-link{list-style-type: none;}
/* .content{padding: 10px 0;} */
.orderHistoryBtn{
  background-color: #fff;
  color: #000;
  width: 100%;
  opacity: 1 !important;
}
.applyBtn{
  background-color: green;
  color: #fff;
  width: 100%;
  opacity: 1 !important;
}
</style>

<template>

  <div class="content">
    <div class="container" style="padding-left: 10px">
      <div class="row">

        <div class="col-md-12 px-0">
          <top-navbar :title="restaurantName"></top-navbar>
          <div class="" style="background-color: #FFFFFF;padding-top: 2%">
            <h2 class="text-center mt-0" style="border-bottom: 2px solid;">Best Sold Items</h2>
            <!-- image -->
            <div style="background: rgb(209 201 218)">
              <h3 class="pl-3">Image</h3>
              <div class="row">
                <div class="col-md-4 text-right my-2">
                  <img :src="itemImage" width="200" class="header-image">
                  <!-- <i class="fa fa-times position-absolute header-delete"
                     @click="deleteItemImage" aria-hidden="true"></i> -->
                </div>
                <div class="col-md-12">
                  <form @submit.prevent="uploadImage">
                    <div class="row">
                      <div class="col-md-4 text-center p-4">
                        <img src="/img/upload.png" alt=""> Upload New Image
                        <input type="file" @change="itemImageChanged" accept="image/*" class="uploadBtn"/>
                      </div>
                      <div class="col-md-4 text-left p-4 contentBtnAlign">
                        <button class="btn submitBtn">Upload</button>
                        <img v-if="showItemImageLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <h4 class="pl-5">Label</h4>
            <div class="row pl-5">
              <div class="col-md-8 col-11">
                <input class="form-control" v-model="itemLabel" placeholder="Label">
              </div>
            </div>
            <h4 class="pl-5">Description</h4>
            <div class="row pl-5">
              <div class="col-md-8 col-11">
                <textarea class="form-control" v-model="itemDescription" placeholder="Description" cols="6" rows="5"></textarea>
              </div>
            </div>
            <h4 class="pl-5">Price</h4>
            <div class="row pl-5">
              <div class="col-md-8 col-11">
                <input class="form-control" v-model="itemPrice" placeholder="Price">
              </div>
            </div>

            <div class="col-md-12 p-5 contentBtnAlign">
              <button class="btn submitBtn" @click="uploadBestSell">Upload</button>
              <img v-if="showBestSellLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
            </div>

            <div class="row">
              <div v-for="(item, index) in bestSoldItems" :key="index" class="col-md-4 pl-5">
                <img :src="item.imageUrl" width="90%" height="250">
                <i class="fa fa-times position-absolute header-delete"
                     @click="() => deleteBestSoldItem(item.id)" aria-hidden="true"></i>
                <p class="mb-0">Label: {{item.label}}</p>
                <p>Description: {{item.description}}</p>
                <p>Price: {{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP' }).format(item.price) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TopNavbar from '../../layout/TopNavbar'
import axios from 'axios'

export default {
  components: {
    TopNavbar
  },
  data () {
    return {
      requestHeader: null,
      itemImage: '',
      publicId: '',
      jwtToken: '',
      restaurantName: localStorage.getItem('restaurantName'),
      showItemImageLoading: false,
      showBestSellLoading: false,
      itemLabel: '',
      itemDescription: '',
      itemPrice: 0,
      bestSoldItems: []
    }
  },
  created() {
    this.jwtToken = this.$cookies.get('token') || null
    this.requestHeader = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "*",
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
    this.getBestSellsItem()
  },
  methods: {
    itemImageChanged (e) {
      this.itemImage = e.target.files[0]
    },
    uploadImage(){
      this.showItemImageLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.itemImage || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/business/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          this.showItemImageLoading = false
          this.itemImage = data.data.imgUrl
          this.publicId = data.data.publicId
          this.$toastr.s("Successfully Uploaded.", "Success")
        })
    },
    deleteItemImage(){
      if (confirm('Please confirm to delete.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/business/deleteImage?publicId=' + this.publicId

        // send request
        axios
          .get(url,
            {
              headers: {
                Authorization: `Bearer ${this.jwtToken}`,
              }
            })
          .then((data) => {
            this.$toastr.s("Successfully Deleted.", "Success")
          })
      }
    },
    uploadBestSell () {
      this.showBestSellLoading = true

      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "label": this.itemLabel,
        "imageUrl": this.itemImage,
        "imagePublicId": this.publicId,
        "price": this.itemPrice,
        "categoryName": '',
        "description": this.itemDescription,
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/bestSell/create'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          this.showBestSellLoading = false
          this.itemLabel = ''
          this.itemDescription = ''
          this.itemPrice = 0
          this.itemImage = ''
          this.publicId = ''
          this.getBestSellsItem()
          this.$toastr.s("Successfully Created.", "Success")
        })
    },
    getBestSellsItem(){
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/bestSell?restaurantId='+localStorage.getItem('restaurantId')
      // send request
      axios
        .get(url,
          {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            }
          })
        .then((result) => {
          this.bestSoldItems = result.data
        })
    },
    deleteBestSoldItem (id) {
      if (confirm('Please confirm to delete.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/bestSell/delete?id=' + id

        // send request
        axios
          .get(url,
            {
              headers: {
                Authorization: `Bearer ${this.jwtToken}`,
              }
            })
          .then((data) => {
            this.getBestSellsItem()
            this.$toastr.s("Successfully Deleted.", "Success")
          })
      }
    }
  }
}
</script>

<style scoped>
.uploadBtn {
  cursor: pointer;
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}
.header-delete{width: 40px; height:40px; top: 0; cursor:pointer; font-size: 1.5rem; color: red;}
</style>

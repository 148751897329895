<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0" >
          <top-navbar v-if="!isEditClicked" title="Create owner"></top-navbar>
          <top-navbar v-else title="Edit owner"></top-navbar>
          <div style="background-color: #FFFFFF; padding-left: 31px; padding-right: 31px; padding-bottom: 31px;">
            <div class="row">
              <div class="col-md-12">
                <div class="btnGroup">
                  <button type="button" v-bind:class="['btn', 'btnGroupMargin', {'optionBtn': ownerDetails}]" @click="ownerDetailsFun">Owner Details</button>
                  <button type="button" v-bind:class="['btn', 'btnGroupMargin', {'optionBtn': businessInfo}]" @click="businessInfoFun">Business Info</button>
                  <button type="button" v-bind:class="['btn', {'optionBtn': bankDetails}]" @click="bankDetailsFun">Bank Details</button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="line"></div>
              </div>
            </div>

            <div class="row" style="margin-top: 49px;" v-if="ownerDetails">
              <div class="col-md-12">
                <form onsubmit="event.preventDefault()">
                  <div class="row">

                    <div class="col-md-4">
                        <label for="customRadio1">Name Title</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group custom-control custom-radio">
                                    <input type="radio" id="customRadio1" value="Mr" v-model="nameTitle" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio1">Mr</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group custom-control custom-radio">
                                    <input type="radio" id="customRadio2" value="Mrs" v-model="nameTitle" class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio2">Mrs</label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1" :class="{requiredCls : isLnameGiven}" >Last Name *</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="lastName" aria-describedby="emailHelp" placeholder="Enter your last name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">House/Flat No</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="house" aria-describedby="emailHelp" placeholder="Enter your house no">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Street Name</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="street" aria-describedby="emailHelp" placeholder="Enter your street name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Username</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="username" aria-describedby="emailHelp" disabled>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleInputEmail1" :class="{requiredCls : isFnameGiven}">First Name *</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="firstName" aria-describedby="emailHelp" placeholder="Enter your first name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1" :class="{requiredCls : isDOBgiven}">DOB *</label>
                            <datepicker v-model="dob" placeholder="Select Date"></datepicker>
                            <!-- <input type="text" class="form-control" id="exampleInputEmail1" v-model="dob" aria-describedby="emailHelp" placeholder="DOB"> -->
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Town</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="town" aria-describedby="emailHelp" placeholder="Enter your town">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1" :class="{requiredCls : isPostCodeGiven}">Post code *</label>
                            <input type="text" class="form-control" id="exampleInputEmail1"  v-model="postCode" aria-describedby="emailHelp" placeholder="Enter your post code">
                        </div>
                        <div class="form-group">
                          <label>Password</label>
                          <div style="display: flex; justify-content: space-between">
                            <input :type="password == '********' ? 'password' : 'text'" class="form-control w-50" v-model="password" id="showPass" aria-describedby="emailHelp">
                            <button v-if="userRole == 'Admin'" class="btn btn-primary bg-primary text-white" @click="showPassword">Show Password</button>
                          </div>
                          <button @click="showConfPassModal = true" class="btn btn-success mt-3">Generate Password</button>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleInputEmail1" :class="{requiredCls : isMnameGiven}">Middle Name *</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="middleName" aria-describedby="emailHelp" placeholder="Enter your middle name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1" :class="{requiredCls : isEmailGiven}">Email address *</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" v-model="email" aria-describedby="emailHelp" placeholder="Enter your email ID">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">City</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="city" aria-describedby="emailHelp" placeholder="Enter your city name">
                        </div>
                        <div class="form-group">
                            <label :class="{requiredCls : isTelephoneGiven}">Telephone *</label>
                            <input type="text" class="form-control" v-model="telephone" placeholder="Enter telephone no">
                        </div>
                    </div>

                  </div>

                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="addressCheckbox" @click="previousAddressFun">
                    <label class="custom-control-label" for="customCheck1">Please select this box, if you are living this adress less than 3 years.</label>
                  </div>

                  <div class="row" v-if="addressCheckbox">
                      <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="line"></div>
                            </div>
                          </div>

                          <br>
                          <h4 style="margin-bottom: 0px;">Please Provide Your Previous Address</h4>

                          <div class="row">
                              <div class="col-md-12">
                                  <div class="line"></div>
                              </div>
                          </div>

                          <div class="row" style="padding-top: 41px;">
                              <div class="col-md-4">
                                  <div class="form-group">
                                      <label for="exampleInputEmail1">Door No</label>
                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevDoor" aria-describedby="emailHelp" placeholder="Enter your door no">
                                  </div>
                                  <div class="form-group">
                                      <label for="exampleInputEmail1">Town</label>
                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevTown" aria-describedby="emailHelp" placeholder="Enter your town">
                                  </div>
                              </div>
                              <div class="col-md-4">
                                  <div class="form-group">
                                      <label for="exampleInputEmail1">House/Flat No</label>
                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevHouse" aria-describedby="emailHelp" placeholder="Enter your house/flat no">
                                  </div>
                                  <div class="form-group">
                                      <label for="exampleInputEmail1">City</label>
                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevCity" aria-describedby="emailHelp" placeholder="Enter your city">
                                  </div>
                              </div>
                              <div class="col-md-4">
                                  <div class="form-group">
                                      <label for="exampleInputEmail1">Post Code</label>
                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevPostCode" aria-describedby="emailHelp" placeholder="Enter your post code">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <br>
                  <button type="button" class="btn submitBtn" v-if="!isEditClicked" @click="submitDetailsInfo">Submit</button>&nbsp;
                  <button type="button" class="btn cancelBtn" v-if="!isEditClicked" @click="cancelDetailsInfo">Cancel</button>

                  <button type="button" class="btn submitBtn" v-if="isEditClicked" @click="submitEditDetailsInfo">Submit</button>&nbsp;
                  <button type="button" class="btn cancelBtn" v-if="isEditClicked" @click="cancelEditDetailsInfo">Cancel</button>

                </form>
              </div>
            </div>

            <div class="row" style="margin-top: 49px;" v-if="businessInfo">
              <div class="col-md-12">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="customRadio1">Logo</label>
                        <div class="uploadDiv">
                          <img src="/img/upload.png" alt=""> Upload Your Logo
                          <input type="file" name="file" class="uploadBtn"/>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1">Company Name</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="companyName" aria-describedby="emailHelp" placeholder="Enter company name">
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Town</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="bTown" aria-describedby="emailHelp" placeholder="Enter Town">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1" :class="{requiredCls : isBusinessNameGiven}">Business Name *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="businessName" aria-describedby="emailHelp" placeholder="Enter your bussiness name">
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">House/Flat no</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="bHouse" aria-describedby="emailHelp" placeholder="Enter house/flat no">
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">City</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="bCity" aria-describedby="emailHelp" placeholder="Enter your city">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Sole/Limited</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="soloOrLimited" aria-describedby="emailHelp" placeholder="Enter sole/limited">
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1" :class="{requiredCls : isBstreetGiven}">Street *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="bStreet" aria-describedby="emailHelp" placeholder="Enter street">
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1" :class="{requiredCls : isBpostCodeGiven}">Post Code *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="bPostCode" aria-describedby="emailHelp" placeholder="Enter post code">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="exampleFormControlSelect1">Business Type</label>
                              <select class="form-control" v-model="companyType" id="exampleFormControlSelect1">
                                  <!-- <option disabled selected value="">Select business type</option> -->
                                  <option value="Takeway">Takeway</option>
                                  <option value="Restaurant">Restaurant</option>
                              </select>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                            <h5>Select Service Type</h5>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.table" id="serviceTypeTable">
                              <label class="custom-control-label" for="serviceTypeTable">Table</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.waiting" id="serviceTypeWaiting">
                              <label class="custom-control-label" for="serviceTypeWaiting">Waiting</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.collection" id="serviceTypeCollection">
                              <label class="custom-control-label" for="serviceTypeCollection">Collection</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.delivery" id="serviceTypeDelivery">
                              <label class="custom-control-label" for="serviceTypeDelivery">Delivery</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.online" id="serviceTypeOnline">
                              <label class="custom-control-label" for="serviceTypeOnline">Online</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.booking" id="serviceTypeBooking">
                              <label class="custom-control-label" for="serviceTypeBooking">Booking</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.express" id="serviceTypeExpress">
                              <label class="custom-control-label" for="serviceTypeExpress">Express</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.callerId" id="serviceTypeCallerId">
                              <label class="custom-control-label" for="serviceTypeCallerId">CallerId</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.till" id="serviceTypeTill">
                              <label class="custom-control-label" for="serviceTypeTill">Till</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.info" id="serviceTypeInfo">
                              <label class="custom-control-label" for="serviceTypeInfo">Info</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.customer" id="serviceTypeCustomer">
                              <label class="custom-control-label" for="serviceTypeCustomer">Customer</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.report" id="serviceTypeReport">
                              <label class="custom-control-label" for="serviceTypeReport">Report</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.deliveryPlan" id="serviceTypeDeliveryPlan">
                              <label class="custom-control-label" for="serviceTypeDeliveryPlan">Delivery Plan</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.promotion" id="serviceTypePromotion">
                              <label class="custom-control-label" for="serviceTypePromotion">Promotion</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.discountPlan" id="serviceTypeDiscountPlan">
                              <label class="custom-control-label" for="serviceTypeDiscountPlan">Discount Plan</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.time" id="serviceTypeTime">
                              <label class="custom-control-label" for="serviceTypeTime">Time</label> <br>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" v-model="serviceType.menu" id="serviceTypeMenu">
                              <label class="custom-control-label" for="serviceTypeMenu">Menu</label> <br>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1" :class="{requiredCls : isBtelephoneGiven}">Telephone *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="bTelephone" aria-describedby="emailHelp" placeholder="Enter telephone">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Subscription end date</label>
                        <datepicker v-model="subscriptionEndDate" placeholder="Select Date"></datepicker>
                        <!-- <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter subscription end date"> -->
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label :class="{requiredCls : isBStatusGiven}">Status Action *</label>
                        <select v-model="businessStatus" class="form-control">
                          <!-- <option value="">Select service type</option> -->
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <br>
                  <button type="button" class="btn submitBtn" v-if="!isEditClicked" @click="submitDetailsInfo">Submit</button>&nbsp;
                  <button type="button" class="btn cancelBtn" v-if="!isEditClicked" @click="cancelDetailsInfo">Cancel</button>

                  <button type="button" class="btn submitBtn" v-if="isEditClicked" @click="submitEditDetailsInfo">Submit</button>&nbsp;
                  <button type="button" class="btn cancelBtn" v-if="isEditClicked" @click="cancelEditDetailsInfo">Cancel</button>
                </form>
              </div>
            </div>

            <div class="row" style="margin-top: 49px;" v-if="bankDetails">
              <div class="col-md-12">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Bank Name</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="bankName" aria-describedby="emailHelp" placeholder="Enter your bank name">
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Account Details</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="accountDetails" aria-describedby="emailHelp" placeholder="Enter your account no">
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Account Holder Name</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="accountHolderName" aria-describedby="emailHelp" placeholder="Enter account holder name">
                      </div>

                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" v-model="isTermsAccepted" id="customCheck1">
                        <label class="custom-control-label" for="customCheck1">Select checkbox to accept our terms</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Sort Code</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="sortCode" aria-describedby="emailHelp" placeholder="Enter your sort code">
                      </div>
                      <div class="form-group">
                        <label for="exampleInputEmail1">Signature (write your name)</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" v-model="signature" aria-describedby="emailHelp" placeholder="Write down your name here">
                      </div>
                    </div>
                  </div>

                  <br>
                  <button type="button" class="btn submitBtn" v-if="!isEditClicked" @click="submitDetailsInfo">Submit</button>&nbsp;
                  <button type="button" class="btn cancelBtn" v-if="!isEditClicked" @click="cancelDetailsInfo">Cancel</button>

                  <button type="button" class="btn submitBtn" v-if="isEditClicked" @click="submitEditDetailsInfo">Submit</button>&nbsp;
                  <button type="button" class="btn cancelBtn" v-if="isEditClicked" @click="cancelEditDetailsInfo">Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-vue">
        <!-- overlay -->
        <div class="overlay" v-if="showConfPassModal" @click="showConfPassModal = false"></div>

        <!-- modal -->
        <div class="modal-own" v-if="showConfPassModal">
          <div class="modal-title">
            <h2 class="d-inline" style="color: rgb(254, 117, 15); margin: unset">Confirm Password</h2>
            <!-- <img src="/img/cross.png" class="float-right" alt="Image" title="Remove"> -->
            <i class="fa fa-times float-right mr-3" @click="showConfPassModal=false" style="cursor: pointer; color: #fe750f; font-size: 2rem;"></i>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group" style="margin: 2rem 0">
                <label style="color: red" for="confPassword" v-if="passMisMatch">Password doesn't match </label>
                <input type="password" class="form-control" id="confPassword" v-model="confPassword" placeholder="Admin Password">
              </div>
              <button type="button" @click="confirmPasswordBeforeGeneratePassword" class="btn submitBtn">Submit</button>&nbsp;
              <img v-if="checkPasswordLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import axios from 'axios'

import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
// import * as lang from "vuejs-datepicker/src/locale";
import TopNavbar from '../../layout/TopNavbar'

// import logout from './../../mixins/logoutMixins.js'

export default {
  components: {
    Datepicker,
    TopNavbar
  },
  data () {
    return {
      format: "d MMMM yyyy",
      ownerDetails: true,
      businessInfo: false,
      bankDetails: false,
      previousAddress: false,
      userRole: this.$cookies.get('adminData').role,

      // owner info
      nameTitle: '',
      firstName: '',
      username: '',
      password: '********',
      middleName: '',
      lastName: '',
      dob: '',
      email: '',
      house: '',
      town: '',
      city: '',
      street: '',
      postCode: '',
      telephone: '',
      isLivingTheCityLessYears: false,
      prevCity: '',
      prevDoor: '',
      prevTown: '',
      prevHouse: '',
      prevPostCode: '',
      addressCheckbox: false,

      // business details
      logo: '',
      businessName: '',
      soloOrLimited: '',
      companyName: '',
      companyType: '',
      bHouse: '',
      bStreet: '',
      bTown: '',
      bCity: '',
      bPostCode: '',
      businessType: '',
      businessStatus: '',
      bTelephone: '',
      subscriptionEndDate: '',
      statusAction: '',
      serviceType: {
        table: null,
        waiting: null,
        collection: null,
        delivery: null,
        online: null,
        booking: null,
        express: null,
        callerId: null,
        till: null,
        info: null,
        customer: null,
        report: null,
        deliveryPlan: null,
        promotion: null,
        discountPlan: null,
        time: null,
        menu: null,
      },

      // bank details
      bankName: '',
      accountHolderName: '',
      sortCode: '',
      accountDetails: '',
      isTermsAccepted: false,
      signature: '',

      // required fields
      isFnameGiven: false,
      isLnameGiven: false,
      isMnameGiven: false,
      isPostCodeGiven: false,
      isDOBgiven: false,
      isEmailGiven: false,
      isTelephoneGiven: false,
      isBusinessNameGiven: false,
      isBstreetGiven: false,
      isBpostCodeGiven: false,
      isBtelephoneGiven: false,
      isBStatusGiven: false,

      // edit.
      isEditClicked: JSON.parse(this.$route.query.edit),
      editId: '',

      jwtToken: this.$cookies.get('token') || null,
      restaurantId: localStorage.getItem('restaurantId'),

      showConfPassModal: false,
      confPassword: '',
      passMisMatch: false,
      checkPasswordLoading: false,
    }
  },
  computed: {
    // ...mapState(['restaurantId'])
  },
  created () {
    this.ownerDetailsFun()
    if(this.isEditClicked){
      this.isEditDataClicked()
    }
  },
  methods: {
    isEditDataClicked() {
      let url = process.env.VUE_APP_API_URL+'/api/restaurant/find?id=' + this.restaurantId

      axios
      .get(url, { headers: { Authorization: `Bearer ${this.jwtToken}` } })
      .then((response) => {
        // if (this.isEditClicked) {

          let editableData = response.data

          if (editableData.providedServices) {
            let serviceTypes = editableData.providedServices
            this.serviceType = {
              table: serviceTypes.includes('Table') ? true : false,
              waiting: serviceTypes.includes('Waiting') ? true : false,
              collection: serviceTypes.includes('Collection') ? true : false,
              delivery: serviceTypes.includes('Delivery') ? true : false,
              online: serviceTypes.includes('Online') ? true : false,
              booking: serviceTypes.includes('Booking') ? true : false,
              express: serviceTypes.includes('Express') ? true : false,
              callerId: serviceTypes.includes('CallerId') ? true : false,
              till: serviceTypes.includes('Till') ? true : false,
              info: serviceTypes.includes('Info') ? true : false,
              customer: serviceTypes.includes('Customer') ? true : false,
              report: serviceTypes.includes('Report') ? true : false,
              deliveryPlan: serviceTypes.includes('DeliveryPlan') ? true : false,
              promotion: serviceTypes.includes('Promotion') ? true : false,
              discountPlan: serviceTypes.includes('DiscountPlan') ? true : false,
              time: serviceTypes.includes('Time') ? true : false,
              menu: serviceTypes.includes('Menu') ? true : false,
            }
          }

          this.editId = editableData.id

          this.username = editableData.owner.userName
          // this.password = editableData.owner.password
          this.nameTitle = editableData.owner.nameTitle
          this.firstName = editableData.owner.firstName
          this.middleName = editableData.owner.middleName
          this.lastName = editableData.owner.lastName
          this.telephone = editableData.owner.phone
          this.email = editableData.owner.email
          this.dob = editableData.owner.dateOfBirth
          this.city = editableData.owner.address ? editableData.owner.address.city : ''
          this.building = editableData.owner.address ? editableData.owner.address.building : ''
          this.town = editableData.owner.address ? editableData.owner.address.town : ''
          this.streetNo = editableData.owner.address ? editableData.owner.address.streetNo : ''
          this.postCode = editableData.owner.address ? editableData.owner.address.postcode : ''

          this.addressCheckbox = editableData.owner.addressCheckbox ? editableData.owner.addressCheckbox == 'Yes' ? true : false : false
          this.prevCity = editableData.owner.previousAddress ? editableData.owner.previousAddress.city : ''
          this.prevDoor = editableData.owner.previousAddress ? editableData.owner.previousAddress.door : ''
          this.prevTown = editableData.owner.previousAddress ? editableData.owner.previousAddress.town : ''
          this.prevHouse = editableData.owner.previousAddress ? editableData.owner.previousAddress.building : ''
          this.prevPostCode = editableData.owner.previousAddress ? editableData.owner.previousAddress.postcode : ''

          this.businessName = editableData.restaurantName
          this.bCity = editableData.address.city
          this.bStreet = editableData.address.streetNo
          this.bTown = editableData.address.town
          this.bPostCode = editableData.address.postcode
          this.bTelephone = editableData.phone
          this.businessStatus = editableData.status
          this.companyName = editableData.companyName
          this.soloOrLimited = editableData.tradeMark
          this.companyType =  editableData.companyType
        // }
      })
    },
    ownerDetailsFun () {
        this.ownerDetails = true
        this.businessInfo = false
        this.bankDetails = false
    },
    businessInfoFun () {
      this.ownerDetails = false
      this.businessInfo = true
      this.bankDetails = false
    },
    bankDetailsFun () {
        this.ownerDetails = false
        this.businessInfo = false
        this.bankDetails = true
    },
    previousAddressFun () {
        this.previousAddress = !this.previousAddress
    },
    // edit details
    submitEditDetailsInfo() {
      let dateObj = new Date(this.dob)
      let month = dateObj.getMonth()+1
      let birthDate = dateObj.getDate()+'/'+month+'/'+dateObj.getFullYear()

      let serviceTypes = this.getServiceTypes()

      if (this.firstName && this.middleName && this.lastName && this.dob && this.email && this.postCode && this.telephone &&
          this.businessName && this.bStreet && this.bPostCode && this.bTelephone) {

        // request url
        let url = process.env.VUE_APP_API_URL+'/api/restaurant/update'

        // request data
        let dataToSubmit = {
          "id": this.restaurantId,
          "createdAt": "",
          "updatedAt": "",
          "img": "",
          "restaurantName": this.businessName,
          "phone": this.bTelephone,
          "email": this.email,
          "webAddress": "",
          "landLine": "",
          "logo": "",
          "TIN": "",
          "termsCond": "",
          "companyName": this.companyName,
          "tradeMark": this.soloOrLimited,
          "companyType": this.companyType,
          "address": {
            "type": "",
            "country": "BD",
            "region": "",
            "city": this.bCity,
            "area": "",
            "town": this.bTown,
            "door": "",
            "building": this.bHouse,
            "streetNo": this.bStreet,
            "postcode": this.bPostCode,
            "isPrevious": ""
          },
          "tableProcessingTime": "",
          "expressProcessingTime": "",
          "waitingProcessingTime": "",
          "collectionProcessingTime": "",
          "deliveryProcessingTime": "",
          "defaultVAT": "",
          "vatRegNo": "",
          "defaultDiscount": "",
          "serviceCharge": "",
          "defaultCCFee": "",
          "bagFee": "",
          "owner": {
            "createdAt": "",
            "updatedAt": "",
            "userName": this.firstName,
            "nameTitle": this.nameTitle,
            "firstName": this.firstName,
            "middleName": this.middleName,
            "lastName": this.lastName,
            "dateOfBirth": birthDate,
            "phone": this.telephone,
            "email": this.email,
            "address": {
              "type": "",
              "country": "BD",
              "region": "",
              "city": this.city,
              "area": "",
              "door": "",
              "building": this.house,
              "town": this.town,
              "streetNo": this.street,
              "postcode": this.postCode
            },
            "addressCheckbox": this.addressCheckbox ? 'Yes' : 'No',
            "previousAddress": {
              "city": this.prevCity,
              "town": this.prevTown,
              "door": this.prevDoor,
              "building": this.prevHouse,
              "postcode": this.prevPostCode,
            },
          },
          "bankDetails": {
            "bankName": this.bankName,
            "holderName": this.accountHolderName,
            "sortCode": "",
            "acceptTerms": this.isTermsAccepted,
            "signature": this.signature
          },
          "providedServices": serviceTypes,
          "status": this.businessStatus
        }
        // request header
        let requestHeader = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.jwtToken}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }

        // send request
        axios
        .post(url, dataToSubmit, {
          headers: requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.$toastr.s("Updated Successfully", "Success");
            //setTimeout(()=> {window.location = '/admin/owner-list'}, 1000)
          }
        })
      } else {
        this.$toastr.w("Fill up all required fields", "Warning");

        this.isLnameGiven = this.lastName ? false : true
        this.isFnameGiven = this.firstName ? false : true
        this.isMnameGiven = this.middleName ? false : true
        this.isDOBgiven = this.dob ? false : true
        this.isEmailGiven = this.email ? false : true
        this.isPostCodeGiven = this.postCode ? false : true
        this.isTelephoneGiven = this.telephone ? false : true
        this.isBusinessNameGiven = this.businessName ? false : true
        this.isBstreetGiven = this.bStreet ? false : true
        this.isBpostCodeGiven = this.bPostCode ? false : true
        this.isBtelephoneGiven = this.bTelephone ? false : true
        this.isBStatusGiven = this.businessStatus ? false : true
      }
    },
    cancelEditDetailsInfo() {
        window.location = './admin/owner-list'
    },
    // owner details
    submitDetailsInfo() {
        let dateObj = new Date(this.dob)
        let month = dateObj.getMonth()+1

        let birthDate = dateObj.getDate()+'/'+month+'/'+dateObj.getFullYear()

        let serviceTypes = this.getServiceTypes()

        if (this.firstName && this.middleName && this.lastName && this.dob && this.email && this.postCode && this.telephone &&
            this.businessName && this.bStreet && this.bPostCode && this.bTelephone) {

            // request url
            let url = process.env.VUE_APP_API_URL+'/api/restaurant/create'

            // request data
            let dataToSubmit = {
              "createdAt": "",
              "updatedAt": "",
              "restaurantName": this.businessName,
              "img": "",
              "phone": this.bTelephone,
              "email": this.email,
              "webAddress": "",
              "landLine": "",
              "logo": "",
              "TIN": "",
              "termsCond": "",
              "companyName": this.companyName,
              "tradeMark": this.soloOrLimited,
              "companyType": this.companyType,
              "address": {
                "type": "",
                "country": "BD",
                "region": "",
                "city": this.bCity,
                "area": "",
                "town": this.bTown,
                "door": "",
                "building": this.bHouse,
                "streetNo": this.bStreet,
                "postcode": this.bPostCode,
                "isPrevious": ""
              },
              "tableProcessingTime": "",
              "expressProcessingTime": "",
              "waitingProcessingTime": "",
              "collectionProcessingTime": "",
              "deliveryProcessingTime": "",
              "defaultVAT": "",
              "vatRegNo": "",
              "defaultDiscount": "",
              "serviceCharge": "",
              "defaultCCFee": "",
              "bagFee": "",
              "owner": {
                "createdAt": "",
                "updatedAt": "",
                "userName": this.firstName,
                "nameTitle": this.nameTitle,
                "firstName": this.firstName,
                "middleName": this.middleName,
                "lastName": this.lastName,
                "dateOfBirth": birthDate,
                "phone": this.telephone,
                "email": this.email,
                "addressCheckbox": this.addressCheckbox,
                "address": {
                  "type": "",
                  "country": "BD",
                  "region": "",
                  "city": this.city,
                  "area": "",
                  "door": "",
                  "building": this.house,
                  "town": this.town,
                  "streetNo": this.street,
                  "postcode": this.postCode
                }
              },
              "bankDetails": {
                "bankName": this.bankName,
                "holderName": this.accountHolderName,
                "sortCode": "",
                "acceptTerms": this.isTermsAccepted,
                "signature": this.signature
              },
              "providedServices": serviceTypes,
              "status": this.businessStatus
            }

            // request header
            let requestHeader = {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.jwtToken}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Allow-Headers": "*",
              'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            // send request
            axios
            .post(url, dataToSubmit, {
              headers: requestHeader
            })
            .then((data) => {
              if (data.status == 200) {
                this.$toastr.s("Successfully Created.", "Success");
                setTimeout(()=> {window.location = '/admin/owner-list'}, 1000)
              }
            })
        }
        else {
          this.$toastr.w("Fill up all required fields", "Warning");

          this.isLnameGiven = this.lastName ? false : true
          this.isFnameGiven = this.firstName ? false : true
          this.isMnameGiven = this.middleName ? false : true
          this.isDOBgiven = this.dob ? false : true
          this.isEmailGiven = this.email ? false : true
          this.isPostCodeGiven = this.postCode ? false : true
          this.isTelephoneGiven = this.telephone ? false : true
          this.isBusinessNameGiven = this.businessName ? false : true
          this.isBstreetGiven = this.bStreet ? false : true
          this.isBpostCodeGiven = this.bPostCode ? false : true
          this.isBtelephoneGiven = this.bTelephone ? false : true
          this.isBStatusGiven = this.businessStatus ? false : true
        }
    },
    cancelDetailsInfo() {
      // owner detailsthis.
      this.nameTitle = ''
      this.firstName = ''
      this.middleName = ''
      this.lastName = ''
      this.dob = ''
      this.email = ''
      this.house = ''
      this.town = ''
      this.city = ''
      this.street = ''
      this.postCode = ''
      this.telephone = ''
      this.isLivingTheCityLessYears = false
      this.prevCity = ''
      this.prevDoor = ''
      this.prevTown = ''
      this.prevHouse = ''
      this.prevPostCode = ''

      // business details
      this.logo = ''
      this.businessName = ''
      this.soloOrLimited = ''
      this.companyName = ''
      this.bHouse = ''
      this.bStreet = ''
      this.bTown = ''
      this.bCity = ''
      this.bPostCode = ''
      this.businessType = ''
      this.serviceType = ''
      this.bTelephone = ''
      this.subscriptionEndDate = ''
      this.statusAction = ''

      // bank details
      this.bankName = ''
      this.accountHolderName = ''
      this.sortCode = ''
      this.accountDetails = ''
      this.isTermsAccepted = false
      this.signature = ''
    },
    getServiceTypes () {
      let serviceTypes = [];
      // "Table", "Waiting", "Collection", "Delivery", "Online", "Booking", "Express", "CallerId", "Till")
      if (this.serviceType.table) { serviceTypes.push('Table') }
      if (this.serviceType.waiting) { serviceTypes.push('Waiting') }
      if (this.serviceType.collection) { serviceTypes.push('Collection') }
      if (this.serviceType.delivery) { serviceTypes.push('Delivery') }
      if (this.serviceType.online) { serviceTypes.push('Online') }
      if (this.serviceType.booking) { serviceTypes.push('Booking') }
      if (this.serviceType.express) { serviceTypes.push('Express') }
      if (this.serviceType.callerId) { serviceTypes.push('CallerId') }
      if (this.serviceType.till) { serviceTypes.push('Till') }
      if (this.serviceType.info) { serviceTypes.push('Info') }
      if (this.serviceType.customer) { serviceTypes.push('Customer') }
      if (this.serviceType.report) { serviceTypes.push('Report') }
      if (this.serviceType.deliveryPlan) { serviceTypes.push('DeliveryPlan') }
      if (this.serviceType.promotion) { serviceTypes.push('Promotion') }
      if (this.serviceType.discountPlan) { serviceTypes.push('DiscountPlan') }
      if (this.serviceType.time) { serviceTypes.push('Time') }
      if (this.serviceType.menu) { serviceTypes.push('Menu') }
      return serviceTypes
    },
    confirmPasswordBeforeGeneratePassword () {
      this.checkPasswordLoading = true
      this.checkPassword()
    },
    checkPassword () {
      let url = process.env.VUE_APP_API_URL+'/api/user/validateAdminPassword'

      axios
      .post(url, {'password': this.confPassword},
      {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        }
      })
      .then((data)=> {
        this.passMisMatch = false
        this.generateNewPassword()
      })
      .catch((error)=>{
        this.passMisMatch = true
      })
    },
    showPassword () {
      if (this.password !== '********') {
        this.password = '********'
        return
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/restaurant/showPassword?id=' + this.restaurantId
      // request header
      let requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }

      // send request
      axios
      .get(url,
      { headers: requestHeader })
      .then((data) => {
        if (data.status == 200) {
          this.password = data.data.password
        }
      })
      .catch((error)=>{

      })
    },
    generateNewPassword () {
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/user/generateNewPassword?restaurantId=' + this.restaurantId
      // request header
      let requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }

      // send request
      axios
      .post(url, {'restaurantId': this.restaurantId},
      { headers: requestHeader })
      .then((data) => {
        this.checkPasswordLoading = false
        if (data.status == 200) {
          this.$toastr.s("Successfully Generated.", "Success");
          this.$router.go()
        }
      })
      .catch((error)=>{
        this.checkPasswordLoading = false
        this.passMisMatch = true
      })
    }
  }
}
</script>

<style scoped>
/* .content{padding: 10px 0} */
.header {
  position: absolute;
  width: 100%;
  height: 69px;
  background: #FFEDE3;
  border: 1px solid #FFD8BC;
  box-sizing: border-box;
  border-radius: 5px;
}

.headerText {
  position: absolute;
  width: 96px;
  height: 19px;
  left: 28px;
  top: 20px;
  color: #FE750F;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.btnGroup {
  margin-top: 91px;
  text-align: center;
}

.btnGroupMargin {margin-right: 20px;}

.line {
  position: relative;
  width: 100%;
  height: 0px;
  top: 25px;
  border: 1px solid #FFE9FF;
}

.uploadDiv {
  position: relative;
  overflow: hidden;
  color: black;
  border: none;
}
.uploadBtn {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}

.cancelBtn {
  background-color: #797979;
  color: white;
  border-color:  #797979;
}

.optionBtn {
  background: #FFF5FF;
  border: 1px solid #E317E3;
  color: #E317E3;
}

.requiredCls {color: red;}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-title {
  padding: 10px;
  background-color: #FFEDE3;
  border-radius: 10px 10px 0 0;
  top: 10%;
  left: 35%;
}
.modal-vue .modal-own {
  position: fixed;
  width: 500px;
  z-index: 9999;
  margin: 0 auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 10px;
  top: 15%;
  left: 35%;
}

.modal-vue .close{
  position: absolute;
  top: 10px;
  right: 10px;
}

</style>

<style>
  .vdp-datepicker > div > input {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  }
</style>

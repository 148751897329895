<template>
  <div class="content py-0">
    <div class="container">
        <div class="row">
          <div class="col-md-6 px-0" style="margin-bottom: 5rem;">
              <div class="header">
                <div class="row">
                  <div class="col-md-6 col-6">
                    <span class="headerText">
                      Modifier
                    </span>
                  </div>
                  <div class="col-md-6 col-6 pl-0">
                    <span style="float: right;">
                      <button type="button" class="btn headerBtnStyle" @click="addNewModifier">Add New Modifier</button>
                    </span>
                  </div>
                </div>
              </div>
              <div class="toppingsTable" style="background-color: #FFFFFF; padding: 2% 31px 31px 31px;">
                <table class="table table-bordered">
                  <thead>
                    <tr style="font-weight: bold; color: blue">
                      <th class="w-75">Title</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in modifierDataList" :key="index">
                      <td>{{ item.label }}</td>
                      <td>
                        <i class="fa fa-edit" style="cursor: pointer;" @click="() => editItem(item.id)"></i> &nbsp;
                        <i class="fa fa-trash" style="color: #F4522D; cursor: pointer;" @click="() => deleteItem(item.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="row" style="background: #FFFFFF; margin-left: 2px; margin-right: 2px;" v-if="isNewAdd">
                  <div class="col-md-8 col-8">
                    <input type="text" class="form-control" v-model="newName" placeholder="Modifier">
                  </div>
                  <div class="col-md-4 col-4 text-center pt-2">
                    <i class="fa fa-check-square" style="cursor: pointer; color: #F4522D;" @click="addItem"></i> &nbsp;
                    <i class="fa fa-times" style="cursor: pointer; color: #F4522D;" @click="cancel"></i>
                  </div>
                </div>

                <div class="row" style="background: #FFFFFF; margin-left: 2px; margin-right: 2px;" v-if="isEditItem">
                  <div class="col-md-8 col-8">
                    <input type="text" class="form-control" v-model="editName" placeholder="Modifier">
                  </div>
                  <div class="col-md-4 col-4 text-center pt-2">
                    <i class="fa fa-check-square" style="cursor: pointer; color: #F4522D;" @click="updateItem"></i> &nbsp;
                    <i class="fa fa-times" style="cursor: pointer; color: #F4522D;" @click="cancelEdit"></i>
                  </div>
                </div>

              </div>
          </div>
          <div class="col-md-6">

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
    data () {
      return {
        isNewAdd: false,
        newName: '',
        restaurantId: parseInt(localStorage.getItem('restaurantId')),
        modifierDataList: [],
        editName: '',
        isEditItem: false,
        editItemId: '',
        jwtToken: this.$cookies.get('token') || null
      }
    },
    created () {
        this.modifierList()
    },
    methods: {
        addNewModifier () {
          this.isNewAdd = true
          this.isEditItem = false
          this.scrollToBottom()
        },
        cancel () {
            this.isNewAdd = false
        },
        addItem () {
            let url = process.env.VUE_APP_API_URL+`/api/modifier/create`

            let dataToSubmit = {
                "restaurantId": this.restaurantId,
                "label": this.newName,
                "resourceRef": "toppings-1",
                "refCode": 3000,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": 0,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 3000,
                "modifierId": 0,
                "mealDealId": 0,
                "setId": 0,
                "printOptionId": 1,
                "printOptionTitle": "",
                "terminalOption": "",
                "status": "Active",
                "basePrice": "1.00",
                "toppingsCategory": "Top1",
                "mealAssignFor": "",
                "toppingsCategoryId": 1,
                "baseQty": 1,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "Modifier",
                "mealDealType": "",
                "displayOrder": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");

                    this.isNewAdd = false

                    this.modifierList()
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        editItem (val) {
          this.editItemId = val
          this.isEditItem = true
          this.isNewAdd = false

          for (var i = 0; i < this.modifierDataList.length; i++) {
            if (this.modifierDataList[i].id == val) {
              this.editName = this.modifierDataList[i].label
            }
          }
          this.scrollToBottom()
        },
        scrollToBottom () {
          window.scrollTo({bottom: 0, top: document.body.scrollHeight, behavior: 'smooth'})
        },
        cancelEdit () {
            this.editItemId = ''
            this.isEditItem = false
        },
        updateItem () {
            let url = process.env.VUE_APP_API_URL+`/api/modifier/update`

            let dataToSubmit = {
                "id": this.editItemId,
                "restaurantId": this.restaurantId,
                "label": this.editName,
                "resourceRef": "toppings-1",
                "refCode": 3000,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": 0,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 3000,
                "modifierId": 0,
                "mealDealId": 0,
                "setId": 0,
                "printOptionId": 1,
                "printOptionTitle": "",
                "terminalOption": "",
                "status": "Active",
                "basePrice": "1.00",
                "toppingsCategory": "Top1",
                "mealAssignFor": "",
                "toppingsCategoryId": 1,
                "baseQty": 1,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "Modifier",
                "mealDealType": "",
                "displayOrder": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            // request header
            let requestHeader = {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.jwtToken}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Access-Control-Allow-Headers": "*",
              'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                  this.$toastr.s("Updated Successfully", "Success");

                  this.editItemId = ''
                  this.isEditItem = false
                  this.modifierList()
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        deleteItem (val){
            if (confirm('Are you sure to delete this modifier')) {

                let url = process.env.VUE_APP_API_URL+`/api/modifier/delete?id=${val}`

                // request header
                let requestHeader = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.jwtToken}`,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Headers": "*",
                    'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
                let dataToSubmit = {}

                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");

                        this.modifierList()
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }
                })
            }
        },
        modifierList () {
            let url = process.env.VUE_APP_API_URL+`/api/modifier?restaurantId=${this.restaurantId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.modifierDataList = data.data
            })
        }
    }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 69px;
  background: #FFEDE3;
  border: 1px solid #FFD8BC;
  box-sizing: border-box;
}

.headerText {
  position: absolute;
  width: 200px;
  height: 19px;
  left: 28px;
  top: 20px;
  color: #FE750F;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.InfoTable {
  position: absolute;
  width: 100%;
  height: auto;
  top: 65px;
  background: #F0F0F0;
}

.headerBtnStyle {
  background: #FFF5FF;
  border: 1px solid #BD31B5;
  color: #FE7611;
  margin-top: 7%;
}
.table > thead > tr > th {color: #E317E3; background: #FFF5FF;}
</style>

<template>

  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0">
          <top-navbar title="Restaurant List" search="true" @filterRestaurant="filterRestaurant"></top-navbar>

          <div style="background-color: #FFFFFF; padding: 2% 15px 31px 15px; overflow-x: auto">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">SL</th>
                  <th scope="col">Name</th>
                  <th scope="col">ID</th>
                  <th scope="col">City</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(owner, index) in ownerDataList" :key="index">
                  <td>{{ index+1 }}</td>
                  <td>{{ owner.name }}</td>
                  <td>{{ owner.id }}</td>
                  <td>{{ owner.city }}</td>
                  <td>{{ owner.phone }}</td>
                  <td>
                    <button class="btn viewBtn" @click="() => goToRestDashboard(owner.id, owner.name)">
                      View
                    </button>
                    <button v-if="userRole == 'Admin'" @click="() => goToReportPage(owner.id, owner.name)" class="btn reportBtn mx-2">
                      Report
                    </button>
                    <button v-if="userRole == 'Admin'" @click="() => openOwnerDeleteModal(owner.id)" class="btn deleteBtn">
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-vue">
      <!-- overlay -->
      <div class="overlay" v-if="showResDeleteModal" @click="showResDeleteModal = false"></div>

      <!-- modal -->
      <div class="modal-own" v-if="showResDeleteModal">
        <div class="modal-title">
          <h2 class="d-inline" style="color: rgb(254, 117, 15); margin: unset">Confirm Password</h2>
          <!-- <img src="/img/cross.png" class="float-right" alt="Image" title="Remove"> -->
          <i class="fa fa-times float-right mr-3" @click="showResDeleteModal=false" style="cursor: pointer; color: #fe750f; font-size: 2rem;"></i>

        </div>
        <div class="modal-body">
          <form>
            <div class="form-group" style="margin: 2rem 0">
              <label style="color: red" for="confPassword" v-if="passMisMatch">Password doesn't match </label>
              <input type="password" class="form-control" id="confPassword" v-model="confPassword" placeholder="Password">
            </div>
            <button type="button" @click="confirmPasswordBeforeDeleteOwner" class="btn submitBtn">Delete Owner</button>&nbsp;
            <img v-if="checkPasswordLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import TopNavbar from '../../layout/TopNavbar'
import moment from 'moment'
import logout from '../../mixins/logoutMixins.js'

export default {
  components: {
    TopNavbar
  },
  data () {
    return {
      ownerDataList: [],
      restaurantList: [],
      jwtToken: this.$cookies.get('token') || null,
      showResDeleteModal: false,
      confPassword: '',
      checkPasswordLoading: false,
      tempRestId: '',
      passMisMatch: false,
      userRole: this.$cookies.get('adminData').role
    }
  },
  created () {
    this.ownerList()
  },
  mounted () {
    // logout user if session expired
    if (localStorage.getItem('tempSessionManage')) {
      const loggedInTime = Number(localStorage.getItem('tempSessionManage'))
      const curTime = moment.now()
      const timeDiff = moment(curTime).diff(moment(loggedInTime), 'hours')
      if (timeDiff > 22) {
        this.logoutUser()
      }
    } else {
      this.logoutUser()
    }
  },
  mixins: [logout],
  methods: {
    // ...mapActions(['setCurrentRestaurant']),
    ...mapMutations(['updateCurrentRestaurant']),

    openOwnerDeleteModal (val) {
      this.showResDeleteModal = true
      this.tempRestId = val
    },
    confirmPasswordBeforeDeleteOwner () {
      this.checkPasswordLoading = true
      this.checkPassword()
    },
    checkPassword () {
      let url = process.env.VUE_APP_API_URL+'/api/user/validateAdminPassword'

        axios
        .post(url, {'password': this.confPassword},
        {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          }
        })
        .then((data)=> {
          this.passMisMatch = false
          this.deleteOwner()
        })
        .catch((error)=>{
          this.passMisMatch = true
          this.checkPasswordLoading = false
        })
    },
    deleteOwner() {
        let url = process.env.VUE_APP_API_URL+`/api/restaurant/delete?id=${this.tempRestId}`

        axios
        .get(url,
        {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          }
        })
        .then((data)=> {
          this.checkPasswordLoading = false
          this.tempRestId = ''
          this.showResDeleteModal = false
          this.$toastr.s("deleted Successfully", "Success");
          this.ownerList()

          if (data.status === 403) {
            let logoutResponse = this.logout()
            if (logoutResponse === 1) {
              this.$router.push({ path: '/login' })
            }
          }
        })
    },
    ownerList () {
        let url = process.env.VUE_APP_API_URL+'/api/restaurant/list'

        axios
        .get(url,
        {
            headers: {
                Authorization: `Bearer ${this.jwtToken}`,
            }
        })
        .then((data) => {
            this.ownerDataList = data.data
            this.restaurantList = data.data

            if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                }
            }
        })
    },
    goToRestDashboard (id, name) {
      localStorage.setItem('restaurantId', id)
      localStorage.setItem('restaurantName', name)
      this.updateCurrentRestaurant({id: id, name: name})
      this.$router.push('/admin/owner-dashboard')
    },
    goToReportPage (id, name) {
      localStorage.setItem('restaurantId', id)
      localStorage.setItem('restaurantName', name)
      this.updateCurrentRestaurant({id: id, name: name})
      this.$router.push('/admin/restaurant/report')
    },
    filterRestaurant (key, status) {
      this.ownerDataList = this.restaurantList.filter(owner => {
          if (status !== 'All' && key) {
            if (owner.status === status && owner.name.toLowerCase().includes(key.toLowerCase())) {
              return owner
            }
          } else if (status !== 'All' && key === '') {
            return owner.status === status
          } else if (status === 'All' && key) {
            return owner.name.toLowerCase().includes(key.toLowerCase())
          } else {
            return owner
          }
      })
    },
    logoutUser() {
      let logoutResponse = this.logout()
      if (logoutResponse === 1) {
        this.$router.push({ path: '/login' })
      }
    }
  }
}
</script>

<style scoped>
.table > thead > tr > th {
  color: black;
  font-size: 1rem;
  font-weight: bold;
}
.table > thead > tr > th:last-child, .table > tbody > tr > th:last-child, .table > tfoot > tr > th:last-child, .table > thead > tr > td:last-child, .table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
    width: auto;
}
table > tbody > tr > td {border-top: 1px solid #EF7029; border-bottom: 1px solid #EF7029;}
.viewBtn, .reportBtn{
  color: #000 !important;
  border-radius: 20px;
  padding: 0px 15px;
  border: 1px solid;
}
.deleteBtn{
  color: red !important;
  border-radius: 20px;
  padding: 0px 15px;
  border: 1px solid;
  /* margin-left: 5px; */
}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-title {
  padding: 10px;
  background-color: #FFEDE3;
  border-radius: 10px 10px 0 0;
  top: 10%;
  left: 35%;
}
.modal-vue .modal-own {
  position: fixed;
  width: 500px;
  z-index: 9999;
  margin: 0 auto;
  /* padding: 20px 30px; */
  background-color: #fff;
  border-radius: 10px;
  top: 15%;
  left: 35%;
}

.modal-vue .close{
  position: absolute;
  top: 10px;
  right: 10px;
}
.submitBtn{
  background-color: red;
  color: #fff;
  border: unset;
}
</style>

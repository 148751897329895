<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0" style="margin-bottom: 5rem;">
          <div class="header">
            <div class="row">
              <div class="col-md-6 col-6">
                <span class="headerText">Menu Management - Hook Items at Set</span>
              </div>
              <!-- <div class="col-md-6 col-6 pl-0">
                <span style="float: right;">
                  <button class="btn btn-sm ml-3" type="button" style="background: #FFF5FF !important; color: #FE7611; margin-top: 10%;" @click="goBack">Go Back</button>
                </span>
              </div> -->
            </div>
          </div>

          <div style="background-color: #FFFFFF; padding: 5% 31px 31px 31px;">
            <form>
              <h3>{{ itemLabel }}</h3>
              <div class="row">
                <div class="col-md-2 col-12">
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="baseQty" placeholder="Base quantity">
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="form-group" >
                    <select class="form-control" id="exampleFormControlSelect1" v-model="selectedCategory">
                      <option v-for="(category, index) in allCategories" :key="index" :value="category"> {{category.label}} </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="form-group" >
                    <select class="form-control" id="exampleFormControlSelect1" v-model="itemIdSelected">
                      <option v-for="(item, index) in selectedCategory.children" :key="index" :value="item"> {{item.label}} </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <ul class="list-group">
                    <li v-for="(item, index) in fixedItemSet" :key="index" class="list-group-item" style="cursor: pointer; border-style: none !important; background: #F5F5F5; margin-bottom: 13px;">
                      <span>
                        <div style="display: inline-block; border: 1px solid #FFD8BC; border-radius: 0px 5px; padding: 2px; color: #FE750F;">{{ item.baseQty }}</div>
                        &nbsp;
                        <div style="display: inline-block;">{{ item.label }}</div>
                        <i class="fa fa-chevron-down" style="float: right; color: black; display: block;" :id="'itemShowOption'+item.setItemId" @click="() => itemExpands(item.setItemId)"></i>
                        <i class="fa fa-chevron-up" style="float: right; color: black; display: none;" :id="'itemHideOption'+item.setItemId" @click="() => itemExpands(item.setItemId)"></i>

                        <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'itemOption'+item.setItemId">
                          <span> <button type="button" class="btn-sm deleteBtn" @click="() => deleteSetItem(item.setItemId)"><i class="fa fa-trash"></i> Delete</button> </span>
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import logout from './../../../mixins/logoutMixins.js'

export default {
  props: ['categoryId', 'itemId', 'itemLabel', 'itemDataList'],
  data () {
    return {
      restaurantId: parseInt(localStorage.getItem('restaurantId')),
      itemIdSelected: '',
      fixedItemSet: [],
      allCategories: [],
      selectedCategory: [],
      baseQty: '',
      jwtToken: this.$cookies.get('token') || null
    }
  },
  created () {
    this.assignedFixedItemSet()
    this.getCategorisedItems()
  },
  methods: {
    chooseItems (item) {
      for (var i = 0; i < this.fixedItemSet.length; i++) {
        if (this.fixedItemSet[i].label.toLowerCase() === item.label.toLowerCase()) {
          this.$toastr.e('Already added', 'Warning')
          return
        }
      }
      let url = process.env.VUE_APP_API_URL+`/api/menu/create`

      // request header
      let requestHeader = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.jwtToken}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }

      let dataToSubmit = {
          "restaurantId": this.restaurantId,
          "label": item.label,
          "resourceRef": "",
          "refCode": 0,
          "imgURL": "",
          "iconURL": "",
          "description": "",
          "categoryId": this.categoryId,
          "itemId": 0,
          "selectionId": 0,
          "toppingsId": 0,
          "modifierId": 0,
          "mealDealId": this.itemId,
          "setId": 0,
          "setItemId": parseInt(item.id),
          "printOptionId": 1,
          "printOptionTitle": "Starter",
          "terminalOption": "",
          "status": "Active",
          "basePrice": "15.00",
          "toppingsCategory": "",
          "mealAssignFor": "Kitchen",
          "toppingsCategoryId": 0,
          "baseQty": this.baseQty,
          "minQty": 0,
          "maxQty": 0,
          "dataType": "SetItem",
          "mealDealType": "Fixed",
          "displayOrder": 0,
          "createdAt": "3 Sep 2020 11:05:30",
          "updatedAt": "30 Sep 2020 11:05:30",
          "children": []
      }

      axios
      .post(url, dataToSubmit,
      {
          headers: requestHeader
      })
      .then((data)=> {
        if (data.status == 200) {
          this.$toastr.s("Created Successfully", "Success");
          this.itemIdSelected = ''
        }
        this.assignedFixedItemSet()
      })
    },
    assignedFixedItemSet() {
        let url = process.env.VUE_APP_API_URL+`/api/menu/item?restaurantId=${this.restaurantId}&id=${this.itemId}`

        // request header
        let requestHeader = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.jwtToken}`
        }

        axios
        .get(url,
        {
            headers: requestHeader
        })
        .then((data)=> {
            if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                }
            }

            this.fixedItemSet = []

            // this.fixedItemSet = data.data

            for (var i = 0; i < data.data.children.length; i++) {
                let obj = {
                    'setId': data.data.children[i].setId,
                    'setItemId': data.data.children[i].setItemId,
                    'label': data.data.children[i].label,
                    'baseQty': data.data.children[i].baseQty
                }
                this.fixedItemSet.push(obj)
            }
        })
    },
    getCategorisedItems() {
      let url = process.env.VUE_APP_API_URL+`/api/menu/categorizedItems?restaurantId=${this.restaurantId}`

      // request header
      let requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`
      }

      axios
      .get(url,
      {
        headers: requestHeader
      })
      .then((data)=> {
        this.allCategories = data.data.sort((a, b) => a.label.localeCompare(b.label))
      })
    },
    itemExpands (item) {
        let idShow = 'itemShowOption'+item
        let idHide = 'itemHideOption'+item
        let idOption = 'itemOption'+item

        if (this.isItemExpanded) {
            let x = document.getElementById(idShow)
            let y = document.getElementById(idHide)
            let z = document.getElementById(idOption)

            x.style.display = "block"
            y.style.display = "none"
            z.style.display = "none"

            this.isItemExpanded = !this.isItemExpanded
        }
        else {
            let x = document.getElementById( idShow)
            let y = document.getElementById(idHide)
            let z = document.getElementById(idOption)

            x.style.display = "none"
            y.style.display = "block"
            z.style.display = "block"

            this.isItemExpanded = !this.isItemExpanded
        }
    },
    deleteSetItem (val) {
        let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

        // request header
        let requestHeader = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.jwtToken}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": "*",
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }

        let dataToSubmit = {
            "id": val,
            "restaurantId": this.restaurantId,
            "printOptionTitle": "Starter",
            "toppingsCategoryId": 0,
            "terminalOption": "",
            "modifierId": 0,
            "displayOrder": 0,
            "description": "",
            "baseQty": 1,
            "printOptionId": 1,
            "createdAt": "3 Sep 2020 11:05:30",
            "mealAssignFor": "Kitchen",
            "children": [],
            // "setId": this.idOfSetItem,
            // "setItemId": 4109,
            "setTitle": "",
            "iconURL": "",
            "toppingsId": 0,
            "basePrice": "",
            "updatedAt": "30 Sep 2020 11:05:30",
            "selectionId": 0,
            "dataType": "SetItem",
            "label": "",
            "resourceRef": "",
            "imgURL": "",
            "itemId": 0,
            "mealDealId": this.itemId,
            "toppingsCategory": "",
            "maxQty": 0,
            "mealDealType": "Fixed",
            "refCode": 0,
            "categoryId": this.categoryId,
            "status": "Active",
            "minQty": 0,
            "total": ""
        }

        if (confirm('Are you sure to delete this set item?')) {
            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                    this.$toastr.s("Deleted Successfully", "Success");
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.assignedFixedItemSet()
            })
        }
    },
    goBack () {
      this.$emit('goBack')
    },
  },
  watch: {
    itemIdSelected (item) {
      if (this.baseQty == '') {
        this.$toastr.w("Please enter base quantity first!", "Warning");
        return false;
      }
      this.chooseItems (item)
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 69px;
  background: #FFEDE3;
  border: 1px solid #FFD8BC;
  box-sizing: border-box;
  border-radius: 0px;
}

.headerText {
  position: absolute;
  width: 400px;
  height: 19px;
  left: 28px;
  top: 20px;
  color: #FE750F;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.deleteBtn {
  background-color: #FF646F;
  border: 1px solid #FF646F;
  color: white;
}
.requiredCls {
  color: red;
}
</style>

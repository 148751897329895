<template>
  <div id="chart">
    <apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
export default {
  props: ['series'],
  data() {
    return {
      chartOptions: {
        chart: {
          width: '100%',
          type: 'pie'
        },
        // colors: ['#A5978B', '#2b908f', '#33b2df', '#90ee7e', '#13d8aa'],
        labels: ['Table', 'Waiting', 'Collection', 'Delivery', 'Express'],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -2
            }
          }
        },
        title: {
          text: "Order Types (All Time)"
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1)+'%']
          }
        },
        legend: {
          show: true
        }
      },

    }
  },
}
</script>

<template>
  <div id="chart">
    <apexchart type="bar" height="400" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
export default {
  props: ['series'],
  data() {
    return {
      chartOptions: {
        chart: {
          height: 400,
          type: 'bar',
          stacked: true,
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7', '#33b2df', '#546E7A'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'bottom',
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }

        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        },
        title: {
          text: 'All Orders (Monthly)',
          // margin: 10,
          floating: false,
          // offsetY: 385,
          align: 'left',
          style: {
            color: '#444'
          }
        }
      },
    }
  },
}
</script>

<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0">
          <top-navbar :title="restaurantName" search="false"></top-navbar>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-2">
          <button @click="() => filterOrders('Collection', true, false)" class="btn mb-3 mx-1" style="background-color: #fff;opacity: 1 !important; color: #000; border: unset">Collection</button>
          <button @click="() => filterOrders('Delivery', true, false)" class="btn mb-3 mx-1" style="background-color: #fff;opacity: 1 !important; color: #000; border: unset; padding: 8px">Delivery</button>
          <button @click="() => filterOrders('Table', true, false)" class="btn orderTypeBtn mb-3 mx-1">Table</button>
          <button @click="() => filterOrders('Waiting', true, false)" class="btn orderTypeBtn mb-3 mx-1">Waiting</button>
          <button @click="() => filterOrders('Express', true, false)" class="btn orderTypeBtn mb-3 ml-1">Express</button>
          <br>
          <button @click="() => filterOrders('Cash', false, true)" class="btn paymentTypeBtn mb-3 mx-1">Cash</button>
          <button @click="() => filterOrders('Card', false, true)" class="btn paymentTypeBtn mb-3 mx-1">Card</button>

          <button @click="() => filterOrders('online')" class="btn orderHistoryBtn mb-3">Online Order</button>

          <div class="row mb-3 mx-0" style="background-color: #fff; text-align: center; border-radius: 2px">
            <div class="col-md-6 col-sm-12 px-0">
              <label>From</label>
              <datepicker v-model="startDate" format="d/MM/yyyy" placeholder="Select Date"></datepicker>
            </div>
            <div class="col-md-6 col-sm-12 px-0">
              <label>To</label>
              <datepicker v-model="endDate" format="d/MM/yyyy" placeholder="Select Date"></datepicker>
            </div>
          </div>
          <button @click="getOrderHistory" class="btn applyBtn">Apply Filter</button>
          <sidebar-link to="/admin/restaurant/booking/history" class="dashboard-link my-3">
            <button class="btn orderHistoryBtn">Booking History</button>
          </sidebar-link>
          <sidebar-link to="/admin/restaurant/report" class="dashboard-link">
            <button class="btn orderHistoryBtn">Back to Report</button>
          </sidebar-link>
        </div>
        <div class="col-md-10" style="background-color: white">
          <h3>All Orders</h3>
          <b-table
            :items="sortedOrders"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            responsive
            striped
            bordered
            show-empty
          >
            <template #cell(total)="row">
              £{{ row.item.total }}
            </template>
            <template #cell(view)="row">
              <a size="sm" @click="() => viewOrderDetails(row.item.id)" class="">View</a>
            </template>
          </b-table>

          <b-pagination
            v-if="sortedOrders.length > 0"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
          <div class="row orderBtm">
            <div class="col md-6">
              Total order: {{ sortedOrders.length }}
            </div>
            <div class="col md-6">
              Total amount: £{{ totalAmount.toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-vue">
      <!-- overlay -->
      <div class="overlay" v-if="showOrderDetailsModal" @click="showOrderDetailsModal = false"></div>

      <!-- modal -->
      <div class="modal-own" v-if="showOrderDetailsModal">
        <div class="modal-title">
          <h2 class="d-inline" style="color: rgb(254, 117, 15); margin: unset">Order Details</h2>
          <i class="fa fa-times float-right mr-3" @click="showOrderDetailsModal=false" style="cursor: pointer; color: #fe750f; font-size: 2rem;"></i>

        </div>
        <div class="modal-body">
          <table class="table rest-details" style="background: #f2f2f2; padding: 1px;">
            <tbody>
              <tr style="background: #fff;">
                <td class="td-first">Ref</td>
                <td class="td-second">{{ orderDetails.id }}</td>
              </tr>
              <tr style="background: #fff;">
                <td class="td-first">Order Type</td>
                <td class="td-second" style="text-transform:capitalize">{{ orderDetails.orderType }}</td>
              </tr>
              <tr style="background: #fff;">
                <td class="td-first">Restaurant Name</td>
                <td class="td-second">{{ restaurantName }}</td>
              </tr>
              <!-- <tr style="background: #fff;">
                <td class="td-first">Restaurant Phone</td>
                <td class="td-second">{{ webConfig.phone }}</td>
              </tr>
              <tr style="background: #fff;">
                <td class="td-first">Address</td>
                <td class="td-second">{{ formattedAddress }}</td>
              </tr> -->
              <tr style="background: #fff;">
                <td class="td-first">Order Date</td>
                <td class="td-second">{{ orderDetails.orderDate }}</td>
              </tr>

              <tr style="background: #fff;">
                <td class="td-first">Order Status</td>
                <td class="td-second"><span style="color: #009e12">{{ orderDetails.orderStatus }}</span>&nbsp;[{{ orderDetails.requestedTime }}]</td>
              </tr>
              <tr style="background: #fff;">
                <td class="td-first">Payment</td>
                <td class="td-second">{{ orderDetails.paymentType }}</td>
              </tr>
            </tbody>
          </table>

          <table class="table cart-details" style="background: #f2f2f2; padding: 1px;">
            <tbody>
              <tr style="background: none; text-align: center;">
                <th style="width: 60%">Item</th>
                <th style="width: 10%">U.P</th>
                <th style="width: 7%">Qty</th>
                <th style="width: 23%; text-align: right; padding-right: 3px;">Price</th>
              </tr>

              <tr
                v-for="(item, index) in orderDetails.cart"
                :key="index"
                style="background: #fff;">
                <td style="width: 60%;text-align: left;padding-left: 3px; ">
                    {{ item.label }}
                </td>
                <td style="width: 10%; text-align: center;">£{{ item.price }}</td>
                <td style="width: 7%; text-align: center;">{{ item.qty }}</td>
                <td style="width: 23%; text-align: right; padding-right: 3px;">£{{ Math.round(item.price * item.qty * 100) / 100 }}</td>
              </tr>

              <tr style="background: #b3feb9;">
                <td colspan="3" style="text-align: right;padding-right: 3px; font-weight: bold;">Subtotal</td>
                <td colspan="1" style="text-align: right; padding-right: 3px;font-weight: bold;">£{{ Math.round(orderDetails.subTotal * 100) / 100 }}</td>
              </tr>
              <tr v-if="orderDetails.hasBagFee === 'Yes'" style="background: #b3feb9;">
                <td colspan="3" style="text-align: right;padding-right: 3px; font-weight: bold;">Bag fee</td>
                <td colspan="1" style="text-align: right; padding-right: 3px;font-weight: bold;">£{{ Math.round(orderDetails.bagFeeAmount * 100) / 100 }}</td>
              </tr>
              <tr v-if="orderDetails.serviceCharge > 0" style="background: #b3feb9;">
                <td colspan="3" style="text-align: right;padding-right: 3px; font-weight: bold">Service Charge</td>
                <td colspan="1" style="text-align: right; padding-right: 3px; font-weight: bold">£{{ Math.round(orderDetails.serviceCharge * 100) / 100 }}</td>
              </tr>
              <tr v-if="orderDetails.deliveryCharge > 0" style="background: #b3feb9;">
                <td colspan="3" style="text-align: right;padding-right: 3px; font-weight: bold">Delivery Charge</td>
                <td colspan="1" style="text-align: right; padding-right: 3px; font-weight: bold">£{{ Math.round(orderDetails.deliveryCharge * 100) / 100 }}</td>
              </tr>
              <tr v-if="orderDetails.discount > 0" style="background: #b3feb9;">
                <td colspan="3" style="text-align: right;padding-right: 3px; font-weight: bold">Discount</td>
                <td colspan="1" style="text-align: right; padding-right: 3px; font-weight: bold">£{{ Math.round(orderDetails.discount * 100) / 100 }}</td>
              </tr>
              <tr v-if="orderDetails.promoAmount > 0" style="background: #b3feb9;">
                <td colspan="3" style="text-align: right;padding-right: 3px; font-weight: bold">Promo disc</td>
                <td colspan="1" style="text-align: right; padding-right: 3px; font-weight: bold">£{{ Math.round(orderDetails.promoAmount * 100) / 100 }}</td>
              </tr>
              <tr style="background: #b3feb9;">
                <td colspan="3" style="text-align: right;padding-right: 3px; font-weight: bold">Total</td>
                <td colspan="1" style="text-align: right; padding-right: 3px; font-weight: bold">£{{ Math.round(orderDetails.grandTotal * 100) / 100 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="showFilterLoading" class="overlay">
      <img src="/img/loading.gif" alt="GIF" width="30" height="30" class="mx-auto d-block" style="margin-top: 20%">
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

import TopNavbar from '../../../layout/TopNavbar'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"

export default {
  components: {
    TopNavbar,
    Datepicker
  },
  data () {
    return {
      restaurantName: localStorage.getItem('restaurantName'),
      jwtToken: this.$cookies.get('token') || null,
      startDate: new Date(),
      endDate: new Date(),
      allOrders: [],
      sortedOrders: [],
      orderDetails: {},
      showFilterLoading: false,
      perPage: 10,
      currentPage: 1,
      fields: ['name', 'phone', 'area', 'id', 'type', 'date', 'time', 'payment', 'total', 'status', 'view'],
      totalAmount: 0,
      showOrderDetailsModal: false
    }
  },
  mounted () {
    this.getOrderHistory()
  },
  computed: {
    rows() {
      return this.sortedOrders.length
    }
  },
  methods: {
    getOrderHistory () {
      this.showFilterLoading = true
      const sDate = this.startDate != '' ? moment(this.startDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
      const eDate = this.endDate != '' ? moment(this.endDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')

      let url = process.env.VUE_APP_API_URL+'/api/order/v2/filterByDate?restaurantId='
        + localStorage.getItem('restaurantId')
        + '&startDate=' + sDate
        + '&endDate=' + eDate
        + '&currentTime=' + moment().format('h:m A')

      // send request
      axios
        .get(url,
          {
            headers: { Authorization: `Bearer ${this.jwtToken}` }
          })
        .then((data) => {
          this.allOrders = []
          this.totalAmount = 0
          data.data.forEach(item => {
            let area = ''
            if (item.data.orderInfo.orderType && item.data.orderInfo.orderType == 'Delivery') {
              if (item.data.orderInfo.doorNo && item.data.orderInfo.doorNo != '') {
                area += item.data.orderInfo.doorNo+', '
              }
              if (item.data.orderInfo.building && item.data.orderInfo.building != '') {
                area += item.data.orderInfo.building+', '
              }
              if (item.data.orderInfo.street && item.data.orderInfo.street != '') {
                area += item.data.orderInfo.street
                area += item.data.orderInfo.city ? ', ' : ''
              }
              if (item.data.orderInfo.city && item.data.orderInfo.city != '') {
                area += item.data.orderInfo.city
              }
            }
            this.allOrders.push(
              {
                name: item.data.orderInfo.name,
                phone: item.data.orderInfo.phone,
                area,
                id: item.id,
                type: item.data.orderInfo.orderType,
                date: item.data.orderInfo.date,
                time: item.data.orderInfo.time,
                payment: item.data.payment ? item.data.payment[0].type : '',
                total: Number(item.data.grandTotal).toFixed(2),
                status: item.status,
                statusOnline: item.data.statusOnline || 'No'
              }
            )

            this.totalAmount += Number(Number(item.data.grandTotal).toFixed(2))
          });
          this.sortedOrders = this.allOrders
          this.showFilterLoading = false
        })
        .catch((error)=>{
          this.showFilterLoading = false
        })
    },
    viewOrderDetails (id) {
      let url = process.env.VUE_APP_API_URL+'/api/order/find?id=' + id

      // send request
      axios
        .get(url,
          {
            headers: { Authorization: `Bearer ${this.jwtToken}` }
          })
        .then((data) => {
          const order = data.data
          this.orderDetails.id = id
          this.orderDetails.cart = order.data.cart
          this.orderDetails.orderType = order.data.orderInfo.orderType
          this.orderDetails.orderStatus = order.status
          this.orderDetails.orderDate = order.data.orderInfo.date + ', ' + order.data.orderInfo.time
          this.orderDetails.requestedTime = order.data.orderInfo.requestedTime
          this.orderDetails.paymentType = order.data.payment[0].type
          this.orderDetails.subTotal = order.data.subTotal
          this.orderDetails.hasBagFee = order.data.bagFee
          this.orderDetails.bagFeeAmount = order.data.bagFeeAmount ? Number(order.data.bagFeeAmount) : 0
          this.orderDetails.discount = order.data.discount
          this.orderDetails.deliveryCharge = order.data.deliveryCharge
          this.orderDetails.serviceCharge = order.data.serviceCharge
          this.orderDetails.grandTotal = order.data.grandTotal
          this.orderDetails.promoAmount = Number(order.data.promo)
          this.orderDetails.promoCode = order.data.promoCode

          this.showOrderDetailsModal = true
        })
        .catch((error)=>{})
    },
    filterOrders (value, orderType=false, paymentType=false) {
      this.showFilterLoading = true
      this.totalAmount = 0
      this.sortedOrders = this.allOrders.filter(order => {
        if (orderType) {
          if (order.type === value) {
            this.totalAmount += Number(Number(order.total).toFixed(2))
            return order;
          }
        } else if (paymentType) {
          if (order.payment === value) {
            this.totalAmount += Number(Number(order.total).toFixed(2))
            return order;
          }
        } else {
          if (order.statusOnline === 'Yes') {
            this.totalAmount += Number(Number(order.total).toFixed(2))
            return order;
          }
        }
      })
      this.showFilterLoading = false
    }
  }
}
</script>
<style scoped>
.dashboard-link{list-style-type: none;}
/* .content{padding: 10px 0;} */
.orderTypeBtn, .orderHistoryBtn, .paymentTypeBtn{
  background-color: #fff;
  color: #000;
  opacity: 1 !important;
  border: unset;
}
.orderTypeBtn{padding: 8px 3px;}
.orderHistoryBtn{width: 100%}
.paymentTypeBtn{width: 40%}
.applyBtn{
  background-color: green;
  color: #fff;
  width: 100%;
  opacity: 1 !important;
}
.orderBtm{
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
  padding: 10px;
  color: #fff;
}

.overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-title {
  padding: 10px;
  background-color: #FFEDE3;
  border-radius: 10px 10px 0 0;
  top: 10%;
  left: 35%;
}
.modal-vue .modal-own {
  position: absolute;
  width: 60%;
  z-index: 9999;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  top: 10%;
  left: 20%;
}

.modal-vue .close{
  position: absolute;
  top: 10px;
  right: 10px;
}
.table{
  font-size: 17px!important;
  color: black!important;
  width: 100%;
  background: #f2f2f2;
  padding: 1px;
}
.table td {
  padding: 4px 8px;
  text-align: left;
  border: 2px solid #e1e1e1;
}
.rest-details .td-first{
  width: 50%; text-align: right; padding-right: 10px;
}
.rest-details .td-second{
  width: 50%; text-align: left; padding-left: 10px;
}
</style>

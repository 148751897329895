<template>
  <div class="head-bg">
    <div class="row w-100 mx-0">
      <div class="col-md-6 col-6" style="padding-left: 5px">
        <sidebar-link to="/admin/dashboard" class="dashboard-link">
          <img src="/img/logo3.png" alt="Logo">
        </sidebar-link>
        <p v-if="page" class="d-inline-block mb-0">
          <span class="pl-3">Menu</span> | <span>{{ page }}</span>
        </p>
      </div>
      <div :class="search == 'true' ? 'col-md-2 col-6 text-center' : 'col-md-6 col-6 text-right'" style="padding-right: 0">
        <p class="mb-0 d-inline-block pr-2" style="padding-top: .8rem;">{{ title }}</p>
        <button v-if="isGoBackEnabled" class="btn btn-sm ml-3 goBackBtn" type="button" @click="goBack">Back</button>
      </div>
      <div v-if="search == 'true'" class="col-md-3 col-12">
        <div class="row mx-0">
          <div class="col-md-4 col-4 px-0 mt-2">
            <select v-model="activeInactiveFilter" @change="searchSubmit" class="activeInactive">
              <option value="All" selected>All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div class="col-md-8 col-8 px-0">
            <input type="search" v-model="searchKey" @keyup="searchSubmit" class="searchBtn" placeholder="Search">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarLink from '../components/SidebarPlugin/SidebarLink.vue'

export default {
  components: { SidebarLink },
  props: ['title', 'page', 'search', 'isGoBackEnabled', 'goBackTitle'],
  computed: {
    routeName () {
      const {name} = this.$route
      return this.capitalizeFirstLetter(name)
    }
  },
  data () {
    return {
      activeNotifications: false,
      searchKey: '',
      activeInactiveFilter: 'All'
    }
  },
  emits: ["filterRestaurant"],
  created () {
    this.$root.$on('enableBackBtn', (data) => {
      this.isGoBackEnabled = data
    })
  },
  methods: {
    logoutUser() {
      let logoutResponse = this.logout()

      if (logoutResponse === 1) {
        this.$router.push({ path: '/login' })
      }
    },
    searchSubmit () {
      clearTimeout(this.filterTimeout)
      const self = this
      this.filterTimeout = setTimeout(function () {
        self.$emit('filterRestaurant', self.searchKey, self.activeInactiveFilter)
      }, 500)
    },
    goBack () {
      if (this.goBackTitle == 'webTopping') {
        this.$root.$emit('backToToppingList')
      } else if (this.goBackTitle == 'customTopping') {
        this.$root.$emit('backToCustomToppingList')
      } else {
        this.$emit('goBack')
      }
    },
  }
}

</script>
<style>
.dashboard-link > .nav-link{padding: unset !important}
.activeInactive{
  color: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
  width: 100px;
  padding: .3rem .5rem;
  background: transparent;
}
.activeInactive option{color: #000}
.searchBtn{
  border: 1px solid #fff;
  border-radius: 15px;
  margin-top: .5rem;
  /* width: 250px; */
  background: transparent;
  /* margin-left: 1rem; */
  padding: .2rem 1.5rem;
  /* color: #000; */
}
</style>
<style scoped>
.row{margin: 0 !important;}
.dashboard-link{list-style-type: none; display: inline-block;}
.dashboard-link img{
  width: 55px;
  height: 45px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  margin: 2px 0;
}
.goBackBtn{
  background: transparent;
  color: #FFF;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid #fff;
  /* margin-top: 10%; */
}
</style>

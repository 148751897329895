import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
    strict: true,
    state: {
        baseUrl: '/api',
        isLoading: false,
    },
    getters: {},
    mutations: {
      updateCurrentRestaurant(state, data) {
        state.restaurantId = data.id
        state.restaurantName = data.name
      }
    },
    actions: {
      setCurrentRestaurant({state, commit}, {id, name}) {
        state.commit('updateCurrentRestaurant', data)
      }
    }
})

<template>
    <div class="row" style="padding-left: 32px; padding-right: 32px;">
        <div class="col-md-12">
            <h4>Subscription Info</h4>
            <hr class="hrCls">

            <br>

            <form>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Subcription Status</label>
                            <select class="form-control" id="exampleFormControlSelect2" v-model="subscriptionStatus">
                                <option v-for="(item, index) in subscriptionType" :key="index" :value="item"> {{item}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Subcription Start Date</label>
                            <datepicker v-model="subcriptionStartDate" placeholder="Subcription End Date"></datepicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Subcription End Date</label>
                            <datepicker v-model="subcriptionEndDate" placeholder="Subcription End Date"></datepicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn" style="background: #E317E3; color: white; border-color: #E317E3; margin-top: 7%;" @click="createSubscription">Submit</button>
                    </div>
                </div>
            </form>


            <h4>Add Payment</h4>
            <hr class="hrCls">

            <br>

            <form>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Payment Amount</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" v-model="paymentAmount" aria-describedby="emailHelp" placeholder="Subcription Status">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Payment Date</label>
                            <datepicker v-model="paymentDate" placeholder="Payment Date"></datepicker>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Payment Type</label>
                            <select class="form-control" id="exampleFormControlSelect2" v-model="paymentTypeSelected">
                                <option v-for="(item, index) in paymentType" :key="index" :value="item"> {{item}} </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn" style="background: #E317E3; color: white; border-color: #E317E3; margin-bottom: 2%;" @click="createPayment">Submit</button>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";

export default {
    props: ['restaurantSubscription', 'restaurantId'],
    components: {
        Datepicker
    },
    data () {
        return {
            subscriptionStatus: '',
            subcriptionStartDate: '',
            subcriptionEndDate: '',
            paymentDate: '',
            subscriptionType: [
                'Active', 'Inactive', 'Due', 'Suspended', 'Terminated', 'Pending'
            ],
            paymentType: [
                'Card', 'Cash'
            ],
            paymentTypeSelected: '',
            paymentAmount: 0,
            isEdit: false,
            editId: 0,
        }
    },
    created () {
        this.getSubscriptionInfo ()
    },
    methods: {
        getSubscriptionInfo () {
            let url = process.env.VUE_APP_API_URL+`/api/subscription?restaurantId=${this.restaurantId}`

            axios
            .post(url,
            {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                }
            })
            .then((data) => {
                if (data.data.length > 0) {
                    let starting = data.data[0].startDate.split('/')
                    let ending = data.data[0].endDate.split('/')

                    this.editId = data.data[0].id
                    this.subscriptionStatus = data.data[0].type
                    this.subcriptionStartDate = starting[1]+'/'+starting[0]+'/'+starting[2]
                    this.subcriptionEndDate = ending[1]+'/'+ending[0]+'/'+ending[2]
                    this.isEdit = true
                }
                else {
                    this.editId = 0
                    this.subscriptionStatus = ''
                    this.subcriptionStartDate = ''
                    this.subcriptionEndDate = ''
                    this.isEdit = false
                }

            })
        },
        createSubscription () {
            // start date
            let startDateObj = new Date(this.subcriptionStartDate)
            let startMonth = startDateObj.getMonth()+1
            let startDate = startDateObj.getDate()+'/'+startMonth+'/'+startDateObj.getFullYear()

            // end date
            let dateObj = new Date(this.subcriptionEndDate)
            let month = dateObj.getMonth()+1

            let date = dateObj.getDate()+'/'+month+'/'+dateObj.getFullYear()


            let url = ''
            let dataToSubmit = {}

            if (this.isEdit) {
                // request url
                url = process.env.VUE_APP_API_URL+'/api/subscription/update'

                // request data
                dataToSubmit = {
                    "id": this.editId,
                    "restaurantId": this.restaurantId,
                    "type": this.subscriptionStatus,
                    "startDate": startDate,
                    "endDate": date
                }
            }
            else {
                // request url
                url = process.env.VUE_APP_API_URL+'/api/subscription/create'

                // request data
                dataToSubmit = {
                    "restaurantId": this.restaurantId,
                    "type": this.subscriptionStatus,
                    "startDate": startDate,
                    "endDate": date
                }
            }



            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.VUE_APP_TOKEN}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            // send request
            axios
            .post(url, dataToSubmit, {
                headers: requestHeader
            })
            .then((data) => {
                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");
                }
            })
        },
        createPayment () {
            // payment date
            let dateObj = new Date(this.paymentDate)
            let month = dateObj.getMonth()+1
            let date = dateObj.getDate()+'/'+month+'/'+dateObj.getFullYear()

            // request url
            let url = process.env.VUE_APP_API_URL+'/api/payment/create'

            // request data
            let dataToSubmit = {
                "restaurantId": this.restaurantId,
                "createdAt": "",
                "paymentAmount": this.paymentAmount,
                "paymentDate": date,
                "paymentTime": "10:00",
                "paymentType": this.paymentTypeSelected
            }

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.VUE_APP_TOKEN}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            // send request
            axios
            .post(url, dataToSubmit, {
                headers: requestHeader
            })
            .then((data) => {
                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");
                }
            })
        },
    }
}
</script>

<style scoped>
.hrCls {
    width: 165px;
    height: 1px;
    float: left;
    margin: 0px;

    background: #8F8F8F;
    border-radius: 6px;
}
</style>

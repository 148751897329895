<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
      data () {
          return {

          }
      },
      created () {
          this.checkIsLogin()
      },
      methods: {
          checkIsLogin () {
              if ((localStorage.getItem('isLoggedIn') !== 'true') || (this.$cookies.get('token') === null) || (this.$cookies.get('token') === 'undefined')) {
                  this.$router.push({ path: '/login' })
              }
          }
      }
  }
</script>
<style lang="scss">
  .content{background-color: #fff}
  .vue-notifyjs.notifications{
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }
    .list-item {
      display: inline-block;
      margin-right: 10px;

    }
    .list-enter-active {
      transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }
    .list-leave-active {
      transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);

    }
    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }
</style>

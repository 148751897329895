<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0">
          <top-navbar title="Business Requests"></top-navbar>

          <div style="background-color: #FFFFFF; padding: 2% 15px 31px 15px; overflow-x: auto">
            <button @click="() => businessRequestList('Completed')" class="btnComplete float-right mx-2">
              Completed
            </button>
            <button @click="() => businessRequestList('InProgress')" class="btnInprogress float-right mx-2">
              In progress
            </button>
            <button @click="() => businessRequestList('Pending')" class="btnPending float-right mx-2">
              Pending
            </button>
            <h3 class="float-left font-weight-bold">Requests</h3>
            <table class="table table-striped table-bordered" style="margin-top: 4rem;">
              <thead>
                <tr>
                  <th scope="col">SL</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Business</th>
                  <th scope="col">Address</th>
                  <th scope="col">Postcode</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(owner, index) in ownerDataList" :key="index">
                  <td>{{ index+1 }}</td>
                  <td>{{ owner.name }}</td>
                  <td>{{ owner.phone }}</td>
                  <td>{{ owner.email }}</td>
                  <td>{{ owner.businessName }}</td>
                  <td>{{ owner.address }}</td>
                  <td>{{ owner.postCode }}</td>
                  <td :style="{color: listTitle == 'Completed' ? 'green' : listTitle == 'In progress' ? 'orange' : 'red'}" style="font-weight: bold;">{{ listTitle }}</td>
                  <td class="text-center">
                    <sidebar-link :to="'/admin/enquiries/details?id='+owner.id" style="list-style-type: none;">
                      <button class="btn viewBtn">
                        View
                      </button>
                    </sidebar-link>
                    <button @click="() => deleteBusinessRequest(owner.id)" class="btn deleteBtn">
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import TopNavbar from '../../layout/TopNavbar'
import logout from '../../mixins/logoutMixins.js'

export default {
  components: {
    TopNavbar
  },
  data () {
    return {
      ownerDataList: [],
      listTitle: "Pending",
      jwtToken: this.$cookies.get('token') || null,
    }
  },
  created () {
    this.businessRequestList('Pending')
  },
  mounted () {
    // logout user if session expired
    if (localStorage.getItem('tempSessionManage')) {
      const loggedInTime = Number(localStorage.getItem('tempSessionManage'))
      const curTime = moment.now()
      const timeDiff = moment(curTime).diff(moment(loggedInTime), 'hours')
      if (timeDiff > 22) {
        this.logoutUser()
      }
    } else {
      this.logoutUser()
    }
  },
  mixins: [logout],
  methods: {
    businessRequestList (title) {
      let url = process.env.VUE_APP_API_URL+'/api/restaurantRequest/findByStatus?status='+title

      axios
      .get(url,
      {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        }
      })
      .then((data) => {
        this.ownerDataList = data.data
        this.listTitle = title

        if (data.status === 403) {
          let logoutResponse = this.logout()
          if (logoutResponse === 1) {
            this.$router.push({ path: '/login' })
          }
        }
      })
    },
    deleteBusinessRequest (id) {
      if (confirm('Please confirm to delete.')){
        let url = process.env.VUE_APP_API_URL+'/api/restaurantRequest/delete?id='+id

        axios
        .get(url,
        {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          }
        })
        .then((data) => {
          this.$toastr.s("Deleted Successfully", "Success");
          this.businessRequestList('Pending')

          if (data.status === 403) {
            let logoutResponse = this.logout()
            if (logoutResponse === 1) {
              this.$router.push({ path: '/login' })
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.btnPending, .btnInprogress, .btnComplete{
  color: #fff;
  border: unset;
  border-radius: .25rem;
  font-weight: bold;
  padding: 8px 16px;
}
.btnPending{
  background-color: #F4522D;
}
.btnInprogress{
  background-color: #FF9500;
}
.btnComplete{
  background-color: #57830d;
}
.viewBtn{
  color: #000 !important;
  border-radius: 20px;
  padding: 2px 15px;
  border: 1px solid;
}
.deleteBtn{
  color: red !important;
  border-radius: 20px;
  padding: 2px 15px;
  border: 1px solid red;
}
</style>

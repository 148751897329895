<template>
    <div class="content py-0">
        <div class="container">
            <div class="row" style="margin-bottom: 5% !important;">
                <div class="col-md-12 px-0">
                    <div class="header">
                      <div class="row">
                        <div class="col-md-6 col-6">
                          <span class="headerText">
                            Toppings Management
                          </span>
                        </div>
                        <div class="col-md-6 col-6 pl-0">
                          <span style="float: right;">
                            <button type="button" class="btn headerBtnStyle" @click="addToppings">Add New Toppings</button>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="toppingsTable" style="background-color: #FFFFFF; padding: 2% 31px 31px 31px;">
                        <table class="table table-bordered">
                            <thead>
                                <tr style="font-weight: bold; color: blue">
                                  <th>Toppings Category</th>
                                  <th>Toppings Title</th>
                                  <th>Base Price</th>
                                  <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in toppingsDataList" :key="index">
                                    <td>{{item.toppingsCategory}}</td>
                                    <td>{{item.label}}</td>
                                    <td>{{item.basePrice}}</td>
                                    <td><i class="fa fa-edit" @click="() => editItem(item.id)" style="cursor: pointer;"></i> &nbsp;
                                        <i class="fa fa-trash" @click="() => deleteItem(item.id)" style="cursor: pointer;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
    data () {
        return {
            toppingsDataList: [],
            restaurantId: parseInt(localStorage.getItem('restaurantId')),
            jwtToken: this.$cookies.get('token') || null
        }
    },
    created () {
        this.toppingsList ()
    },
    methods: {
        addToppings () {
            this.$emit("toppingsEvent")
        },
        toppingsList () {
            let url = process.env.VUE_APP_API_URL+`/api/topping?restaurantId=${this.restaurantId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.toppingsDataList = data.data
            })
        },
        editItem (val) {
          for (var i = 0; i < this.toppingsDataList.length; i++) {
            if (this.toppingsDataList[i].id == val) {
              let editObj = {
                id: this.toppingsDataList[i].id,
                category: this.toppingsDataList[i].toppingsCategory,
                title: this.toppingsDataList[i].label,
                price: this.toppingsDataList[i].basePrice
              }

              editObj = JSON.stringify(editObj)

              localStorage.setItem('editableToppingsData', editObj)
            }
          }

          setTimeout(()=>{this.$emit("editToppingsEvent")}, 1000)
        },
        deleteItem (val) {
            if (confirm('Are you sure to delete this toppings item?')) {
                let url = process.env.VUE_APP_API_URL+`/api/topping/delete?id=${val}`

                // request header
                let requestHeader = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.jwtToken}`,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Headers": "*",
                    'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
                let dataToSubmit = {}

                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                  if (data.status == 200) {
                    this.$toastr.s("Deleted Successfully", "Success");
                    this.toppingsList()
                  }

                  if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                      this.$router.push({ path: '/login' })
                    }
                  }
                })
            }
        }
    }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 69px;
  background: #FFEDE3;
  border: 1px solid #FFD8BC;
  box-sizing: border-box;
  border-radius: 0px;
}

.headerText {
  position: absolute;
  width: 200px;
  height: 19px;
  left: 28px;
  top: 20px;
  color: #FE750F;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.headerBtn {
  padding-top: 12px;
  padding-right: 44px;
}

.headerBtnStyle {
  background: #FFF5FF !important;
  color: #FE7611;
  margin-top: 7%;
}

.table > thead > tr > th {
  color: #E317E3;
  background: #FFF5FF;
}
.table > thead > tr > th:last-child, .table > tbody > tr > th:last-child, .table > tfoot > tr > th:last-child, .table > thead > tr > td:last-child, .table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
  width: auto;
}
</style>

export default {
	created () {},
  methods: {
    logout: function () {
      localStorage.setItem('isLoggedIn', false)
      this.$cookies.remove('token')
      return 1;
    },
    hello: function () {
      localStorage.setItem('isLoggedIn', false)
      this.$cookies.remove('token')
      return 1;
    }
  }
}

<template>

  <div class="content">
    <div class="container" style="padding-left: 10px; overflow-x: hidden">
      <div class="row">

        <div class="col-md-12 px-0">
          <top-navbar :title="restaurantName"></top-navbar>
          <div class="row">
            <div class="col-md-2 col-lg-2 content_sidebar">
              <ul>
                <li @click="() => toggleDiv('isImage', 1)" :class="{ activeElement : active_el == 1 }" class="list-group-item">
                  Image
                </li>
                <li @click="() => toggleDiv('isColor', 2)" :class="{ activeElement : active_el == 2 }" class="list-group-item">
                  Color
                </li>
                <li @click="() => toggleDiv('isPayment', 3)" :class="{ activeElement : active_el == 3 }" class="list-group-item">
                  Payment
                </li>
                <li @click="() => toggleDiv('isContent', 4)" :class="{ activeElement : active_el == 4 }" class="list-group-item">
                  Content
                </li>
                <li @click="() => toggleDiv('isMenu', 5)" :class="{ activeElement : active_el == 5 }" class="list-group-item">
                  Menu PDF
                </li>
                <li @click="() => toggleDiv('isSocial', 6)" :class="{ activeElement : active_el == 6 }" class="list-group-item">
                  Social Media
                </li>
              </ul>
            </div>
            <div class="col-md-10 col-lg-10">
              <div style="background-color: #FFFFFF;padding-top: 2%">
                <div v-if="sidebarContentVisibility.isImage">
                  <h2 class="text-center mt-0" style="border-bottom: 2px solid;">Image</h2>
                  <!-- favicon -->
                  <div style="background: rgb(209 201 218)">
                    <h3 class="pl-3">Favicon</h3>
                    <div class="row">
                      <div class="col-md-4 text-center my-2">
                        <img :src="favicon" width="200" alt="Image" class="header-image">
                        <i class="fa fa-times position-absolute header-delete"
                          @click="() => deleteImage(webData.favIconPublicId, imageTypeFavicon)" aria-hidden="true"></i>
                      </div>
                      <div class="col-md-12">
                        <form @submit.prevent="submitFavicon">
                          <div class="row">
                            <div class="col-md-4 text-center p-4">
                              <img src="/img/upload.png" alt=""> Upload New Image
                              <input type="file" @change="faviconSelected" accept="image/*" class="uploadBtn"/>
                            </div>
                            <div class="col-md-4 text-left p-4 contentBtnAlign">
                              <button class="btn submitBtn">Upload</button>
                              <img v-if="showFaviconLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- logo -->
                  <div style="background: rgb(209 201 218)">
                    <h3 class="pl-3">Logo</h3>
                    <div class="row">
                      <div class="col-md-4 text-center my-2">
                        <img :src="companyLogo" width="200" alt="Image" class="header-image">
                        <i class="fa fa-times position-absolute header-delete"
                          @click="() => deleteImage(webData.logoPublicId, imageTypeLogo)" aria-hidden="true"></i>
                      </div>
                      <div class="col-md-12">
                        <form @submit.prevent="submitLogo">
                          <div class="row">
                            <div class="col-md-4 text-center p-4">
                              <img src="/img/upload.png" alt=""> Upload New Image
                              <input type="file" @change="logoSelected" accept="image/*" class="uploadBtn"/>
                            </div>
                            <div class="col-md-4 text-left p-4 contentBtnAlign">
                              <button class="btn submitBtn">Upload</button>
                              <img v-if="showLogoLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- background image -->
                  <div style="background: rgb(209 201 218)">
                    <h3 class="pl-3">Background Image</h3>
                    <div class="row">
                      <div class="col-md-4 text-center my-2">
                        <img :src="backgroundImage" width="300" alt="Image" class="header-image">
                        <i class="fa fa-times position-absolute header-delete"
                          @click="() => deleteImage(webData.backgroundImagePublicId, imageTypeBackground)" aria-hidden="true"></i>
                      </div>
                      <div class="col-md-12">
                        <form @submit.prevent="submitBackgroundImage">
                          <div class="row">
                            <div class="col-md-4 text-center p-4">
                              <img src="/img/upload.png" alt=""> Upload New Image
                              <input type="file" @change="backgroundImageSelected" accept="image/*" class="uploadBtn"/>
                            </div>
                            <div class="col-md-4 text-left p-4 contentBtnAlign">
                              <button class="btn submitBtn">Upload</button>
                              <img v-if="showBackgroundImageLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!--header one-->
                  <div style="background: rgb(209 201 218)">
                    <h3 class="pl-3">Header One</h3>
                    <div class="row">
                      <div v-for="(item,i) in headerOneImages"
                          :key="i"
                          class="col-md-4 text-center my-2">
                        <img :src="item.src" width="300" alt="Image" class="header-image">
                        <i class="fa fa-times position-absolute header-delete"
                          @click="() => deleteImage(item.publicId, headerTypeOne)" aria-hidden="true"></i>
                      </div>
                      <div class="col-md-12">
                        <form @submit.prevent="submitHeaderOne">
                          <div class="row">
                            <div class="col-md-4 text-center p-4">
                              <img src="/img/upload.png" alt=""> Upload New Image
                              <input type="file" @change="headerOneImageSelected" accept="image/*" class="uploadBtn"/>
                            </div>
                            <div class="col-md-4 text-left p-4 contentBtnAlign">
                              <button class="btn submitBtn">Upload</button>
                              <img v-if="showHeaderOneLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!--header two-->
                  <div style="background: rgb(214 243 219)">
                    <h3 class="pl-3 mt-4">Header Two</h3>
                    <div class="row">
                      <div v-for="(item,i) in headerTwoImages"
                          :key="i"
                          class="col-md-4 text-center my-2">
                        <img :src="item.src" width="300" alt="Image" class="header-image">
                        <i class="fa fa-times position-absolute header-delete"
                          @click="() => deleteImage(item.publicId, headerTypeTwo)" aria-hidden="true"></i>
                      </div>
                      <div class="col-md-12">
                        <form @submit.prevent="submitHeaderTwo">
                          <div class="row">
                            <div class="col-md-4 text-center p-4">
                              <img src="/img/upload.png" alt=""> Upload New Image
                              <input type="file" @change="headerTwoImageSelected" accept="image/*" class="uploadBtn"/>
                            </div>
                            <div class="col-md-4 text-left p-4 contentBtnAlign">
                              <button class="btn submitBtn">Upload</button>
                              <img v-if="showHeaderTwoLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                  <!--header three-->
                  <div style="background: rgb(224 208 222)">
                    <h3 class="pl-3 mt-4">Header Three</h3>
                    <div class="row px-2">
                    <div v-for="(item,i) in headerThreeImages"
                        :key="i"
                        class="col-md-3 text-center my-2">
                      <img :src="item.src" width="100%" alt="Image" class="header-image">
                      <i class="fa fa-times position-absolute header-delete text-right"
                        @click="() => deleteImage(item.publicId, headerTypeThree)" aria-hidden="true"></i>
                    </div>
                    <div class="col-md-12">
                      <form @submit.prevent="submitHeaderThree">
                        <div class="row">
                          <div class="col-md-4 text-center p-4">
                            <img src="/img/upload.png" alt=""> Upload New Image
                            <input type="file" @change="headerThreeImageSelected" accept="image/*" class="uploadBtn"/>
                          </div>
                          <div class="col-md-4 text-left p-4 contentBtnAlign">
                            <button class="btn submitBtn">Upload</button>
                            <img v-if="showHeaderThreeLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                  </div>
                </div>

                <div v-if="sidebarContentVisibility.isContent">
                  <h2 class="text-center mt-5" style="border-bottom: 2px solid;">Content</h2>
                  <h3 class="content-heading">Hours</h3>
                  <div class="row pl-5">
                    <div class="col-md-2 col-11">
                      <input type="text" class="form-control" v-model="openingHrs" placeholder="00:00">
                    </div>-
                    <div class="col-md-2 col-11 contentPaddingClass">
                      <input type="text" class="form-control" v-model="closingHrs" placeholder="00:00">
                    </div>
                  </div>
                  <!-- <h3 class="content-heading">Time Slot Applicable</h3>
                  <div class="row pl-5">
                    <div class="col-md-2 col-11">
                      <select v-model="timeSlotApplicable" class="form-control mb-3">
                        <option value="" selected disabled>Choose value</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div> -->
                  <h3 class="content-heading">Latitude/Longitude</h3>
                  <div class="row pl-5">
                    <div class="col-md-3 col-11">
                      <input type="text" class="form-control" v-model="latitude" placeholder="latitude">
                    </div>
                    <div class="col-md-3 col-11 contentPaddingClass">
                      <input type="text" class="form-control" v-model="longitude" placeholder="longitude">
                    </div>
                  </div>
                  <h3 class="content-heading">Web Address</h3>
                  <div class="row pl-5">
                    <div class="col-md-6 col-11">
                      <input type="text" class="form-control" v-model="webAddress" placeholder="Web address">
                    </div>
                  </div>
                  <h3 class="content-heading">Map</h3>
                  <div class="row pl-5">
                    <div class="col-md-11 col-11">
                      <textarea class="form-control" v-model="mapIframe" placeholder="Map" cols="10" rows="5"></textarea>
                    </div>
                  </div>

                  <div class="row content-heading">
                    <div class="col-md-3 col-sm-12">
                      <h3 class="mt-5">Mission Statement</h3>
                    </div>
                    <div class="col-md-3 col-sm-12 mt-2">
                      <label for="">Font Color</label>
                      <input type="text" class="form-control" v-model="aboutTextColor" placeholder="#FFFFFF">
                    </div>
                    <div class="col-md-3 col-sm-12 my-2">
                      <label for="">Background Color</label>
                      <input type="text" class="form-control" v-model="aboutBackgroundColor" placeholder="Mission text color">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-12 px-5">
                      <vue-editor id="aboutUs" v-model="aboutUs" />
                    </div>
                  </div>
                  <div class="row content-heading">
                    <div class="col-md-3 col-sm-12">
                      <h3 class="mt-5">Welcome Message</h3>
                    </div>
                    <div class="col-md-3 col-sm-12 mt-2">
                      <label for="">Font Color</label>
                      <input type="text" class="form-control" v-model="welcomeTextColor" placeholder="#FFFFFF">
                    </div>
                    <div class="col-md-3 col-sm-12 my-2">
                      <label for="">Background Color</label>
                      <input type="text" class="form-control" v-model="welcomeBackgroundColor" placeholder="#FFFFFF">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 px-5">
                      <vue-editor v-model="welcomeMsg" />
                    </div>
                  </div>
                  <h3 class="content-heading mt-5">Privacy Policy</h3>
                  <div class="row">
                    <div class="col-md-12 px-5">
                      <vue-editor v-model="privacyPolicy" />
                    </div>
                  </div>
                  <h3 class="content-heading mt-5">Terms & Condition</h3>
                  <div class="row">
                    <div class="col-md-12 px-5">
                      <vue-editor v-model="termsCondition" />
                    </div>
                  </div>
                  <h3 class="content-heading mt-5">Allergy Information</h3>
                  <div class="row">
                    <div class="col-md-12 px-5">
                      <vue-editor v-model="allergyInformation" />
                    </div>
                  </div>
                  <h3 class="content-heading mt-5">Lunch Opening Hours</h3>
                  <div class="row">
                    <div class="col-md-12 px-5">
                      <vue-editor v-model="lunchOpeningHours" />
                    </div>
                  </div>
                </div>


                <div v-if="sidebarContentVisibility.isColor">
                  <h3 class="content-heading">Colors</h3>
                  <div class="row pl-5 w-100">
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Header Bg Color</label>
                          <input type="text" class="form-control" v-model="headerColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">Header Text Color</label>
                          <input type="text" class="form-control" v-model="navigationColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">Header Hover Text Color</label>
                          <input type="text" class="form-control" v-model="navbarHoverColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">Menu Icon Bg Color</label>
                          <input type="text" class="form-control" v-model="menuIconBgColor" placeholder="#FFFFFF">
                        </div>
                        <!-- <div class="col-md-12">
                          <label for="">Mobile Navbar Background Color</label>
                          <input type="text" class="form-control" v-model="mobileNavBgColor" placeholder="Mobile Navbar Background Color">
                        </div> -->
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Footer Bg Color</label>
                          <input type="text" class="form-control" v-model="footerColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">Footer Text Color</label>
                          <input type="text" class="form-control" v-model="footerTextColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">All Rights Reserved Text Color</label>
                          <input type="text" class="form-control" v-model="rightsReservedTextColor" placeholder="All rights reserved text color">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Button Background Color</label>
                          <input type="text" class="form-control" v-model="buttonColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">Button Text Color</label>
                          <input type="text" class="form-control" v-model="buttonFontColor" placeholder="#FFFFFF">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Left Category Hover Bg Color</label>
                          <input type="text" class="form-control" v-model="categoryHoverBgColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">Left Category Hover Text Color</label>
                          <input type="text" class="form-control" v-model="categoryHoverTextColor" placeholder="#FFFFFF">
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="">Middle Category Bg Color</label>
                          <input type="text" class="form-control" v-model="categoryBgForDesktop" placeholder="#FFFFFF">
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr style="background-color: red; padding: 1px">
                  <div class="row pl-5 w-100">
                    <div class="col-md-3 mt-3">
                      <label for="">Alert Box Background Color</label>
                      <input type="text" class="form-control" v-model="alertBgColor" placeholder="#FFFFFF">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Header Two Background</label>
                      <input type="text" class="form-control" v-model="headerTwoBackgroundColor" placeholder="#FFFFFF">
                    </div>
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Mission Background Color</label>
                      <input type="text" class="form-control" v-model="aboutBackgroundColor" placeholder="#FFFFFF">
                    </div> -->
                    <div class="col-md-3 mt-3">
                      <label for="">Review Background Color</label>
                      <input type="text" class="form-control" v-model="reviewBackgroundColor" placeholder="#FFFFFF">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Review Text Color</label>
                      <input type="text" class="form-control" v-model="reviewTextColor" placeholder="Review Text color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Cart Item Bg Color</label>
                      <input type="text" class="form-control" v-model="cartItemBgColor" placeholder="#FFFFFF">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Contact Page Background Color</label>
                      <input type="text" class="form-control" v-model="contactPageBgColor" placeholder="#FFFFFF">
                    </div>
                    <!-- <div class="col-md-3">
                      <label for="">Header Bg Color</label>
                      <input type="text" class="form-control" v-model="headerColor" placeholder="Header color">
                    </div> -->
                    <!-- <div class="col-md-3">
                      <label for="">Navigation Color</label>
                      <input type="text" class="form-control" v-model="navigationColor" placeholder="Navigation color">
                    </div> -->
                    <!-- <div class="col-md-3">
                      <label for="">Navbar Hover Color</label>
                      <input type="text" class="form-control" v-model="navbarHoverColor" placeholder="Navbar hover color">
                    </div> -->
                    <!-- <div class="col-md-3">
                      <label for="">Mobile Navbar Background Color</label>
                      <input type="text" class="form-control" v-model="mobileNavBgColor" placeholder="Mobile Navbar Background Color">
                    </div> -->

                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Alert Button Background Color</label>
                      <input type="text" class="form-control" v-model="alertBoxBtnBgColor" placeholder="Alert button background color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Alert Button Font Color</label>
                      <input type="text" class="form-control" v-model="alertBoxBtnFontColor" placeholder="Alert button font color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Welcome Text</label>
                      <input type="text" class="form-control" v-model="welcomeTextColor" placeholder="Welcome text color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Welcome Text Background</label>
                      <input type="text" class="form-control" v-model="welcomeBackgroundColor" placeholder="Welcome text background color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Header Two Background</label>
                      <input type="text" class="form-control" v-model="headerTwoBackgroundColor" placeholder="Header two background color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Mission Text Color</label>
                      <input type="text" class="form-control" v-model="aboutTextColor" placeholder="Mission text color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Mission Background Color</label>
                      <input type="text" class="form-control" v-model="aboutBackgroundColor" placeholder="Mission background color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Review Background Color</label>
                      <input type="text" class="form-control" v-model="reviewBackgroundColor" placeholder="Review Background color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Footer Background Color</label>
                      <input type="text" class="form-control" v-model="footerColor" placeholder="Footer background color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Footer Text Color</label>
                      <input type="text" class="form-control" v-model="footerTextColor" placeholder="Footer text color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">All Rights Reserved Text Color</label>
                      <input type="text" class="form-control" v-model="rightsReservedTextColor" placeholder="All rights reserved text color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Footer Powered by</label>
                      <input type="text" class="form-control" v-model="footerPoweredByColor" placeholder="Powered By color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Category Background Color</label>
                      <input type="text" class="form-control" v-model="categoryBgForDesktop" placeholder="Category background color">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Category Bg For Mobile</label>
                      <input type="text" class="form-control" v-model="categoryBgForMobile" placeholder="Category bg for mobile">
                    </div> -->
                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Category Hover Bg Color</label>
                      <input type="text" class="form-control" v-model="categoryHoverBgColor" placeholder="Category hover bg color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Category Hover Text Color</label>
                      <input type="text" class="form-control" v-model="categoryHoverTextColor" placeholder="Category hover text color">
                    </div> -->

                    <!-- <div class="col-md-3 mt-3">
                      <label for="">Button Background Color</label>
                      <input type="text" class="form-control" v-model="buttonColor" placeholder="Button color">
                    </div>
                    <div class="col-md-3 mt-3">
                      <label for="">Button Text Color</label>
                      <input type="text" class="form-control" v-model="buttonFontColor" placeholder="Button text color">
                    </div> -->

                  </div>
                </div>

                <div v-if="sidebarContentVisibility.isPayment">
                  <h3 class="content-heading">Payment gateways</h3>
                  <div class="row pl-5">
                    <div class="col-md-12 col-11">
                      <label for="">Stripe secret key</label>
                      <input type="text" class="form-control" v-model="stripeSecretKey" placeholder="Secret key">
                    </div>
                    <div class="col-md-12 col-11 mt-3">
                      <label for="">Stripe publish key</label>
                      <input type="text" class="form-control" v-model="stripePublishKey" placeholder="Publish key">
                    </div>
                  </div>
                  <!-- <div class="row pl-5">
                    <div class="col-md-12 col-11 mt-3">
                      <label for="">Elavon Username</label>
                      <input type="text" class="form-control" v-model="elavonUserName" placeholder="Username">
                    </div>
                    <div class="col-md-12 col-11 mt-3">
                      <label for="">Elavon Password</label>
                      <input type="text" class="form-control" v-model="elavonPassword" placeholder="Password">
                    </div>
                    <div class="col-md-12 col-11 mt-3">
                      <label for="">Default payment gateway</label>
                      <select v-model="defaultPaymentGateway" class="form-control mb-3">
                        <option value="Stripe">Stripe</option>
                        <option value="Elavon">Elavon</option>
                      </select>
                    </div>
                  </div> -->
                </div>



                <div v-if="sidebarContentVisibility.isMenu">
                  <h3 class="content-heading">Menu PDF</h3>
                  <div class="row">
                    <div class="col-md-4 text-center my-2">
                      <img :src="menuPdf" width="200" alt="File" class="header-image">
                      <i class="fa fa-times position-absolute header-delete"
                          @click="() => deleteImage(webData.menuPdfPublicId, 'MenuPdf')" aria-hidden="true"></i>
                    </div>
                    <div class="col-md-12">
                      <form @submit.prevent="submitMenu">
                        <div class="row">
                          <div class="col-md-4 text-center p-4">
                            <img src="/img/upload.png" alt=""> Upload New Menu
                            <input type="file" @change="menuPdfSelected" class="uploadBtn"/>
                          </div>
                          <div class="col-md-4 text-left p-4 contentBtnAlign">
                            <button class="btn submitBtn">Upload</button>
                            <img v-if="showMenuLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div v-if="sidebarContentVisibility.isSocial">
                  <h3 class="content-heading">Social Media</h3>
                  <div class="row">
                    <div class="col-md-4 col-11 pl-5 form-group">
                      <select v-model="socialMediaType" class="form-control mb-3">
                        <option value="0" disabled>Select Media</option>
                        <option value="fb">Facebook</option>
                        <option value="google">Google</option>
                        <option value="insta">Instagram</option>
                        <option value="twitter">Twitter</option>
                        <option value="linkedin">Linkedin</option>
                      </select>
                      <label for="socialMedia">Link</label>
                      <input type="text" v-model="socialMediaUrl" class="form-control" id="socialMedia" placeholder="Social media URL">
                    </div>
                    <div class="col-md-7 col-11 pl-5 form-group">
                      <div v-for="(item, index) in socialMedia" :key="index">
                        <h4 class="my-0">{{ item.type }}
                          <span @click="socialMedia.splice(index, 1)" class="removeSocialBtn">x</span>
                        </h4>
                        <p style="word-break: break-all">{{ item.link }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="!sidebarContentVisibility.isMenu && !sidebarContentVisibility.isImage" class="col-md-12 p-5 contentBtnAlign">
                  <button class="btn submitBtn" @click="updateWebConfig">Upload</button>
                  <img v-if="showWebConfigLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TopNavbar from '../../layout/TopNavbar'
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
  components: {
    TopNavbar
  },
  data () {
    return {
      sidebarContentVisibility: {
        isImage: true,
        isColor: false,
        isPayment: false,
        isContent: false,
        isMenu: false,
        isSocial: false,
      },
      active_el: 1,
      headerOne: {
        image: null,
      },
      headerTwo: {
        image: null,
      },
      headerThree: {
        image: null,
      },
      favicon: '',
      companyLogo: '',
      backgroundImage: '',
      headerOneImages: [],
      headerTwoImages: [],
      headerThreeImages: [],
      openingHrs: '',
      closingHrs: '',
      // timeSlotApplicable: '',
      latitude: '',
      longitude: '',
      mapIframe: '',
      stripeSecretKey: '',
      stripePublishKey: '',
      elavonUserName: '',
      elavonPassword: '',
      defaultPaymentGateway: 'Stripe',
      headerColor: '',
      navigationColor: '',
      navbarHoverColor: '',
      menuIconBgColor: '',
      mobileNavBgColor: '',
      alertBgColor: '',
      alertBoxBtnBgColor: '',
      alertBoxBtnFontColor: '',
      welcomeTextColor: '',
      welcomeBackgroundColor: '',
      headerTwoBackgroundColor: '',
      aboutTextColor: '',
      aboutBackgroundColor: '',
      reviewTextColor: '',
      reviewBackgroundColor: '',
      footerColor: '',
      footerTextColor: '',
      rightsReservedTextColor: '',
      footerPoweredByColor: '',
      categoryBgForDesktop: '',
      categoryBgForMobile: '',
      categoryHoverBgColor: '',
      categoryHoverTextColor: '',
      cartItemBgColor: '',
      buttonColor: '',
      buttonFontColor: '',
      contactPageBgColor: '',
      aboutUs: '',
      welcomeMsg: '',
      privacyPolicy: '',
      termsCondition: '',
      allergyInformation: '',
      lunchOpeningHours: '',
      requestHeader: null,
      imageTypeLogo: 'Logo',
      imageTypeFavicon: 'FavIcon',
      imageTypeBackground: 'BackgroundImage',
      headerTypeOne: 'HeaderOne',
      headerTypeTwo: 'HeaderTwo',
      headerTypeThree: 'HeaderThree',
      showLogoLoading: false,
      showFaviconLoading: false,
      showBackgroundImageLoading: false,
      showHeaderOneLoading: false,
      showHeaderTwoLoading: false,
      showHeaderThreeLoading: false,
      showWebConfigLoading: false,
      jwtToken: '',
      webData: '',
      menuPdf: null,
      showMenuLoading: false,
      restaurantName: localStorage.getItem('restaurantName'),
      socialMedia: [],
      socialMediaType: '0',
      socialMediaUrl: '',
      webAddress: ''
    }
  },
  created() {
    this.jwtToken = this.$cookies.get('token') || null
    this.webConfig()
  },
  methods: {
    faviconSelected(e){
      this.favicon = e.target.files[0]
    },
    logoSelected(e){
      this.companyLogo = e.target.files[0]
    },
    backgroundImageSelected(e){
      this.backgroundImage = e.target.files[0]
    },
    headerOneImageSelected(e){
      this.headerOne.image = e.target.files[0]
    },
    headerTwoImageSelected(e){
      this.headerTwo.image = e.target.files[0]
    },
    headerThreeImageSelected(e){
      this.headerThree.image = e.target.files[0]
    },
    menuPdfSelected(e){
      this.menuPdf = e.target.files[0]
    },
    submitFavicon() {
      this.showFaviconLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.favicon || '')
      dataToSubmit.append('imageType', this.imageTypeFavicon)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showFaviconLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitLogo() {
      this.showLogoLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.companyLogo || '')
      dataToSubmit.append('imageType', this.imageTypeLogo)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showLogoLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitBackgroundImage() {
      this.showBackgroundImageLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.backgroundImage || '')
      dataToSubmit.append('imageType', this.imageTypeBackground)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showBackgroundImageLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderOne() {
      this.showHeaderOneLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerOne.image || '')
      dataToSubmit.append('imageType', this.headerTypeOne)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderOneLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderTwo() {
      this.showHeaderTwoLoading = true
      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerTwo.image || '')
      dataToSubmit.append('imageType', this.headerTypeTwo)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderTwoLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderThree() {
      this.showHeaderThreeLoading = true
      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerThree.image || '')
      dataToSubmit.append('imageType', this.headerTypeThree)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderThreeLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    updateWebConfig(){
      this.showWebConfigLoading = true
      //social media payload
      if (this.socialMediaType !== '0') {
        let isUpdate = false
        this.socialMedia.forEach(item => {
          if (item.type === this.socialMediaType) {
            item.link = this.socialMediaUrl
            isUpdate = true
          }
        })
        if (!isUpdate) {
          const data =
            {
              "type": this.socialMediaType,
              "link": this.socialMediaUrl
            }
          this.socialMedia.push(data)
        }
      }

      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "welcomeTxt": this.welcomeMsg,
        "aboutUs": this.aboutUs,
        "privacyPolicy": this.privacyPolicy,
        "termsCondition": this.termsCondition,
        "lat": this.latitude,
        "long": this.longitude,
        "mapIframe": this.mapIframe,
        "stripeSecretKey": this.stripeSecretKey,
        "stripePublishKey": this.stripePublishKey,
        "elavonUserName": this.elavonUserName,
        "elavonPassword": this.elavonPassword,
        "defaultPaymentGateway": this.defaultPaymentGateway,
        "headerColor": this.headerColor,
        "navigationColor": this.navigationColor,
        "navbarHoverColor": this.navbarHoverColor,
        "menuIconBgColor": this.menuIconBgColor,
        "mobileNavBgColor": this.mobileNavBgColor,
        "alertBgColor": this.alertBgColor,
        "alertBoxBtnBgColor": this.alertBoxBtnBgColor,
        "alertBoxBtnFontColor": this.alertBoxBtnFontColor,
        "welcomeTextColor": this.welcomeTextColor,
        "welcomeBackgroundColor": this.welcomeBackgroundColor,
        "welcomeBgShadowColor": this.webData.welcomeBgShadowColor,
        "sliderText": this.webData.sliderText,
        "headerTwoBackgroundColor": this.headerTwoBackgroundColor,
        "aboutTextColor": this.aboutTextColor,
        "aboutBackgroundColor": this.aboutBackgroundColor,
        "reviewTextColor": this.reviewTextColor,
        "reviewFontColor": this.webData.reviewFontColor,
        "reviewBackgroundColor": this.reviewBackgroundColor,
        "footerColor": this.footerColor,
        "footerTextColor": this.footerTextColor,
        "rightsReservedTextColor": this.rightsReservedTextColor,
        "poweredByColor": this.footerPoweredByColor,
        "categoryBgForDesktop": this.categoryBgForDesktop,
        "categoryBgForMobile": this.categoryBgForMobile,
        "categoryHoverBgColor": this.categoryHoverBgColor,
        "categoryHoverTextColor": this.categoryHoverTextColor,
        "cartItemBgColor": this.cartItemBgColor,
        "buttonColor": this.buttonColor,
        "buttonFontColor": this.buttonFontColor,
        "contactPageBgColor": this.contactPageBgColor,
        "openingTime": this.openingHrs,
        "closingTime": this.closingHrs,
        // "timeSlotApplicable": this.timeSlotApplicable,
        "allergyInformation": this.allergyInformation,
        "lunchOpeningHours": this.lunchOpeningHours,
        "socialMedia": this.socialMedia,
        "webAddress": this.webAddress
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/update'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWebConfigLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitMenu() {
      if (!this.menuPdf) {
        this.$toastr.w("Please select file.", "Warning");
        return
      }
      this.showMenuLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.menuPdf || '')
      dataToSubmit.append('imageType', 'MenuPdf')
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showMenuLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    deleteImage(publicId, headerType){
      if (confirm('Please confirm to delete.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/webConfig/v2/deleteImage'
        // request data
        let dataToSubmit = {
          "restaurantId": parseInt(localStorage.getItem('restaurantId')),
          "publicId": publicId,
          "imageType": headerType
        }
        // send request
        axios
          .post(url, dataToSubmit, {
            headers: this.requestHeader
          })
          .then((data) => {
            if (data.status == 200) {
              this.$toastr.s("Successfully Deleted.", "Success");
              setTimeout(()=> {this.$router.go()}, 1000)
            }
          })
      }
    },
    webConfig(){
      // request header
      this.requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig?restaurantId='+localStorage.getItem('restaurantId')
      // send request
      axios
        .get(url,
          {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            }
          })
        .then((result) => {
          if (result.status === 403) {
              let logoutResponse = this.logout()
              if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
              }
          }
          this.webData = result.data
          const data = result.data
          if (typeof data.headerOneImages != 'undefined'){
            for (var i=0; i<data.headerOneImages.length; i++){
              this.headerOneImages.push({'src': data.headerOneImages[i].url, 'publicId': data.headerOneImages[i].publicId})
            }
          }
          if (typeof data.headerTwoImages != 'undefined') {
            for (var j=0; j<data.headerTwoImages.length; j++){
              this.headerTwoImages.push({'src': data.headerTwoImages[j].url, 'publicId': data.headerTwoImages[j].publicId})
            }
          }
          if (typeof data.headerThreeImages != 'undefined') {
            for (var k=0; k<data.headerThreeImages.length; k++){
              this.headerThreeImages.push({'src': data.headerThreeImages[k].url, 'publicId': data.headerThreeImages[k].publicId})
            }
          }
          this.openingHrs = data.openingTime
          this.closingHrs = data.closingTime
          // this.timeSlotApplicable = data.timeSlotApplicable
          this.latitude = data.lat
          this.longitude = data.long
          this.mapIframe = data.mapIframe
          this.headerColor = data.headerColor
          this.navigationColor = data.navigationColor
          this.navbarHoverColor = data.navbarHoverColor
          this.menuIconBgColor = data.menuIconBgColor
          this.mobileNavBgColor = data.mobileNavBgColor
          this.alertBgColor = data.alertBgColor
          this.alertBoxBtnBgColor = data.alertBoxBtnBgColor
          this.alertBoxBtnFontColor = data.alertBoxBtnFontColor
          this.welcomeTextColor = data.welcomeTextColor
          this.welcomeBackgroundColor = data.welcomeBackgroundColor
          this.headerTwoBackgroundColor = data.headerTwoBackgroundColor
          this.aboutTextColor = data.aboutTextColor
          this.aboutBackgroundColor = data.aboutBackgroundColor
          this.reviewTextColor = data.reviewTextColor
          this.reviewBackgroundColor = data.reviewBackgroundColor
          this.footerColor = data.footerColor
          this.footerTextColor = data.footerTextColor
          this.rightsReservedTextColor = data.rightsReservedTextColor
          this.footerPoweredByColor = data.poweredByColor
          this.categoryBgForDesktop = data.categoryBgForDesktop
          this.categoryBgForMobile = data.categoryBgForMobile
          this.categoryHoverBgColor = data.categoryHoverBgColor
          this.categoryHoverTextColor = data.categoryHoverTextColor
          this.cartItemBgColor = data.cartItemBgColor
          this.buttonColor = data.buttonColor
          this.buttonFontColor = data.buttonFontColor
          this.contactPageBgColor = data.contactPageBgColor
          this.aboutUs = data.aboutUs
          this.welcomeMsg = data.welcomeTxt
          this.privacyPolicy = data.privacyPolicy
          this.termsCondition = data.termsCondition
          this.allergyInformation = data.allergyInformation
          this.lunchOpeningHours = data.lunchOpeningHours
          this.stripeSecretKey = data.stripeSecretKey
          this.stripePublishKey = data.stripePublishKey
          this.elavonUserName = data.elavonUserName
          this.elavonPassword = data.elavonPassword
          this.defaultPaymentGateway = data.defaultPaymentGateway
          this.favicon = data.favIcon
          this.companyLogo = data.logo
          this.backgroundImage = data.backgroundImage
          this.socialMedia = data.socialMedia
          this.webAddress = data.webAddress
        })
    },
    toggleDiv(param, el_no) {
      const obj = Object.entries(this.sidebarContentVisibility)
      for (const [key, value] of obj) {
        if (key == param) {
          this.sidebarContentVisibility[key] = true
        } else {
          this.sidebarContentVisibility[key] = false
        }
      }
      this.active_el = el_no
    }
  }
}
</script>

<style scoped>
.content_sidebar{background-color: #ffbfa4 !important; padding-top: 3rem;}
.content_sidebar ul{padding-left: 1rem;}
.content_sidebar ul li{
  font-size: 1.2rem;
  background-color: transparent;
  border: 0px solid transparent;
  padding: 0 1.25rem;
  margin: .25rem 0;
  cursor: pointer;
}
.content_sidebar ul li.activeElement{font-weight: bold;}

.uploadBtn {
  cursor: pointer;
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}
.content-heading{padding-left: 2rem; color: #38ACE2}
.content-heading h3{color: #38ACE2}
.header-delete{width: 40px; height:40px; top: 0; cursor:pointer; font-size: 1.5rem;right: 35px; color: red;}
.removeSocialBtn{
  color: red;
  cursor: pointer;
}
</style>

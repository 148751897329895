<template>
  <div class="content">
    <div class="container px-0">
      <div class="row w-100 mx-0">
        <div class="col-md-12 col-12 px-0">
          <top-navbar title="OWNER" search="false"></top-navbar>
        </div>
      </div>
      <div class="row pb-5 mx-0" style="background-color: #FDEAD5">
        <div class="col-md-10 offset-md-1 mt-5">
          <form class="">
            <h5 class="font-weight-bold d-inline-block">Origin</h5>
            <p v-if="originError" class="d-inline-block pl-5" style="color: red">Details not matched!</p>
            <div class="row text-center mb-5">
              <div class="col-md-6">
                <v-select
                  :options="restaurantList"
                  v-model="selectedOrigin"
                  label="name"
                  placeholder="Select Business"
                  class="ownerDropdown">
                </v-select>
              </div>
              <div class="col-md-3">
                <input type="text" v-model="originId" placeholder="ID" class="form-control">
              </div>
              <div class="col-md-3">
                <input type="text" v-model="originPostcode" placeholder="Postcode" class="form-control">
              </div>
            </div>
            <h5 class="font-weight-bold d-inline-block">Destination</h5>
            <p v-if="destinationError" class="d-inline-block pl-5" style="color: red">Details not matched!</p>
            <div class="row text-center">
              <div class="col-md-6">
                <v-select
                  :options="restaurantList"
                  v-model="selectedDestination"
                  label="name"
                  placeholder="Select Business"
                  class="ownerDropdown">
                </v-select>
              </div>
              <div class="col-md-3">
                <input type="text" v-model="destinationId" placeholder="ID" class="form-control">
              </div>
              <div class="col-md-3">
                <input type="text" v-model="destinationPostcode" placeholder="Postcode" class="form-control">
              </div>
            </div>
          </form>
        </div>
      </div>
      <button type="button" @click="doubleCheckInfo" class="menuMigrateSubmitBtn">Migrate</button>
    </div>

    <div class="modal-vue">
      <!-- modal -->
      <div class="modal-own" v-if="showConfirmModal">
        <div class="modal-body">
          <h4 style="padding: 0 3rem; font-weight: bold" class="mb-5">Please double-check all the information before contunuing to migrate.</h4>

          <button type="button" @click="showConfirmModal = false" class="menuMigrateCancelBtn">Cancel</button>
          <button type="button" @click="migrateMenu" class="menuMigrateContinueBtn">Continue</button>

        </div>
      </div>
    </div>

    <div v-if="isLoading" class="overlay">
      <img src="/img/loading.gif" alt="GIF" width="30" height="30" class="mx-auto d-block" style="margin-top: 20%">
    </div>

  </div>
</template>
<script>
  import TopNavbar from '../layout/TopNavbar'
  import vSelect from "vue-select";
  import axios from 'axios'
  import "vue-select/dist/vue-select.css"

  export default {
    components: {
      TopNavbar,
      vSelect
    },
    data () {
      return {
        jwtToken: this.$cookies.get('token') || null,
        restaurantList: [],
        selectedOrigin: {},
        originId: '',
        originPostcode: '',
        originError: false,
        selectedDestination: {},
        destinationId: '',
        destinationPostcode: '',
        destinationError: false,
        showConfirmModal: false,
        isLoading: false
      }
    },
    created () {
      this.ownerList()
    },
    methods: {
      ownerList () {
        let url = process.env.VUE_APP_API_URL+'/api/restaurant/list'

        axios
        .get(url,
        {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          }
        })
        .then((data) => {
          this.restaurantList = data.data
        })
      },
      doubleCheckInfo () {
        // check required fields
        if (!this.selectedOrigin || !this.originId || !this.originPostcode || !this.selectedDestination || !this.destinationId || !this.destinationPostcode){
          this.$toastr.w("Please select all fields", "Warning")
          return
        }
        // check origin restaurant info
        if (this.selectedOrigin.id != this.originId || this.selectedOrigin.postCode != this.originPostcode) {
          this.originError = true
          return
        }
        this.originError = false
        // check destination restaurant info
        if (this.selectedDestination.id != this.destinationId || this.selectedDestination.postCode != this.destinationPostcode) {
          this.destinationError = true
          return
        }
        this.destinationError = false
        this.showConfirmModal = true
      },
      migrateMenu () {
        this.isLoading = true
        let url = process.env.VUE_APP_API_URL+'/api/restaurant/menuMigration?restaurantIdFrom='+this.originId+'&restaurantIdTo='+this.destinationId

        axios
        .get(url,
        {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          }
        })
        .then((data) => {
          this.isLoading = false
          this.showConfirmModal = false
        })
        .catch((e)=>{
          this.isLoading = false
          this.showConfirmModal = false
        })
      }
    }
  }

</script>
<style scoped>
.ownerDropdown input{padding: .15rem 0;}
.menuMigrateSubmitBtn{
  position: absolute;
  padding: 6px 30px;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
  color: #fff;
  border: unset;
  border-radius: 5px;
  right: 2rem;
  bottom: 2rem;
}
.menuMigrateCancelBtn{
  padding: 6px 30px;
  color: #000;
  background: #fff;
  border-radius: 5px;
  border: unset;
  box-shadow: 1px 1px 4px 0px #888888;
}
.menuMigrateContinueBtn{
  float: right;
  padding: 6px 25px;
  color: #fff;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 99%);
  border-radius: 5px;
  border: unset;
}
.overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}
.modal-body{box-shadow: 1px 2px 5px 0px #888888;}
.modal-vue .modal-own {
  position: fixed;
  width: 450px;
  z-index: 9999;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  bottom: 5%;
  left: 55%;
}

</style>

<template>
    <div class="content py-0">
        <div class="container">
            <div class="row">
                <div class="col-md-12 px-0">
                    <div class="header">
                      <div class="row">
                        <div class="col-md-6 col-6">
                          <span class="headerText"> Menu Management - Custom Deal Set </span>
                        </div>
                        <div class="col-md-6 col-6 pl-0">
                          <span style="float: right;" >
                              <button class="btn btn-sm addSetMenuBtn" type="button" @click="addNewSet">Add Set Menu</button>
                              <!-- <button class="btn btn-sm ml-3 goBackBtn" type="button" @click="goBack">Go Back</button> -->
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="customSetMealTable" style="background-color: #FFFFFF;">
                      <table class="table table-bordered" id="setTable">
                        <thead>
                          <tr style="font-weight: bold;">
                            <th>Title</th>
                            <th>Minimum</th>
                            <th>Maximum</th>
                            <th>Qty</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in customItemSet" :key="index">
                              <td>{{item.label}}
                                  <i class="fa fa-edit" style="cursor: pointer;" @click="() => editSetLabel(item)"></i>
                                  <div :class="'setOptions'+item.setId" style="display: none;" >
                                      <hr>
                                      <div v-for="(innetItem, index) in itemsNameUnderSet" :key="index">
                                          <span style="color: #393C40 !important;">{{innetItem}}</span>
                                      </div>
                                  </div>
                              </td>
                              <td>{{item.minimum}}
                                  <div :class="'setOptions'+item.setId" style="display: none;" ><hr>
                                      <div v-for="(innetItem, index) in itemsNameUnderSet" :key="index">
                                          <span><br></span>
                                      </div>
                                  </div>
                              </td>
                              <td>{{item.maximum}}
                                  <div :class="'setOptions'+item.setId" style="display: none;" ><hr>
                                      <div v-for="(innetItem, index) in itemsNameUnderSet" :key="index">
                                          <span><br></span>
                                      </div>
                                  </div>
                              </td>
                              <td>{{item.baseQty}}
                                  <div :class="'setOptions'+item.setId" style="display: none;" >
                                      <hr>
                                      <div v-for="(innetItem, index) in itemsNameUnderSet" :key="index">
                                          <span>1</span>
                                      </div>
                                  </div>
                              </td>
                              <td> <!--{{item.status}} --> Status
                                  <div :class="'setOptions'+item.setId" style="display: none;" >
                                      <hr>
                                      <div v-for="(innetItem, index) in itemsNameUnderSet" :key="index">
                                          <span style="color: green;">Active</span>
                                      </div>
                                  </div>
                              </td>
                              <td><i class="fa fa-edit" style="cursor: pointer;" @click="() => editSet(item.setId+'-'+item.label)"></i> &nbsp;
                                  <i class="fa fa-trash" style="cursor: pointer;" @click="() => deleteSet(item.setId)"></i>
                                  <i class="fa fa-chevron-down" style="cursor: pointer; display: inline;" :id="'showItemsInsideSet'+item.setId" @click="() => showItemsInsideSet(item.setId, this)"></i>
                                  <i class="fa fa-chevron-up" style="cursor: pointer; display: none;" :id="'hideItemsInsideSet'+item.setId" @click="() => showItemsInsideSet(item.setId, this)"></i>

                                  <div :class="'setOptions'+item.setId" style="display: none;" >
                                      <hr>
                                      <div v-for="(innetItem, index) in itemsIdUnderSet" :key="index">
                                          <span><i class="fa fa-times" style="cursor: pointer;" @click="() => deleteItemsUnderSet(item.setId, innetItem)"></i></span>
                                      </div>
                                  </div>
                              </td>

                          </tr>
                        </tbody>
                      </table>

                      <div class="" style="margin-top: 3%" v-if="isAddNewSet">
                          <form class="" action="index.html" method="post">
                              <table class="table table-bordered">
                                  <tbody>
                                      <tr>
                                          <td>
                                              <div class="btn-group" role="group" aria-label="Basic example">
                                                  <div class="form-group">
                                                      <input type="email" class="form-control" id="exampleInputEmail1" v-model="setName" aria-describedby="emailHelp" placeholder="Enter Set Name">
                                                  </div>
                                                  <!-- <div class="form-group">
                                                      <select class="form-control" id="exampleFormControlSelect1" v-model="itemIdSelected">
                                                          <option v-for="(item, index) in itemDataList" :key="index" :value="item.id"> {{item.label}} </option>
                                                      </select>
                                                  </div> -->
                                              </div>
                                          </td>
                                          <td>
                                              <div class="form-group">
                                                  <input type="email" class="form-control" id="exampleInputEmail1" v-model="minimum" aria-describedby="emailHelp" placeholder="Minimum">
                                              </div>
                                          </td>
                                          <td>
                                              <div class="form-group">
                                                  <input type="email" class="form-control" id="exampleInputEmail1" v-model="maximum" aria-describedby="emailHelp" placeholder="Maximum">
                                              </div>
                                          </td>
                                          <td>
                                              <button type="button" name="button" style="background: #00BC09; color: white; border-color: #00BC09;" @click="addSet"><i class="fa fa-check"></i></button>&nbsp;
                                              <button type="button" name="button" style="background: #FFF0ED; color: #F4522D; border-color: #FFF0ED;" @click="closeNewSet"><i class="fa fa-times"></i></button>&nbsp;
                                              <button type="button" name="button" style="background: #E8E8E8; border-color: #E8E8E8; color: #555555;" ><i class="fa fa-plus"></i></button>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </form>
                      </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- edit set modal -->
        <div class="modal-vue">
            <!-- overlay -->
            <div class="overlay" v-if="showSetLabelEditModal" @click="showSetLabelEditModal = false"></div>

            <!-- modal -->
            <div class="modal-own" v-if="showSetLabelEditModal">
              <div class="modal-title">
                <h2 style="color: rgb(254, 117, 15); margin: unset">Edit set title</h2>
              </div>
              <div class="modal-body">
                <form>
                    <div class="form-group">
                      <label>Title </label>
                      <input type="text" class="form-control" v-model="currentSet.label" placeholder="Title">
                    </div>
                    <div class="form-group">
                      <label>Minimum </label>
                      <input type="text" class="form-control" v-model="currentSet.minimum" placeholder="Minimum">
                    </div>
                    <div class="form-group">
                      <label>Maximum </label>
                      <input type="text" class="form-control" v-model="currentSet.maximum" placeholder="Maximum">
                    </div>
                    <div class="form-group">
                      <label>Quantity </label>
                      <input type="text" class="form-control" v-model="currentSet.baseQty" placeholder="Base Qty">
                    </div>
                    <button type="button" @click="editSetLabelSubmit" class="btn submitBtn">Submit</button>&nbsp;
                </form>
              </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import logout from './../../../mixins/logoutMixins.js'

export default {
    props: ['itemDataList', 'itemId', 'categoryId'],
    data () {
        return {
            restaurantId: parseInt(localStorage.getItem('restaurantId')),
            isAddNewSet: false,
            setName: '',
            itemIdSelected: 0,
            maximum: 0,
            minimum: 0,
            customItemSet: [],
            setCollapsed: false,
            itemsNameUnderSet: [],
            itemsIdUnderSet: [],
            jwtToken: this.$cookies.get('token') || null,
            showSetLabelEditModal: false,
            currentSet: []
        }
    },
    created () {
        this.setList ()
    },
    methods : {
        addNewSet () {
            this.isAddNewSet = true
        },
        closeNewSet () {
            this.isAddNewSet = false
        },
        setList () {
            let url = process.env.VUE_APP_API_URL+`/api/menu/item?restaurantId=${this.restaurantId}&id=${this.itemId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.customItemSet = []

                // this.fixedItemSet = data.data
                if (data.data.children) {
                  for (var i = 0; i < data.data.children.length; i++) {
                    let obj = {
                      'setId': data.data.children[i].setId,
                      'setItemId': data.data.children[i].setItemId,
                      'label': data.data.children[i].label,
                      'maximum': data.data.children[i].maxQty,
                      'minimum': data.data.children[i].minQty,
                      'baseQty': data.data.children[i].baseQty,
                      'status': data.data.children[i].status,
                    }
                    this.customItemSet.push(obj)
                  }
                }
            })
        },
        addSet () {
            let url = process.env.VUE_APP_API_URL+`/api/menu/create`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "restaurantId": this.restaurantId,
                "label": this.setName,
                "resourceRef": "",
                "refCode": 0,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": this.categoryId,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 0,
                "modifierId": 0,
                "mealDealId": this.itemIdSelected > 0 ? this.itemIdSelected : this.itemId,
                "setId": 0,
                "printOptionId": 1,
                "printOptionTitle": "Starter",
                "terminalOption": "",
                "status": "Active",
                "basePrice": "",
                "toppingsCategory": "",
                "mealAssignFor": "Kitchen",
                "toppingsCategoryId": 0,
                "baseQty": 1,
                "minQty": this.minimum,
                "maxQty": this.maximum,
                "dataType": "Set",
                "mealDealType": "Custom",
                "displayOrder": 0,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.setName = ''
                this.maximum = 0
                this.minimum = 0
                this.itemIdSelected = 0
                this.isAddNewSet = false

                this.setList()
            })
        },
        editSetLabel (item) {
            this.currentSet = item
            this.showSetLabelEditModal = true
        },
        editSetLabelSubmit () {
            let url = process.env.VUE_APP_API_URL+`/api/menu/update`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "id": this.currentSet.setId,
                "restaurantId": this.restaurantId,
                "label": this.currentSet.label,
                "resourceRef": "",
                "refCode": 0,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": this.categoryId,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 0,
                "modifierId": 0,
                "mealDealId": this.itemIdSelected > 0 ? this.itemIdSelected : this.itemId,
                "setId": this.currentSet.setId,
                "printOptionId": 1,
                "printOptionTitle": "Starter",
                "terminalOption": "",
                "status": "Active",
                "basePrice": "",
                "toppingsCategory": "",
                "mealAssignFor": "Kitchen",
                "toppingsCategoryId": 0,
                "baseQty": this.currentSet.baseQty,
                "minQty": this.currentSet.minimum,
                "maxQty": this.currentSet.maximum,
                "dataType": "Set",
                "mealDealType": "Custom",
                "displayOrder": 0,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30"
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");
                }

                this.setId = ''
                this.showSetLabelEditModal = false
                this.setList()
            })

        },
        editSet (val) {
            let data = val.split('-')
            this.$emit("checkEditClickForSetItem", data)
        },
        showItemsInsideSet (val, element) {
            this.getSetItems(val)
            let idShow = 'showItemsInsideSet'+val
            let idHide = 'hideItemsInsideSet'+val
            let classOption = 'setOptions'+val
            // let row = parseInt(element.parentNode.parentNode.rowIndex) + 1

            if (this.setCollapsed) {
                let x = document.getElementById( idShow)
                let y = document.getElementById(idHide)
                let z = document.getElementsByClassName(classOption)

                x.style.display = "inline"
                y.style.display = "none"

                for (let el of z) el.style.display = 'none'

                this.setCollapsed = !this.setCollapsed
            }
            else {
                let x = document.getElementById(idShow)
                let y = document.getElementById(idHide)
                let z = document.getElementsByClassName(classOption)

                x.style.display = "none"
                y.style.display = "inline"

                for (let el of z) el.style.display = 'block';

                this.setCollapsed = !this.setCollapsed

                // var tbodyRef = document.getElementById('setTable').getElementsByTagName('tbody')[0];
                //
                // var newRow = tbodyRef.insertRow(row);
                //
                // var newCell = newRow.insertCell(0);
                //
                // var newText = document.createTextNode('new row');
                // newCell.appendChild(newText);
            }
        },
        getSetItems (val) {
            let url = process.env.VUE_APP_API_URL+`/api/menu/setItems?restaurantId=${this.restaurantId}&dealId=${this.itemId}&setId=${val}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.itemsNameUnderSet = []
                this.itemsIdUnderSet = []

                for (var i = 0; i < data.data.length; i++) {
                    this.itemsNameUnderSet.push(data.data[i].label)
                    this.itemsIdUnderSet.push(data.data[i].id)
                }
            })
        },
        deleteItemsUnderSet (setVal, itemVal) {
            let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "id": itemVal,
                "restaurantId": this.restaurantId,
                "printOptionTitle": "Starter",
                "toppingsCategoryId": 0,
                "terminalOption": "",
                "modifierId": 0,
                "displayOrder": 0,
                "description": "",
                "baseQty": 1,
                "printOptionId": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "mealAssignFor": "Kitchen",
                "children": [],
                "setId": setVal,
                // "setItemId": 4109,
                "setTitle": "",
                "iconURL": "",
                "toppingsId": 0,
                "basePrice": "",
                "updatedAt": "30 Sep 2020 11:05:30",
                "selectionId": 0,
                "dataType": "SetItem",
                "label": "",
                "resourceRef": "",
                "imgURL": "",
                "itemId": 0,
                "mealDealId": this.itemId,
                "toppingsCategory": "",
                "maxQty": 0,
                "mealDealType": "Custom",
                "refCode": 0,
                "categoryId": this.categoryId,
                "status": "Active",
                "minQty": 0,
                "total": ""
            }

            if (confirm('Are you sure to delete this set item?')) {
                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }

                    this.getSetItems(setVal)
                })
            }

        },
        deleteSet (setVal) {
            let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "id": setVal,
                "restaurantId": this.restaurantId,
                "printOptionTitle": "Starter",
                "toppingsCategoryId": 0,
                "terminalOption": "",
                "modifierId": 0,
                "displayOrder": 0,
                "description": "",
                "baseQty": 1,
                "printOptionId": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "mealAssignFor": "Kitchen",
                "children": [],
                // "setId": setVal,
                // "setItemId": 4109,
                "setTitle": "",
                "iconURL": "",
                "toppingsId": 0,
                "basePrice": "",
                "updatedAt": "30 Sep 2020 11:05:30",
                "selectionId": 0,
                "dataType": "Set",
                "label": "",
                "resourceRef": "",
                "imgURL": "",
                "itemId": 0,
                "mealDealId": this.itemId,
                "toppingsCategory": "",
                "maxQty": 0,
                "mealDealType": "Custom",
                "refCode": 0,
                "categoryId": this.categoryId,
                "status": "Active",
                "minQty": 0,
                "total": ""
            }

            if (confirm('Are you sure to delete this set?')) {
                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }

                    this.setList()
                })
            }

        },
        goBack () {
          this.$emit('goBack')
        },
    }
}
</script>

<style scoped>
.header {
    width: 100%;
    height: 69px;
    background: #FFEDE3;
    border: 1px solid #FFD8BC;
    box-sizing: border-box;
    border-radius: 0px;
}

.headerText {
    position: absolute;
    width: 400px;
    height: 19px;
    left: 28px;
    top: 20px;
    color: #FE750F;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
}
.addSetMenuBtn, .goBackBtn{
  background: #FFF5FF !important;
  color: #FE7611;
  margin-top: 10%;
}
.table > thead > tr > th {
    color: #E317E3;
    background: #FFF5FF;
}
.table > thead > tr > th:last-child, .table > tbody > tr > th:last-child, .table > tfoot > tr > th:last-child, .table > thead > tr > td:last-child, .table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
    width: auto;
}
.modal-vue .overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-title {
    padding: 10px;
    background-color: #FFEDE3;
    border-radius: 10px 10px 0 0;
    top: 10%;
    left: 35%;
}
.modal-vue .modal-own {
    position: fixed;
    width: 500px;
    z-index: 9999;
    margin: 0 auto;
    /* padding: 20px 30px; */
    background-color: #fff;
    border-radius: 10px;
    top: 15%;
    left: 35%;
}

.modal-vue .close{
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>

<template>
  <div class="content py-0">
    <div class="container">
      <div class="row">
        <div class="col-md-6 px-0" style="margin-bottom: 5rem;">
          <div class="header">
            <div class="row">
              <div class="col-md-6 col-6">
                <span class="headerText">
                  Print Option
                </span>
              </div>
              <div class="col-md-6 col-6 pl-0">
                <span style="float: right;">
                  <button type="button" class="btn headerBtnStyle px-1" @click="addNewPrintOption">Add New Print Option</button>
                </span>
              </div>
            </div>
          </div>
          <div class="toppingsTable" style="background-color: #FFFFFF; padding: 2% 31px 31px 31px;">
            <table class="table table-bordered">
              <thead>
                <tr style="font-weight: bold; color: blue">
                  <th class="w-75">Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in printOptionDataList" :key="index">
                  <td>{{ item.title }}</td>
                  <td>
                    <i class="fa fa-edit" style="cursor: pointer;" @click="() => editItem(item.id)"></i> &nbsp;
                    <i class="fa fa-trash" style="color: #F4522D; cursor: pointer;" @click="() => deleteItem(item.id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row" style="background: #FFFFFF; margin-left: 2px; margin-right: 2px;" v-if="isNewAdd">
              <div class="col-md-8 col-8">
                <input type="text" class="form-control" v-model="newName" placeholder="Print option">
              </div>
              <div class="col-md-4 col-4 text-center pt-2">
                <i class="fa fa-check-square" style="cursor: pointer; color: #F4522D;" @click="addItem"></i> &nbsp;
                <i class="fa fa-times" style="cursor: pointer; color: #F4522D;" @click="cancel"></i>
              </div>
            </div>

            <div class="row" style="background: #FFFFFF; margin-left: 2px; margin-right: 2px;" v-if="isEditItem">
              <div class="col-md-8 col-8">
                <input type="text" class="form-control" v-model="editName" placeholder="Print option">
              </div>
              <div class="col-md-4 col-4 text-center pt-2">
                <i class="fa fa-check-square" style="cursor: pointer; color: #F4522D;" @click="updateItem"></i> &nbsp;
                <i class="fa fa-times" style="cursor: pointer; color: #F4522D;" @click="cancelEdit"></i>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
    data () {
        return {
            isNewAdd: false,
            newName: '',
            restaurantId: parseInt(localStorage.getItem('restaurantId')),
            printOptionDataList: [],
            editName: '',
            isEditItem: false,
            editItemId: '',
            jwtToken: this.$cookies.get('token') || null
        }
    },
    created () {
        this.printOptionList()
    },
    methods: {
        addNewPrintOption () {
          this.isNewAdd = true
          this.isEditItem = false
          this.scrollToBottom()
        },
        cancel () {
          this.isNewAdd = false
          this.newName = ''
        },
        addItem () {
            let url = process.env.VUE_APP_API_URL+`/api/printOption/create`

            let dataToSubmit = {
                "restaurantId": this.restaurantId,
                "title": this.newName
            }

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");

                    this.isNewAdd = false
                    this.newName = ''
                    this.printOptionList()
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        editItem (val) {
          this.editItemId = val
          this.isEditItem = true
          this.isNewAdd = false

          for (let i = 0; i < this.printOptionDataList.length; i++) {
            if (this.printOptionDataList[i].id == val) {
              this.editName = this.printOptionDataList[i].title
            }
          }
          this.scrollToBottom()
        },
        updateItem () {
            let url = process.env.VUE_APP_API_URL+`/api/printOption/update`

            let dataToSubmit = {
                "restaurantId": this.restaurantId,
                "title": this.editName,
                "id": this.editItemId
            }

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status == 200) {
                    this.$toastr.s("Updated Successfully", "Success");

                    this.editItemId = ''
                    this.isEditItem = false
                    this.printOptionList()
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        cancelEdit () {
            this.editItemId = ''
            this.isEditItem = false
        },
        deleteItem (val){

            if (confirm('Are you sure to delete this print option')) {

                let url = process.env.VUE_APP_API_URL+`/api/printOption/delete?id=${val}`

                // request header
                let requestHeader = {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${this.jwtToken}`,
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": true,
                  "Access-Control-Allow-Headers": "*",
                  'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
                let dataToSubmit = {}

                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {

                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");

                        this.printOptionList()
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }
                })
            }
        },
        printOptionList () {
            let url = process.env.VUE_APP_API_URL+`/api/printOption?restaurantId=${this.restaurantId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.printOptionDataList = data.data
            })
        },
        scrollToBottom () {
          window.scrollTo({bottom: 0, top: document.body.scrollHeight, behavior: 'smooth'})
        },
    }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 69px;
  background: #FFEDE3;
  border: 1px solid #FFD8BC;
  box-sizing: border-box;
}

.headerText {
  position: absolute;
  width: 200px;
  height: 19px;
  left: 28px;
  top: 20px;
  color: #FE750F;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.InfoTable {
  position: absolute;
  width: 100%;
  height: auto;
  top: 65px;
  background: #F0F0F0;
}

.headerBtnStyle {
  background: #FFF5FF;
  border: 1px solid #BD31B5;
  color: #FE7611;
  margin-top: 7%;
}
.table > thead > tr > th {color: #E317E3; background: #FFF5FF;}

</style>

<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 px-0">
          <top-navbar></top-navbar>
          <div class="col-md-6 offset-md-3 px-5">
            <h3 class="font-weight-bold d-inline-block">Details</h3>
            <sidebar-link to="/admin/enquiries" style="list-style-type: none; float: right;">
              <button class="btn backBtn">
                Back
              </button>
            </sidebar-link>
            <p>
              <b>Name:</b> {{ restDetails.name }}
            </p>
            <p>
              <b>Phone:</b> {{ restDetails.phone }}
            </p>
            <p>
              <b>Email:</b> {{ restDetails.email }}
            </p>
            <p>
              <b>Business Name:</b> {{ restDetails.businessName }}
            </p>
            <p>
              <b>Address:</b> {{ restDetails.address }}
            </p>
            <p>
              <b>Postcode:</b> {{ restDetails.postCode }}
            </p>
            <p>
              <b>Message:</b> {{ restDetails.msg }}
            </p>
            <p>
              <b>Status:</b> {{ restDetails.status }}
            </p>
            <div class="float-right">
              <p v-if="isSuccess" style="color: green; font-weight: bold">Transferred to {{ restDetails.status }}</p>
              <button v-if="restDetails.status == 'Pending'" @click="() => updateStatus('InProgress')" class="btnComplete mx-2">
                Make InProgress
              </button>
              <button v-if="restDetails.status == 'InProgress'" @click="() => updateStatus('Completed')" class="btnInprogress mx-2">
                Make Completed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from '../../layout/TopNavbar'
import logout from '../../mixins/logoutMixins.js'
import axios from 'axios'

export default {
  components: {
    TopNavbar
  },
  data () {
    return {
      restDetails: '',
      isSuccess: false,
      jwtToken: this.$cookies.get('token') || null,
    }
  },
  mixins: [logout],
  mounted () {
    this.getRestDetails()
  },
  methods: {
    getRestDetails () {
        let url = process.env.VUE_APP_API_URL+'/api/restaurantRequest/find?id='+this.$route.query.id

        axios
        .get(url,
        {
            headers: {
                Authorization: `Bearer ${this.jwtToken}`,
            }
        })
        .then((data) => {
            this.restDetails = data.data

            if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                }
            }
        })
    },
    updateStatus (status) {
      let url = process.env.VUE_APP_API_URL+'/api/restaurantRequest/update'
      let tempDetails = {...this.restDetails}
      tempDetails.status = status

      axios
      .post(url, tempDetails,
      {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        }
      })
      .then((data) => {
        this.restDetails.status = status
        this.isSuccess = true

        if (data.status === 403) {
          let logoutResponse = this.logout()
          if (logoutResponse === 1) {
            this.$router.push({ path: '/login' })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
 .backBtn{
    padding: 5px 20px;
    border-radius: 10px;
    text-align: center;
    float: right;
  }
  .btnPending, .btnInprogress, .btnComplete{
    color: #fff;
    border: unset;
    border-radius: .25rem;
    font-weight: bold;
    padding: 8px 16px;
  }
  .btnPending{
    background-color: #F4522D;
  }
  .btnInprogress{
    background-color: #FF9500;
  }
  .btnComplete{
    background-color: #57830d;
  }
  /* .btn:hover{
    background-color: unset !important; color: #fff;
  } */
</style>

<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12 px-0">
          <top-navbar :title="restaurantName" search="false"></top-navbar>
        </div>
        <div class="col-md-10 offset-md-1 col-12 text-center" style="margin-top: 2rem">
          <div class="row">
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/create-owner?edit=true" class="restaurantBox">
                <img src="/img/business-details.png" alt="Logo">
                <h4 class="text-white">Business & Owner Details</h4>
              </sidebar-link>
            </div>
            <div class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/menu-management" class="restaurantBox">
                <img src="/img/menu-entry.png" alt="Logo">
                <h4 class="text-white">Menu Entry</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/content-management" class="restaurantBox">
                <img src="/img/website-update.png" alt="Logo">
                <h4 class="text-white">Website Update</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/content-management-v2" class="restaurantBox">
                <img src="/img/website-update.png" alt="Logo">
                <h4 class="text-white">Website Update V2</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/booking-list" class="restaurantBox">
                <img src="/img/website-update.png" alt="Logo">
                <h4 class="text-white">Booking List</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/customer-list" class="restaurantBox">
                <i style="color: #fff; font-size: 2.7rem;" class="nc-icon nc-single-02"></i>
                <h4 class="text-white">Customer List</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/best-sells" class="restaurantBox">
                <i style="color: #fff; font-size: 2.7rem;" class="nc-icon nc-album-2"></i>
                <h4 class="text-white">Best Sells</h4>
              </sidebar-link>
            </div>
          </div>
        </div>
      </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <sidebar-link to="/admin/owner-list" class="backBtn">
              <img src="/img/arrow-left.png" alt="Logo">
            </sidebar-link>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  // import { mapState } from 'vuex'
  import TopNavbar from '../../layout/TopNavbar'

  export default {
    components: {
      TopNavbar
    },
    data () {
      return {
        ownerMgmt: false,
        isMenuManagement: false,
        restaurantName: localStorage.getItem('restaurantName'),
        userRole: this.$cookies.get('adminData').role
      }
    },
    methods: {
      ownerManagement () {
        this.ownerMgmt = !this.ownerMgmt
      },
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
    },
    watch: {
      $route (to, form) {
        if (to.name == 'MenuManagement' && to.path == '/admin/menu-management') {
          this.isMenuManagement = true
        }
        else {
          this.isMenuManagement = false
        }
      }
    }
  }

</script>
<style scoped>
  .restaurantBox{
    width: 315px;
    height: 150px;
    background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
    border-radius: 10px;
    text-align: center;
    margin: 1rem;
    display: inline-grid;
  }
  .backBtn{
    width: 200px;
    height: 50px;
    background: linear-gradient(179.48deg, #7A7A7A 36.9%, #1C1C1C 93.77%);
    border-radius: 10px;
    text-align: center;
    margin: 3rem auto 0 auto;
    display: block;

  }
  .backBtn img{width: 40px; height: 30px;}
</style>

/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v2.0.0 (Bootstrap 4)
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import 'ant-design-vue/lib/date-picker/style/css'
require('/src/assets/css/media-query.css')
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'

// router setup
import routes from './routes/routes'

import './registerServiceWorker'

// import plugin
import VueToastr from "vue-toastr";
import VueEditor from "vue2-editor";
import Antd from 'ant-design-vue';

// import cookies
import VueCookies from 'vue-cookies'

// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)
Vue.use(Antd)
Vue.use(VueCookies)
Vue.use(BootstrapVue)

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  mode: 'history',
})


// use plugin
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
  defaultTimeout: 1500,
  defaultProgressBar: false,
});
Vue.use(VueEditor);

// apexchart
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})

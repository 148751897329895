import { render, staticRenderFns } from "./CustomToppings.vue?vue&type=template&id=d71fdb92&scoped=true&"
import script from "./CustomToppings.vue?vue&type=script&lang=js&"
export * from "./CustomToppings.vue?vue&type=script&lang=js&"
import style0 from "./CustomToppings.vue?vue&type=style&index=0&id=d71fdb92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d71fdb92",
  null
  
)

export default component.exports
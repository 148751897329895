<template>

  <div class="content">
    <div class="container" style="padding-left: 10px">
      <div class="row">
        <div class="col-md-12 px-0" >
          <top-navbar :title="restaurantName"></top-navbar>
        </div>
        <div class="col-md-12" >
            <div class="row" style="background-color: #FFFFFF;padding-top: 2%">
                <!-- <div class="col-md-3 p-5">
                    <div class="form-group">
                        <select class="form-control" v-model="year">
                            <option v-for="(item, index) in years" :key="index" :value="item"> {{item}} </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3 p-5">
                    <div class="form-group">
                        <select class="form-control" v-model="month">
                            <option value="0"> Select Month </option>
                            <option v-for="(item, index) in months" :key="index" :value="index+1"> {{item}} </option>
                        </select>
                    </div>
                </div> -->
                <div class="col-md-3 p-5 text-center">
                    <div class="form-group">
                      <button @click="getCustomerList" class="btn submitBtn">Download</button>
                      <img v-if="showLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TopNavbar from '../../layout/TopNavbar'
import axios from 'axios'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  components: {
    TopNavbar
  },
  data () {
    return {
      jwtToken: '',
      years: [],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      year: new Date().getFullYear(),
      month: 0,
      showLoading: false,
      excel_header: [['Customer Name', 'Email', 'Phone']],
      export_data: [],
      excel_button: 'Excel',
      restaurantName: localStorage.getItem('restaurantName')
    }
  },
  created() {
    this.jwtToken = this.$cookies.get('token') || null
  },
  mounted() {
    // this.setData()
  },
  methods: {
    setData () {
      let curYear = new Date().getFullYear()
      for (let i = 2021; i <= curYear; i++) {
        this.years.push(i)
      }
    },
    getCustomerList () {
      let id = parseInt(localStorage.getItem('restaurantId'))
      // if (!this.month) {
      //   this.$toastr.w("Please select month", "Warning")
      //   return
      // }
      let url = process.env.VUE_APP_API_URL+`/api/customer?restaurantId=${id}`

      this.showLoading = true
      axios
      .get(url,
      {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        }
      })
      .then((data)=> {
        if (data.status == 200) {
          let customers = data.data
          for (let i = 0; i < customers.length; i++) {
            let customer = []
            customer.name = customers[i].fullName || ''
            customer.email = customers[i].email || ''
            customer.phone = customers[i].phone || ''
            this.export_data.push(customer)
          }
          // New method
          const wb = XLSX.utils.book_new();  // Had to create a new workbook and then add the header
          const ws =  XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(ws, this.excel_header);

          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, this.export_data, { origin: 'A2', skipHeader: true });

          XLSX.utils.book_append_sheet(wb, ws, 'customer');

          // export Excel file
          XLSX.writeFile(wb, 'customer.csv')
        }
        this.showLoading = false
      })
    }
  }
}
</script>

<style scoped>
.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}
</style>
